import { isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const mobilePhoneValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  value = value.trim();
  const re = /^\+[0-9]+$/;

  if (Array.isArray(value)) {
    return (
      value.every((val) => re.test(String(val))) ||
      i18n.global.t("validate.phone_number_failde")
    );
  }
  return (
    re.test(String(value)) || i18n.global.t("validate.phone_number_failde")
  );
};

export { mobilePhoneValidator };
