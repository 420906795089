<template>
  <div class="mb-16 base-card-wrapper">
    <BaseListItemCard
      size="sm"
      class="grid grid-cols-12 gap-4 items-center w-full"
    >
      <div class="flex items-center col-span-2">
        <InlineSvg
          class="svg-sm mr-6"
          :src="icon"
        ></InlineSvg>
        <span class="name text-base-span-md overflow-hidden text-ellipsis whitespace-nowrap">{{ title }}</span>
      </div>

      <div class="flex col-span-8">
        <span class="name text-base-span-md whitespace-normal" v-safe-html="text"></span>
      </div>
      <div class="flex items-center col-span-2">
        <InlineSvg
          class="svg-sm"
          src="/img/clock.svg"
        ></InlineSvg>
        <span class="ml-8 text-base-span-md overflow-hidden text-ellipsis whitespace-nowrap">{{ date }}</span>
      </div>
    </BaseListItemCard>
  </div>
</template>



<script>
export default {
  name: "ItemHistory",
  props: {
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    }
  },
};
</script>
