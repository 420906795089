<template>
    <div class="mx-auto nf_container">
    <div class="nf_preview">
      <img src="/image/404-preview.png" alt="" />
    </div>
    <div class="nf_content">
      <div class="nf_heading">
        Something<br />
        gone missing
      </div>
      <div class="nf_descr">
        Oops! The page you are looking for can't be found
      </div>
      <router-link :to="{ name: 'home.index' }">
        <BaseButton size="md" variant="primary" color="green"
          >Back to Home</BaseButton
        >
      </router-link>
    </div>
  </div>
</template>
