export const STORAGE_CREATED = "s0045";
export const STORAGE_CREATE_ERROR = "e0045";
export const STORAGE_UPDATED = "s0046";
export const STORAGE_UPDATE_ERROR = "e0046";
export const STORAGE_TURNED_ON = "s0047";
export const STORAGE_TURN_ON_ERROR = "e0047";
export const STORAGE_TURNED_OFF = "s0048";
export const STORAGE_TURN_OFF_ERROR = "e0048";
export const STORAGE_REMOVED = "s0049";
export const STORAGE_REMOVE_ERROR = "e0049";


export const storage = {
  [STORAGE_CREATED]: {
    icon: "/img/storage.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.storage.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.storage.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.storage"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [STORAGE_UPDATED]: {
    icon: "/img/storage.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.storage.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.storage.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.update"),
      entityType: $t("notification.contract.entity.storage"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [STORAGE_REMOVED]: {
    icon: "/img/storage.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.storage.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.storage.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.delete"),
      entityType: $t("notification.contract.entity.storage"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [STORAGE_TURNED_ON]: {
    icon: "/img/storage.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.storage.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.storage.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOn"),
      entityType: $t("notification.contract.entity.storage"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [STORAGE_TURNED_OFF]: {
    icon: "/img/storage.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.storage.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.storage.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOff"),
      entityType: $t("notification.contract.entity.storage"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
}