import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("contractCertificate.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, certificateId, request) {
  return axios.put(
    api.path("contractCertificate.update", {
      contractId: contractId,
      certificateId: certificateId,
    }),
    request
  );
}

export function list(contractId) {
  return axios.get(
    api.path("contractCertificate.index", { contractId: contractId })
  );
}

export function show(contractId, certificateId) {
  return axios.get(
    api.path("contractCertificate.show", {
      contractId: contractId,
      certificateId: certificateId,
    })
  );
}

export function remove(contractId, certificateId) {
  return axios.delete(
    api.path("contractCertificate.remove", {
      contractId: contractId,
      certificateId: certificateId,
    })
  );
}

export function turnOn(contractId, certificateId) {
  console.log(certificateId);
  return axios.put(
    api.path("contractCertificate.turnOn", {
      contractId: contractId,
      certificateId: certificateId,
    })
  );
}

export function turnOff(contractId, certificateId) {
  console.log(certificateId);
  return axios.put(
    api.path("contractCertificate.turnOff", {
      contractId: contractId,
      certificateId: certificateId,
    }));
}