import {
  confirmed,
  email,
  max,
  min,
  required,
  itn,
  zipCode,
  certificate,
  domain,
  isNumber,
  aDomainType,
  aaaaDomainType,
  cnameDomainType,
  locDomainType,
  mxDomainType,
  nsDomainType,
  ptrDomainType,
  soaDomainType,
  srvDomainType,
  txtDomainType,
  domainZoneName,
  ttl,
  maxNumber,
  minNumber
} from "~/validation/rules";

const validationMixin = {
  props: {
    rules: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      validation: {
        isFirstRunWatch: false,
        errors: [],
        hasErrors: null,
        // //TODO да в этой переменной есть смысл,  хотя может быть можно и без неё обойтись.
        // // И есть пару мыслей как это сделать, но пока  она есть и она с нами.
        // withoutErrors: null,
        rules: {
          email: email,
          confirmed: confirmed,
          certificate: certificate,
          min: min,
          max: max,
          required: required,
          itn: itn,
          zipCode: zipCode,
          domain: domain,
          isNumber: isNumber,
          aDomainType: aDomainType,
          aaaaDomainType: aaaaDomainType,
          cnameDomainType: cnameDomainType,
          locDomainType: locDomainType,
          mxDomainType: mxDomainType,
          nsDomainType: nsDomainType,
          ptrDomainType: ptrDomainType,
          soaDomainType: soaDomainType,
          srvDomainType: srvDomainType,
          txtDomainType: txtDomainType,
          ttl: ttl,
          domainZoneName: domainZoneName,
          maxNumber: maxNumber,
          minNumber: minNumber,
        },
      },
    };
  },
  created() {},
  methods: {
    //TODO  You also need to write such a function that would restart only the specified rule
    // checkRule(rule) {},

    checkRules: function(rules) {
      let isSuccess = true;
      rules = Object.entries(rules);

      rules.forEach((rule) => {
        let name;
        let params;

        if (typeof rule === "object") {
          name = rule[0];
          params = rule[1];
        } else {
          name = rule.split(":")[0];
          params = rule.split(":")[1];
        }

        if (params === false) {
          return true;
        }

        let result = this.validation.rules[name](this.getValue(), params);

        if (result !== true) {
          isSuccess = false;
          this.validation.errors.push(result);
        }
      });

      this.validation.hasErrors = !isSuccess;

      return isSuccess;
    },
    makeValidation: function (rules) {
      this.validation.errors = [];
      this.validation.hasErrors = null;
      let lengthRules;
      if (!rules) {
        return true;
      }

      //TODO After I change the validation everywhere, remove this check.since all rules will be an object
      lengthRules = Object.keys(rules).length;

      if (rules && lengthRules > 0) {
        return this.checkRules(rules);
      }

      return true;
    },
    setErrors: function (messages) {
      if (messages) {
        this.validation.errors = [];
        this.validation.hasErrors = null;

        messages.forEach((message) => {
          this.validation.errors.push(message);
        });

        this.validation.hasErrors = true;
      } else {
        this.validation.hasErrors = false;
        this.validation.errors = []
      }
    },
    setError: function (message) {
      if (message) {
        this.validation.errors = [message];
        this.validation.hasErrors = true;
      } else {
        this.validation.hasErrors = false;
        this.validation.errors = [];
      }
    },

    /*
     * Цей метод, створений для того, щоб ми мали смогу
     * видаляти, лише деякі специфічні(кастомні) повідомлення про помилку.
     *
     * @param message
     */
    clearError: function (message) {
      if (message === null) {
          this.validation.hasErrors = null;
          this.validation.errors = [];
      } else {
        let index = this.validation.errors.indexOf(message);

        if (index > -1) {
          this.validation.errors.splice(index, 1);

          if (this.validation.errors.length === 0) {
            this.validation.hasErrors = false;
          }
        }
      }
    },
  },
};

export default validationMixin;
