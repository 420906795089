import { list as getList } from "~/api/contractHistory";
import * as types from "../mutation-types";
import store from "../../store";

export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_HISTORY](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_HISTORY](state, contractStorage) {
    state.list = contractStorage;
  },
  [types.SET_CONTRACT_HISTORY_META](state, meta) {
    state.meta = meta;
  },
};

export const actions = {
  fetchContractHistories({ commit }, {contractId = null, params = null}) {
    console.log(contractId);
    console.log(params);
    commit(types.SET_IS_LOADING_CONTRACT_HISTORY, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId, params).then((response) => {
        let { data } = response;
        console.log(data);
        console.log(data.data);
        commit(types.SET_CONTRACT_HISTORY, data.data);
        commit(types.SET_CONTRACT_HISTORY_META, data.meta);
        commit(types.SET_IS_LOADING_CONTRACT_HISTORY, false);
      });
    }
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
