import Cookies from "js-cookie";
import * as types from "../mutation-types";

const locale = localStorage.getItem("appLanguage") || "ua";
const locales = {
  ua: { key: "ua", name: "ukrainian", value: 1 },
  en: { key: "en", name: "english", value: 2 },
};

// CORRECT:A-2 - well, it's complete nonsense, there's duplication of keys and language. Overall, I don't like this system, it gets complicated with language, as there's a key, an ID, and a name. Everything needs to be done in a better way.
export const idToLocaleKey = {
  1: "ua",
  2: "en",
};

// state
export const state = {
  locale: getLocale(locales, locale),
  locales: locales,
};

// getters
export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
  localeValue: (state) => state.locales[state.locale].value,
};

// mutations
export const mutations = {
  [types.SET_LOCALE](state, { locale }) {
    let safeLocale = "en"; // Значение по умолчанию

    if (
      typeof locale === "string" &&
      Object.values(idToLocaleKey).includes(locale)
    ) {
      safeLocale = locale;
    } else if (typeof locale === "number" && idToLocaleKey[locale]) {
      safeLocale = idToLocaleKey[locale];
    }

    state.locale = safeLocale;
    Cookies.set("locale", safeLocale, { expires: 365 });
    localStorage.setItem("appLanguage", safeLocale);
  },
};

// actions
export const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });

    // Cookies.set("locale", locale, { expires: 365 });
  },
};

function getLocale(locales, fallback) {
  const locale = Cookies.get("locale");
  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale;
  } else if (locale) {
    Cookies.remove("locale");
  }

  return fallback;
}
