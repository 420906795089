<template>
  <div>
    <template v-if="isLoad">
      <BaseCard fullWidth loader class="mt-16">
        <BaseItemLoader v-for="index in 3" :key="index" />
      </BaseCard>
    </template>
    <template v-if="invitations.length > 0">
      <div
        class="mb-16 base-card-wrapper"
        v-for="invitation in invitations"
        :key="invitation.id"
      >
        <BaseListItemCard
          size="sm"
          class="grid grid-cols-12 gap-4 items-center"
        >
          <div
            class="col-span-7 md:col-span-7 lg:col-span-7 xl:col-span-7 2xl:col-span-7 flex flex-col justify-center whitespace-nowrap truncate space-x-4"
          >
            <span class="text-base-p-md">
              {{
                $t("invitation.user_invite_to_contract", {
                  user: showName(invitation.invitingUser),
                  contract: invitation.contractName
                })
              }}</span
            >
          </div>
          <div
            class="col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 flex items-center"
          >
            <InlineSvg class="svg-sm mr-5" src="/img/clock.svg"></InlineSvg>
            {{ invitation.createdAt }}
          </div>
          <div
            class="invitation-nav col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1 flex items-center justify-end"
          >
            <button
              @click="isConfirm(invitation.contractId)"
              type="button"
              class="green-5-color mr-2"
            >
              <i class="icon invitation-success-icon"></i>
              {{ $t("form.button.accept") }}
            </button>
            <button
              @click="isUnConfirm(invitation.contractId)"
              type="button"
              class="red-5-color"
            >
              <i class="icon invitation-cancel-icon"></i>
              {{ $t("form.button.reject") }}
            </button>
          </div>
        </BaseListItemCard>
      </div>
    </template>
    <template v-if="!isLoad && invitations.length === 0">
      <div class="mt-20 grey-4-color">
        {{ $t("invitation.no_invitations_text") }}
      </div>
    </template>
  </div>
  <PermissionModal
    :header="$t('invitation.confirm_acceptance')"
    :is-loading="isLoadingModal"
    :show="isShowConfirm"
    @make="confirm"
    @close="isShowConfirm = false"
  />
  <PermissionModal
    :header="$t('invitation.confirm_rejection')"
    :is-loading="isLoadingModal"
    :show="isShowUnConfirm"
    @make="unConfirm"
    @close="isShowUnConfirm = false"
  />
</template>

<script>
import {
  confirmInvitation,
  unConfirmInvitation
} from "~/api/userInvitationContract";
import PermissionModal from "~/components/Modal/SetContragentPermission.vue";
import baseMixin from "~/mixins/base";

export default {
  name: "ContractInvitation",
  emits: ['refresh'],
  components: {
    PermissionModal
  },
  mixins: [baseMixin],
  props: {
    invitations: {
      type: Object,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    isLoad: {
      type: Boolean
    }
  },
  data() {
    return {
      isLoadingModal: false,
      isShowConfirm: false,
      isConfirmRun: false,
      isShowUnConfirm: false,
      makeCommand: null,
      contractId: null
    };
  },
  created() {
  },
  methods: {
    showName: function(user) {
      return user.fullName;
    },
    isConfirm: function(contractId) {
      this.isLoadingModal = false;
      this.isShowConfirm = true;
      this.contractId = contractId;
    },
    isUnConfirm: function(contractId) {
      this.isLoadingModal = false;
      this.contractId = contractId;
      this.isShowUnConfirm = true;
    },
    confirm: function() {
      this.isLoadingModal = true;
      confirmInvitation(this.contractId, this.userId)
        .then(({ data }) => {
          if (this.isSuccessStatus(data)) {
            this.isShowConfirm = false;
            this.$emit("refresh");
            this.$store.dispatch("contract/fetchContracts");
            this.$toast.success(this.$t("invitation.contract.success_confirm"));
          }
        })
        .catch(() => {
          this.isLoadingModal = false;
        });
    },
    unConfirm: function() {
      this.isLoadingModal = true;
      unConfirmInvitation(this.contractId, this.userId)
        .then((response) => {
          let { data } = response;

          if (data.data) {
            this.isShowUnConfirm = false;
            this.$emit("refresh");
            this.$toast.success(this.$t("invitation.contract.success_un_confirm"));
          }
        })
        .catch(() => {
          this.isLoadingModal = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.invitation-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
  padding-left: 20px;

  button {
    padding: 0;
    border: none;
    margin: 0 22px 0 0;
    background: transparent;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }

    .icon {
      margin-right: 8px;
    }
  }
}

.text-base-p-md {
  white-space: normal;
  overflow-wrap: break-word;
}

@include maxWidth992 {
  .cabinet-list_item {
    display: block;
  }
  .invitation-nav {
    padding-left: 0;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }
}
</style>
