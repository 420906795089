import * as types from "~/constants/counterpartyHistoryType";

export const counterpartyConfig = {
  [types.COUNTERPARTY_CREATED]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.counterparty.action",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.counterparty.action",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      name: data.name,
      link: data.link,
      action: $t("notification.action.created"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.CONTRACT_CREATED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.counterparty.contract_action",
    route: "contract.index",
    langKeyNotificationAction: "notification.counterparty.contract_action",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      contract: data.contract,
      name: data.name,
      link: data.link,
      action: $t("notification.action.created"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.CONTRACT_UPDATED_ATTACHED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.counterparty.contract_update_attached",
    route: "contract.index",
    langKeyNotificationAction: "notification.counterparty.contract_update_attached",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      contract: data.contract,
      name: data.name,
      link: data.link,
      action: $t("notification.action.created"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.CONTRACT_UPDATED_DETACHED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.counterparty.contract_update_detached",
    route: "contract.index",
    langKeyNotificationAction: "notification.counterparty.contract_update_detached",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      contract: data.contract,
      name: data.name,
      link: data.link,
      action: $t("notification.action.created"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.COUNTERPARTY_UPDATED]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.counterparty.action",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.counterparty.action",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      name: data.name,
      link: data.link,
      action: $t("notification.action.updated"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.COUNTERPARTY_REMOVED]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.counterparty.action",
    langKeyNotificationTitle: "notification.counterparty.title",
    langKeyNotificationAction: "notification.counterparty.action",
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      name: data.name,
      link: data.link,
      action: $t("notification.action.deleted"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
  },
  [types.INVITATION_CREATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty.invitation",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.counterparty.invitation",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      contractorName: data.name,
      targetUser: data.initiatorName,
      link: data.link,
      action: $t("notification.action.sent"),
      user: data.userName,
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.INVITATION_UPDATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty.invitation",
    langKeyNotificationAction: "notification.counterparty.invitation",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langTextParams: (data, $t) => ({
      contractorName: data.name,
      targetUser: data.initiatorName,
      link: data.link,
      action: $t("notification.action.updated"),
      user: data.userName,
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.INVITATION_REMOVED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty.invitation",
    langKeyNotificationAction: "notification.counterparty.invitation",
    route: "counterparty.index",
    langKeyNotificationTitle: "notification.counterparty.title",
    langTextParams: (data, $t) => ({
      targetUser: data.initiatorName,
      contractorName: data.name,
      link: data.link,
      action: $t("notification.action.deleted"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [types.USER_INVITATION_CONFIRMED]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.counterparty.user_invitation",
    langKeyNotificationAction: "notification.counterparty.user_invitation",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      link: data.link,
      counterparty: data.name,
      action: $t("action.accepted"),
    }),
    hash: (id) => `#${id}`,
  },
  [types.USER_INVITATION_UN_CONFIRMED]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.counterparty.user_invitation",
    langKeyNotificationAction: "notification.counterparty.user_invitation",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      link: data.link,
      counterparty: data.name,
      action: $t("action.declined"),
    }),
    hash: (id) => `#${id}`,
  },
  [types.COUNTERPARTY_USER_UPDATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty_user.action",
    langKeyNotificationAction: "notification.counterparty_user.action",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      executor: data.initiatorName,
      user: data.subjectName,
      counterparty: data.name,
      link: data.link,
      action: $t("notification.action.updated"),
    }),
    hash: (id) => `#${id}`,
  },
  [types.COUNTERPARTY_USER_REMOVED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty_user.action",
    langKeyNotificationAction: "notification.counterparty_user.action",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "counterparty.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      executor: data.initiatorName,
      user: data.subjectName,
      counterparty: data.name,
      action: $t("action.removed"),
    }),
    hash: (id) => `#${id}`,
  },
  [types.CONTRACT_DELETED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.counterparty.contract_action",
    langKeyNotificationAction: "notification.counterparty.contract_action",
    langKeyNotificationTitle: "notification.counterparty.title",
    route: "contract.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      contract: data.contract,
      name: data.name,
      link: data.link,
      action: $t("action.removed"),
    }),
  },
};
