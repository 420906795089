import { isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const minValidator = (value, length) => {
  if (isEmpty(value)) return true;

  if (typeof value === "string") {
    return value.length >= length || i18n.global.t("validate.the_field_not_less_than", { min: length });
  }

  return true;
};

export { minValidator };
