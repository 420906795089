import { show } from "~/api/contractSetting";
import * as types from "../mutation-types";
import store from "../../store";

const typeLimitMinimalBalanceId = 10;
const typeCostLimitPerMinutePeriodId = 21;
const typeCostLimitPer5MinuteTimePeriodId = 22;
const typeCostLimitPerHourPeriodId = 23;
const typeCostLimitPerDayPeriodId = 24;

export const state = {
  list: null,
  isLoading: false,
  typeIds: {
    limitMinimalBalance: typeLimitMinimalBalanceId,
    typeCostLimitPerMinutePeriod: typeCostLimitPerMinutePeriodId,
    typeCostLimitPer5MinuteTimePeriod: typeCostLimitPer5MinuteTimePeriodId,
    typeCostLimitPerHourPeriod: typeCostLimitPerHourPeriodId,
    typeCostLimitPerDayPeriod: typeCostLimitPerDayPeriodId,
  },
  limitMinimalBalance: null,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_SETTINGS](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_SETTINGS_DATA](state, data) {
    state.contractSettingsData = data;
  },
  [types.SET_CONTRACT_SETTINGS](state, sites) {
    state.list = sites.data;
  },
};

export const actions = {
  fetchContractSettings({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_SETTINGS, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      show(activeContractId).then((response) => {
        let { data } = response;

        commit(types.SET_CONTRACT_SETTINGS_DATA, data.data);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  contractSettingsData: (state) => state.contractSettingsData,
  typeLimitMinimalBalanceId: (state) => state.typeIds.limitMinimalBalance,
  typeCostLimitPerMinutePeriodId: (state) =>
    state.typeIds.typeCostLimitPerMinutePeriod,
  typeCostLimitPer5MinuteTimePeriodId: (state) =>
    state.typeIds.typeCostLimitPer5MinuteTimePeriod,
  typeCostLimitPerHourPeriodId: (state) =>
    state.typeIds.typeCostLimitPerHourPeriod,
  typeCostLimitPerDayPeriodId: (state) =>
    state.typeIds.typeCostLimitPerDayPeriod,
};
