<template>
  <main>
    <ContainerLoader v-if="isLoading"></ContainerLoader>
    <section v-if="!isLoading" id="cabinet" class="cabinet-wrapp">
      <SidebarMain v-if="navbarType === 'main'" :isCollapsed="isSidebarCollapsed" @toggle="toggleSidebar"></SidebarMain>
      <sidebar v-if="navbarType === 'profile'"></sidebar>
      <div class="cabinet_main">
        <navbar :layout="navbarType" :isCollapsed="isSidebarCollapsed" @toggle="toggleSidebar"></navbar>
        <router-view></router-view>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SidebarMain from "../components/SidebarMain";
import { csrf } from "~/api/auth";
import baseMixin from "~/mixins/base";
import { reloadData } from "~/constants/broadcast/reloadDataType";
import router from "~/router";
import contractBaseMixin from "~/mixins/contractBase";

export default {
  props: {
    navbarType: {
      type: String,
      default: "profile",
    },
  },
  mixins: [baseMixin, contractBaseMixin],
  data() {
    return {
      isAuthUser: false,
      isUserChecked: false,
      echoChannel: null,
      isSidebarCollapsed: false,
      isMobile: window.innerWidth < 992,
    };
  },
  components: {
    Navbar,
    Sidebar,
    SidebarMain,
  },
  async created() {
    this.startLoading();
    await csrf();
    let user = await this.$store.dispatch("user/fetchUser");

    if (user) {
      await Promise.all([
        this.$store.dispatch("counterparty/fetchCounterparties"),
        this.$store.dispatch("contract/fetchContracts"),
        this.$store.dispatch("contract/fetchActiveContract"),
        this.$store.dispatch("user/fetchBroadcast"),
      ]);

      window.Echo.private(`user.${user.id}`).notification((notification) => {
        this.handleNotification(notification, 'user');
      });

      this.subscribeToContractChannel();
    }

    this.finishLoading();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    this.unsubscribeFromChannels();
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    'activeContractName'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.subscribeToContractChannel();
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
    handleNotification(notification, type) {
      if (notification.type === "reload-data") {
        const currentRouteName = router.currentRoute.value.name;
        const modifiedData = notification.modifiedData;
        const dataToReload = reloadData[modifiedData];

        if (dataToReload) {
          dataToReload.alwaysFetch.forEach(fetchAction => {
            this.$store.dispatch(fetchAction);
          });

          dataToReload.conditionalFetch.forEach(condition => {
            if (condition.routeName.includes(currentRouteName)) {
              condition.fetchData.forEach(fetchAction => {
                if (typeof fetchAction === 'object' && fetchAction.action) {
                  const params = fetchAction.paramsKey && notification.data ? notification.data[fetchAction.paramsKey] : undefined;
                  this.$store.dispatch(fetchAction.action, params);
                } else {
                  this.$store.dispatch(fetchAction);
                }
              });
            }
          });
        }
      }
    },
    subscribeToContractChannel() {
      this.unsubscribeFromChannels();
      if (this.activeContractName) {
        this.echoChannel = window.Echo.private(`contract.${this.activeContractName}`)
          .notification((notification) => this.handleNotification(notification, 'contract'));
      }
    },
    unsubscribeFromChannels() {
      if (this.echoChannel) {
        this.echoChannel.stopListening('notification');
        window.Echo.leave(`contract.${this.activeContractName}`);
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 992;
      if (this.isMobile) {
        this.isSidebarCollapsed = false;
      }
    },
  }
};
</script>
