function _typeof(obj) {
  "@babel/helpers - typeof";
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    // eslint-disable-next-line no-func-assign
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    // eslint-disable-next-line no-func-assign
    _typeof = function _typeof(obj) {
      return obj &&
        typeof Symbol === "function" &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
        ? "symbol"
        : typeof obj;
    };
  }
  return _typeof(obj);
}

export function isEmpty(value) {
  if (value === null || value === undefined || value === "") {
    return true;
  }

  return Array.isArray(value) && value.length === 0;
}

export function getSingleParam(params, paramName) {
  return Array.isArray(params) ? params[0] : params[paramName];
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

export default function assertString(input) {
  var isString = typeof input === "string" || input instanceof String;

  if (!isString) {
    var invalidType = _typeof(input);

    if (input === null) invalidType = "null";
    else if (invalidType === "object") invalidType = input.constructor.name;
    throw new TypeError(
      "Expected a string but received a ".concat(invalidType)
    );
  }
}

export function isNumber(arg) {
  return typeof arg === "number";
}
