import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const backupsRoutes = {
  path: "/backups",
  component: layout,
  name: "backups",
  children: [
    {
      path: "",
      component: () => import("~/views/Backups/Index"),
      name: "backups.index",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default backupsRoutes;
