<template>
  <div class="nav-toggler">
    <div class="icon">
      <input
        type="checkbox"
        :id="checkboxId"
        :checked="internalValue"
        @click="handleClick"
      />
      <label :for="checkboxId"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseToggleButton",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    checkboxId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();

      this.$emit("toggle");
    },
  },
};
</script>
