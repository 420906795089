import * as types from "../mutation-types";
import store from "../../store";
import { indexGrafana } from "~/api/contractMetric";

export const state = {
  list: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_GRAFANA_METRIC](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_GRAFANA_METRIC](state, data) {
    state.list = data.data;
  },
};

export const actions = {
  fetchContractMetricGrafana({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_GRAFANA_METRIC, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      indexGrafana(activeContractId).then(({data}) => {
        // console.log(response);
        // let { data } = response;
        console.log(data);
        commit(types.SET_CONTRACT_GRAFANA_METRIC, data);
        commit(types.SET_IS_LOADING_CONTRACT_GRAFANA_METRIC, false);
      });
    }
  },
};

export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};