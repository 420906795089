<template>
  <div class="cabinet_container_alert" v-if="question">
    <BaseIcon icon="alert-quest-icon" />
    <div class="text-base-h6">
      {{ $t("frequently_questions") }}:
      <a href="#" class="text-base-span-md" @mouseenter="showText = true" @mouseleave="showText = false">{{ question }}</a>
    </div>
  </div>
  <transition v-if="text" name="fade">
    <div v-if="showText"
         :id="id"
         class="text-justify custom-margin mx-auto max-w-7xl w-full sm:px-6 lg:px-8 z-10 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm">
      {{ text }}
    </div>
  </transition>
</template>



<script>
import { initTooltips } from "flowbite";

export default {
  name: "QuestionAlert",
  props: {
    question: String,
    id: String,
    text: String
  },
  data() {
    return {
      showText: false
    };
  },
  mounted() {
    initTooltips();
  }
};
</script>

<style lang="scss" scoped>
.custom {
  transform: translateY(-33%);
  top: 50%;left: 0%;
  inset: auto!important
}
.custom-margin {
  margin-top: 20px !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
