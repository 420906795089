<template>
  <transition name="header-profile-transition">
    <div class="header_profile_menu" v-show="show">
      <div class="header_profile_menu_top">{{ $t("personal_cabinet") }}</div>
      <ul>
        <li>
          <router-link
            :to="{ name: 'counterparty.index' }"
            :class="{ active: routeLayout === 'counterparty' }"
            @click="$emit('close')"
          >
            <BaseIcon
              icon="contragent-icon"
              :active="routeLayout === 'counterparty'"
            />
            <span>{{ $t("counterparties") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ active: currentRoute === 'contract.index' }"
            :to="{ name: 'contract.index' }"
          >
            <BaseIcon
              icon="contract-icon"
              :active="currentRoute === 'contract.index'"
            />
            <span>{{ $t("contracts") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'referral.program' }"
            :class="{ active: routeLayout === 'referral' }"
            @click="$emit('close')"
          >
            <BaseIcon
              icon="referrals-icon"
              :active="routeLayout === 'referral'"
            />
            <span>{{ $t("referral_program") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'profile.info' }"
            :class="{ active: routeLayout === 'profile' }"
            @click="$emit('close')"
          >
            <BaseIcon icon="setting-icon" :active="routeLayout === 'profile'" />
            <span>{{ $t("personal_settings") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'history.user.index' }"
            :class="{ active: routeLayout === 'history' }"
            @click="$emit('close')"
          >
            <BaseIcon icon="history-icon" :active="routeLayout === 'history'" />
            <span>{{ $t("sidebar.history") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'invitation.counterparties' }"
            :class="{ active: routeLayout === 'invitation' }"
            @click="$emit('close')"
          >
            <BaseIcon
              icon="invitation-icon"
              :active="routeLayout === 'invitation'"
            />
            <span>{{ $t("invitations") }}</span>
          </router-link>
        </li>
        <li>
          <a href="javascript:void(0);" @click="logout()">
            <BaseIcon icon="exit-icon" />
            <span>{{ $t("exit") }}</span>
          </a>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { logout } from "~/api/auth";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    logout: async function() {
      let result = await logout();

      if (result) {
        const fullPath = this.$router.resolve({
          name: "auth.login",
          query: { loggedOut: 'true' }
        }).href;

        window.location.href = fullPath;
      }
    }
  },
  computed: {
    routeLayout: function() {
      return this.$route.name.split(".")[0];
    },
    currentRoute() {
      return this.$route.name;
    }
  }
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
