<template>
  <button
    v-tooltip="tooltipData"
    v-bind="$attrs"
    :style="btnStyle"
    :icon="icon"
    :iconSize="iconSize"
    :iconPosition="iconPosition"
    :disabled="disabled"
    :size="size"
    :class="[btnClass, { 'element-load': isLoad }]"
  >
    <img
      v-if="icon"
      class="icon"
      :class="{
        'icon-right': this.iconPosition === 'right',
        [`icon-size-${iconSize}`]: this.icon,
        'bright-icon':
          this.variant === 'primary' && this.icon && this.brightIcon,
      }"
      :src="`/image/icons/${icon}.svg`"
      alt="icon"
    />

    <slot />
  </button>
</template>

<script>
import { isMobile } from "~/mixins/isMobile";

export default {
  name: "BaseButton",
  inheritAttrs: false,
  mixins: [isMobile],
  props: {
    variant: String,
    size: {
      type: String,
      default: "sm",
    },
    iconSize: {
      type: String,
      default: "sm",
    },
    color: {
      type: String,
      default: "grey",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    isLoad: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      required: false,
    },
    brightIcon: {
      type: Boolean,
      default: true,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipData() {
      if (this.tooltipContent) {
        return { content: this.tooltipContent, html: true };
      }

      return {};
    },
    btnClass() {
      return {
        "base-btn": true,
        "v-popper--has-tooltip": true,
        "bold-font": this.bold,
        // fix spacing when only 1 icon
        [`size-${this.size}`]: true,
        [`base-btn-text-${this.size}`]: true,
        [`${this.color}-bg`]: true,
        [this.variant]: true,
        fullwidth: this.fullWidth,
        disabled: this.disabled,
        circle: this.circle,
        "icon-spacing": this.icon,
        "circle-icon-spacing": this.icon && this.circle,
      };
    },
    btnStyle() {
      const base = {
        "background-color":
          this.variant === "primary" ? this.color : "transparent",
        border:
          this.variant === "primary"
            ? "1px solid transparent"
            : `1px solid ${this.color}`,
        color: this.variant === "primary" ? "white" : `${this.color}`,
      };
      return base;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/design/mainIncludes";

.base-btn {
  display: flex;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Museo Regular", sans-serif;
  font-weight: normal;
  //letter-spacing: 0.08em;
  border-radius: 4px;
  transition: 0.3s;

  outline: none;
  &.size-sm {
    padding: 10px 11px;
  }
  &.size-md {
    padding: 16px 40px;
  }
  &.icon-spacing {
    column-gap: 8px;
  }
  &.circle-icon-spacing {
    padding: 9px !important;
  }
  @include minWidth992 {
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.fullwidth {
  width: 100%;
}

.circle {
  border-radius: 50px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.option-btn {
  @include minWidth768 {
    display: none;
  }
}

.icon {
  width: 16px;
  height: 16px;
  &.icon-size-xs {
    height: 8px;
    width: 8px;
  }
  &.icon-size-md {
    height: 20px;
    width: 20px;
  }
  &.icon-size-lg {
    height: 24px;
    width: 24px;
  }
  &-right {
    order: 1;
  }
  &.bright-icon {
    filter: brightness(2);
  }
}

.v-popper--theme-tooltip .v-popper__inner {
  background: rgba(194, 11, 11, 0.8) !important;
  color: #942323 !important;
}
</style>
