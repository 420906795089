<template>
  <transition name="header-profile-transition">
    <div class="header_profile_menu" v-show="isShow">
      <div class="header_profile_menu_top">{{ $t("contract") }}</div>
      <div
        v-if="contractsList && contractsList.length"
        class="header_profile_menu_contragent"
      >
        <ul :key="contract.id" v-for="contract in contractsList">
          <li>
            <a
              @click="setActiveContract(contract)"
              href="javascript:void(0);"
              :class="{ active: contract.name === activeContract?.name }"
              >{{ contract.name }}</a
            >
          </li>
        </ul>
      </div>
      <div v-else class="contragent-empty">
        Активних контрактів не знайдено.
        <div class="mt-10">
          <router-link class="green-5-color" :to="{ name: 'contract.index' }">
            Створити контракт
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: this.show,
    };
  },
  methods: {
    setActiveContract: function (contract) {
      this.$store.dispatch("contract/setActiveContract", contract.id);
      this.$router.push({
        name: "contract.cabinet.index",
        params: { contractName: contract.name },
      });

      this.isShow = false;
    },
  },
  watch: {
    show: function (newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    activeContract: function () {
      return this.$store.getters["contract/activeContract"];
    },
    contractsList: function () {
      let contracts = this.$store.getters["contract/list"];
      let contactsList = [];

      if (contracts) {
        contracts.forEach((element) => {
          if (element.users.length) {
            contactsList.push(element);
          }
        });
        if (contactsList.length >= 1) {
          this.$emit("emptyList", false);
          return contactsList;
        } else {
          this.$emit("emptyList", true);
        }
      } else {
        this.$emit("emptyList", true);
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";
.contragent-empty {
  padding: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
