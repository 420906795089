import { index as getList } from "~/api/site";
import * as types from "../mutation-types";
import store from "../../store";

/**
 * Initial state
 */
export const state = {
  list: null,
  isLoading: false,
  isInitialLoadPending: true,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_SITES](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_SITES](state, sites) {
    state.list = sites.data;
    state.isInitialLoadPending = false;

  },
};

/**
 * Actions
 */
export const actions = {
  fetchContractSites({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_SITES, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_SITES, data);
        commit(types.SET_IS_LOADING_CONTRACT_SITES, false);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  isInitialLoadPending: (state) => state.isInitialLoadPending,
};
