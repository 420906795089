export const state = {
  notifications: {
    user: ["user_authorization", "user_profile_changes"],
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.notifications,
};
