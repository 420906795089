<template>
  <div class="cabinet-form_item">
    <div :style="labelStyle" :class="labelClass" class="label text-base-span-sm">
      <div class="label-content w-full">
        <slot name="label" />
      </div>
    </div>
    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelWidth: {
      type: String,
      default: "30%",
    },
    labelClass: {
      type: String,
      required: false,
    }
  },
  computed: {
    labelStyle() {
      return {
        width: this.labelWidth,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/design/mainIncludes";

.cabinet-form_item {
  display: flex;
  align-items: baseline;
  column-gap: 15px;

  @include maxWidth768 {
    flex-flow: column wrap;
    row-gap: 8px;
  }

  .label {
    display: flex;
    align-items: center;
    color: $grey-4;
    flex-shrink: 0;

    @include maxWidth768 {
      width: 100%;
      overflow: hidden;
    }

    .label-content {
      display: flex;
      align-items: center;
    }
  }

  .content {
    width: 100%;
    row-gap: 10px;
    display: flex;
    flex-flow: column;
  }
}
</style>
