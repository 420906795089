import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("site.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, siteId, request) {
  return axios.put(
    api.path("site.update", { contractId: contractId, siteId: siteId }),
    request
  );
}

export function remove(contractId, siteId) {
  return axios.delete(
    api.path("site.remove", { contractId: contractId, siteId: siteId })
  );
}

export function index(contractId) {
  return axios.get(api.path("site.index", { contractId: contractId }));
}

export function show(contractId, siteId) {
  return axios.get(
    api.path("site.show", { contractId: contractId, siteId: siteId })
  );
}

export function turnOn(contractId, siteId) {
  return axios.put(
    api.path("site.turnOn", { contractId: contractId, siteId: siteId }));
}

export function turnOff(contractId, siteId) {
  return axios.put(
    api.path("site.turnOff", { contractId: contractId, siteId: siteId }));
}

