<template>
  <div class="base-checkbox">
    <input
      :disabled="disabled"
      type="checkbox"
      :id="id"
      :checked="checked"
      @input="(event) => $emit('update:checked', event.target.checked)"
    />
    <label :for="id" v-html="label" />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";

.base-checkbox input {
  display: none;
}

.base-checkbox label::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: 1px solid $grey-4;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px;
  transition: all 0.2s;
}

.base-checkbox label {
  padding-left: 32px;
  color: $black-2;
  font-size: 15px;
  line-height: 26px;
  position: relative;
  cursor: pointer;
  display: block;
  :deep a {
    font-size: inherit;
    font-weight: 500;
    color: $green-5;
  }
}

.base-checkbox input:checked + label::before {
  background-image: url("@public/check-icon.svg");
  border-color: #009440;
  background-color: #009440;
}
</style>
