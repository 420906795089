import axios from "axios";
import store from "~/store/index";
import router from "../router";

axios.interceptors.request.use(
  (config) => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    // const cookies = useCookies(['locale'])
    // config.headers["X-XSRF-TOKEN"] = "";
    config.withCredentials = true;

    // config.headers["X-XSRF-TOKEN"] = decodeURIComponent(Cookies.get('laravel_session'));
    // config.headers["X-XSRF-TOKEN"] = decodeURIComponent(Cookies.get('XSRF-TOKEN'));

    const locale = store.getters["lang/locale"];
    if (locale) {
      config.headers["Accept-Language"] = locale;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 || error.response?.status === 419) {
      let currentRoute = router.currentRoute.value;

      const excludedRoutes = ["/"];

      if (currentRoute.name === "auth.login") {
        return;
        // return Promise.reject(error);
      }

      if (
        !excludedRoutes.includes(currentRoute.name) &&
        !excludedRoutes.includes(currentRoute.fullPath)
      ) {
        await router.replace({
          name: "auth.login",
          query: { redirect: currentRoute.fullPath },
        });
        return;
      } else if (excludedRoutes.includes(currentRoute.fullPath)) {
        await router.replace({ name: "auth.login", query: { loggedOut: 'true' } });
        return;
      }
    }
    if (error.response?.status === 419) {
      // TODO тут бы показывать флеш сообщение что куки прошли и пора перейти на страницу логина. Но при этом в урле сотавить путь на котором был пользователь в процессе чего он там был
    }


    return Promise.reject(error);
  }
);
