<template>
  <div class="mb-16 base-card-wrapper">
    <BaseListItemCard
      size="sm"
      class="grid grid-cols-12 gap-4 items-center min-w-max"
    >
      <div class="col-span-10 md:col-span-10 lg:col-span-10 xl:col-span-10 2xl:col-span-10 flex flex-col justify-center whitespace-nowrap truncate space-x-4">
        <span v-html="text" class="name text-base-span-md my-auto lg:text-center xl:text-left"></span>
      </div>
      <div class="col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 items-center flex">
        <InlineSvg
          class="svg-sm"
          src="/img/clock.svg"
        ></InlineSvg>
        <!--        <span class="text-base-small-2 grey-4-color">{{ $t("entity.creation_date") }}:</span><br>-->
        <span class="ml-8 text-base-span-md">{{date}}</span>
      </div>
    </BaseListItemCard>
  </div>
</template>

<script>
import contractHistoryType from "~/mixins/contractHistoryType";

export default {
  name: "ItemHistory",
  mixins: [contractHistoryType],
  props: {
    date: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    message() {
      return this.item.name;
    },
  },
};
</script>
