import { list as getList } from "~/api/payment.js";
import * as types from "../mutation-types";
import store from "../../store";

export const state = {
  list: null,
  listGroupByUser: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_PAYMENT_LIST](state, data) {
    console.log(data);
    state.list = data.data;
  },
  // [types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST_GROUP_USER](
  //   state,
  //   status
  // ) {
  //   state.isLoading = status;
  // },
  // [types.SET_CONTRACT_PAYMENT_LIST_GROUP_USER](state, data) {
  //   state.listGroupByUser = data.data;
  // },
};

export const actions = {
  fetchContractPayments({ commit }, activeContractId, params = {}) {
    commit(types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST, true);

    let contractId = activeContractId || store.getters["contract/activeContract"]?.id;

    getList(contractId, params).then((response) => {
      let { data } = response;
      commit(types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST, false);
      commit(types.SET_CONTRACT_PAYMENT_LIST, data);
    });




    // let activeContractId =
    //   data?.contractId || store.getters["contract/activeContract"]?.id;
    //
    // if (activeContractId) {
    //   if (data.typeId) {
    //     index(activeContractId, data.typeId).then((data) => {
    //       commit(
    //         types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST_GROUP_USER,
    //         false
    //       );
    //       commit(
    //         types.SET_CONTRACT_PAYMENT_LIST_GROUP_USER,
    //         data.data
    //       );
    //     });
    //   } else {
    //     index(activeContractId).then((data) => {
    //       commit(
    //         types.SET_IS_LOADING_CONTRACT_PAYMENT_LIST,
    //         false
    //       );
    //       commit(types.SET_CONTRACT_PAYMENT_LIST, data.data);
    //     });
    //   }
    // }
  },
};

export const getters = {
  list: (state) => state.list,
  // listGroupByUser: (state) => state.listGroupByUser,
  // isLoading: (state) => state.isLoading,
};
