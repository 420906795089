<template>
  <div class="flex justify-center items-center mt-20">
    <span>{{ labelFirst }}</span>
    <div
      :class="[
        'w-14 h-7 flex items-center rounded-full mx-3 px-1',
        toggleClasses,
      ]"
      @click="handleToggle"
    >
      <div
        class="bg-white w-5 h-5 rounded-full shadow-md transform"
        :class="{ 'translate-x-7': isToggled }"
      ></div>
    </div>
    <span>{{ labelSecond }}</span>
  </div>
</template>

<script>
export default {
  name: "BaseSwitch",
  props: {
    initialState: {
      type: Boolean,
      default: false,
    },
    labelFirst: {
      type: String,
      required: true,
    },
    labelSecond: {
      type: String,
      required: true,
    },
    toggleClasses: {
      type: String,
      default: "green-3-bg",
    },
  },
  data() {
    return {
      isToggled: this.initialState,
    };
  },
  methods: {
    handleToggle() {
      this.isToggled = !this.isToggled;
      this.$emit("toggle", this.isToggled);
    },
  },
};
</script>
