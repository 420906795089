import axios from "axios";
import { api } from "~/router/apiConfig";
import * as types from "../mutation-types";

/**
 * Initial state
 */

const TOKEN = window.localStorage.getItem("token");

export const state = {
  user: null,
  token: TOKEN || null,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_USER](state, { user }) {
    state.user = user;
    // eslint-disable-next-line no-undef
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("activeContract");
  },

  [types.FETCH_USER_FAILURE](state) {
    state.user = null;
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("activeContract");
    window.reload();
  },

  [types.SET_TOKEN](state, { token }) {
    state.token = token;
  },
};

/**
 * Actions
 */
export const actions = {
  saveToken({ commit }, data) {
    commit(types.SET_TOKEN, data);
    // if (this.rememberMe) {
    //   window.localStorage.setItem("token", data.data.token);
    // }
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await axios.get(api.path("me"));
      commit(types.SET_USER, data);
    } catch (e) {
      commit(types.FETCH_USER_FAILURE);
    }
  },

  setUser({ commit }, payload) {
    commit(types.SET_USER, payload);
  },

  async logout({ commit }) {
    // await axios.post(api.path("logout"));
    commit(types.LOGOUT);
  },

  destroy({ commit }) {
    commit(types.LOGOUT);
  },
};

/**
 * Getters
 */
export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
};
