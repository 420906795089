<template>
  <navbar layout="profile"></navbar>
  <main>
    <PromoPlan></PromoPlan>
    <section class="order-section">
      <router-view></router-view>
    </section>
  </main>
</template>

<script>
import Navbar from "../components/Navbar";

export default {
  components: {
    Navbar,
  },
  created: function () {
    this.$store.dispatch("user/fetchUser");
    this.$store.dispatch("counterparty/fetchCounterparties");
  },
};
</script>
