import axios from "axios";
import { api } from "~/router/apiConfig";

export function index(contractId, typeId = null) {
  return axios.get(
    api.path("contractBalanceReplenishmentHistory.index", {
      contractId: contractId,
    }),
    {
      params: {
        typeId: typeId,
      },
    }
  );
}
