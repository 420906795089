<template>
  <div>
    <vue-tel-input
      v-model="data"
      :id="id"
      @input="input($event)"
      @validate="validate"
      :autoDefaultCountry="autoDefaultCountry"
      :ignoredCountries="['RU']"
      :preferredCountries="['UA']"
      :inputOptions="{
        autocomplete: 'off',
        showDialCode: true,
        styleClasses: [
          validation.hasErrors === false && modelValue ? 'success-input' : '',
          validation.hasErrors === true ? 'error-input' : '',
        ],
      }"
      :placeholder="placeholder"
      :class="[
        inputWrapperClass,
        { 'validation-alert': isShowValidationIcon },
        { 'error-input': validation.hasErrors },
        { 'success-input': validation.hasErrors === false },
      ]"
      :required="required"
      :disabled="disabled"
      @change="changed()"
    />
  </div>
  <div v-if="validation.errors && validation.errors.length > 0">
    <ValidationInputMessage
      :key="index"
      v-for="(error, index) in validation.errors.slice(0, 1)"
      class="invalid-feedback show"
    >
      {{ $t(error) }}
    </ValidationInputMessage>
  </div>
</template>

<script>
import { required } from "~/validation/rules";

import checkValidationMixin from "~/mixins/checkValidation";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  components: {
    VueTelInput,
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    placeholder: {
      type: [String, Number, Object, Boolean],
      default: "",
    },
    rules: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withError: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modelValue: [String, Number, Object],
    class: [String],
    valid: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: false,
    },
    borderTransparent: {
      type: Boolean,
      default: false,
    },
    isShowValidationIcon: {
      type: Boolean,
      default: true,
    },
    maska: {
      type: String,
    },
  },
  mixins: [checkValidationMixin],
  created() {},
  data() {
    return {
      inputValue: null,
      autoDefaultCountry: false,
      data: this.modelValue,
      phoneObject: null,
      //TODO разобраться как мержиться миксины. И сделатьк красиво. что бы можно было добавлять
      // только необходимые правила,но не дублировать те которые есть в миксине.
      validation: {
        rules: {
          required: required,
          phone: this.checkPhone,
        },
      },
    };
  },
  computed: {
    inputClass() {
      return {
        "base-input": true,
        [`size-${this.size}`]: true,
        disabled: this.disabled,
        error: !this.valid,
      };
    },
    inputWrapperClass() {
      return {
        [`size-${this.size}`]: true,
        "state-disabled": this.disabled,
        "state-error": !this.valid,
        "full-width": this.fullWidth,
        "border-transparent": this.borderTransparent,
      };
    },
  },
  create() {
    //TODO будет когда то настроение и желание разобраться как это сделать хорошо, то сделать.
    //  Сейчас проблема в том, что при повторном монтировании(отображении) срабатывает autoDefaultCountry и  перебивает текущий номер телефона.
    // if (this.modelValue) {
    //   this.autoDefaultCountry = false;
    // } else {
    //   this.autoDefaultCountry = true;
    // }
  },
  methods: {
    checkPhone() {
      return (
        this.phoneObject?.valid === true || "validate.invalid_phone_number"
      );
    },
    validate: function (phoneObject) {
      this.phoneObject = phoneObject;
    },
    input: function (event) {
      let value = event.target.value;
      let isValidInput = /^(\+)?[0-9]*$/.test(value);

      if (isValidInput) {
        this.data = value;
        this.$emit("update:modelValue", value);
        this.inputValue = value

      }
    },
    getValue: function () {
      return (this.value ?? this.modelValue) ?? this.data;
    },
    changed: function () {
      this.makeValidation(this.rules);
    },
  },
  watch: {
    data(newVal) {
      let newValue = newVal;
      // Remove all non-numeric characters except the plus sign
      newValue = newValue.replace(/[^0-9+]/g, "");
      // Allow only one plus sign at the beginning
      newValue = newValue.replace(/(\+.*)\+/g, "$1");

      if (newVal !== newValue) {
        this.data = newValue;
      }
    },
    modelValue: function (value) {
      if (!value) {
        this.hasErrors = null;
      } else {
        //TODO костыль, что бы при превой загрузки данный в компонент, обработала их валидация.
        this.data = value;
        //TODO Super crutch for solving some kind of asynchronous problem in this library. But I'll figure it out later.
        setTimeout(() => {
          this.makeValidation(this.rules);
          this.validation.isFirstRunWatch = true;
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/design/mainIncludes";

.validation-alert {
  .error-input {
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("/public/image/icons/icon-info-danger.svg") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    border: none !important;
  }

  .success-input {
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("/public/image/icons/invitation-success-icon.svg") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    border: none !important;
  }
}

.error-input {
  border-color: $red-3 !important;
}

.success-input {
  border-color: $green-3 !important;
}

.base-input-wrapper {
  //display: flex;
  //width: fit-content;
  //height: fit-content;
  //overflow: hidden;
  //background: #fff;
  //border: 1px solid $grey-2;
  //border-radius: 4px;
  //box-sizing: border-box;
  //align-items: center;
  //column-gap: 2px;
  //flex-direction: row;
  //padding: 0;

  &.valid-danger {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
  }

  //&.error-input {
  //  border-color: $red-3 !important;
  //}

  &:focus-within {
    border: 1px solid $grey-5;
  }

  &.state-disabled {
    border: 1px solid $grey-3;
  }

  &.state-error,
  &.state-error:focus-within {
    border: 1px solid $red-3;
  }

  &.full-width {
    width: 100%;
  }

  &.border-transparent {
    border-color: transparent;
  }

  &.text-center {
    .base-input {
      text-align: center;
    }
  }

  &.table-input {
    &.state-disabled {
      border-color: #f4f7f4;
    }

    .base-input {
      &.disabled {
        background: #f4f7f4;
        color: $grey-4;
        opacity: 1;
      }
    }
  }

  &.size-lg {
    column-gap: 6px;
  }

  img.icon {
    order: -1;
    width: 20px;
    height: 20px;
    margin: 0 0 0 12px;

    &-right {
      margin: 0 12px 0 0;
      order: 0;
    }

    &.error {
      filter: brightness(80%) sepia(100%) hue-rotate(-50deg) saturate(8)
        contrast(1);
    }

    @include maxWidth768 {
      width: 15px;
      height: 15px;
    }
  }

  .base-input {
    width: 100%;
    outline: none;
    background: transparent;
    color: $black;
    border-radius: inherit;
    border: none;
    box-sizing: border-box;
    height: auto;
    padding: 4px 2px;
    -webkit-appearance: none;

    &.size-sm {
      font-size: 14px;
    }

    &.size-md {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      height: 38px;
    }

    &.size-lg {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 10px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.error {
      color: $red-3;
    }

    &::placeholder {
      color: $black-2;
    }
  }

  .span-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>
