import axios from "axios";
import { api } from "~/router/apiConfig";

export function resetPassword(token,request) {
  return axios.post(api.path("resetPassword", {token: token}), request);
}

export function verifyResetPassword(hash) {
  return axios.get(api.path("verifyResetPassword", {
    token: hash,
  }));
}


export function forgotPassword(request) {
  return axios.post(api.path("forgotPassword"), request);
}

export function csrf() {
  return axios.get(api.path("csrf"));
}

export function logout() {
  return axios.post(api.path("logout"));
}

export function verify(hash) {
  return axios.post(
    api.path("verify"),{
    token: hash,
    }
  );
}


