import axios from "axios";
import { api } from "~/router/apiConfig";

export function list(contractId) {
  return axios.get(
    api.path("contractDomain.index", { contractId: contractId })
  );
}

export function create(contractId, request) {
  return axios.post(
    api.path("contractDomain.store", { contractId: contractId }),
    request
  );
}
//
// export function update(contractId, domainId, request) {
//   return axios.put(
//     api.path("domain.update", { contractId: contractId, domainId: domainId }),
//     request
//   );
// }
//
export function show(contractId, domainId) {
  return axios.get(
    api.path("contractDomain.show", {
      contractId: contractId,
      domainId: domainId,
    })
  );
}
//
// export function remove(contractId, domainId) {
//   return axios.delete(
//     api.path("domain.remove", {
//       contractId: contractId,
//       domainId: domainId,
//     })
//   );
// }

// export function removeRecord(contractId, domainId, settingId) {
//   return axios.delete(
//     api.path("domain.removeRecord", {
//       contractId: contractId,
//       domainId: domainId,
//       settingId: settingId,
//     })
//   );
// }

// export function turnOn(contractId, domainId, request) {
//   return axios.put(
//     api.path("domain.turnOn", { contractId: contractId, domainId: domainId }),
//     request
//   );
// }
//
// export function turnOff(contractId, domainId, request) {
//   return axios.put(
//     api.path("domain.turnOff", { contractId: contractId, domainId: domainId }),
//     request
//   );
// }
