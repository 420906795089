import { TYPES_LIST } from "~/constants/historyTypeConfigs";

const historyMixin = {
  methods: {
    /**
     * Fetches the type configuration for a given history item.
     * @param {Object} item - The history item.
     * @returns {Object|null} - The type configuration, or null if not found.
     */
    getTypeConfig(item) {
      const typeConfig = TYPES_LIST[item.typeHistoryId]?.[item.typeId];

      if (!typeConfig) {
        console.error(`Missing type configuration for typeId: ${item.typeId}`);
        return null;
      }

      return typeConfig;
    },

    /**
     * Determines if the history item represents an error.
     * @param {Object} item - The history item.
     * @returns {boolean} - True if the item represents an error, false otherwise.
     */
    getIsError(item) {
      const typeConfig = this.getTypeConfig(item);
      return typeConfig ? typeConfig.isError ?? false : "";
    },

    /**
     * Gets the text for the history item, including any dynamic parameters.
     * @param {Object} item - The history item.
     * @returns {string} - The localized text for the item.
     */
    getText(item) {
      const typeConfig = this.getTypeConfig(item);
      if (!typeConfig) return "";

      const textParams = item.info;
      let langParams = typeConfig.langTextParams ? typeConfig.langTextParams(textParams, this.$t) : {};

      if (typeConfig.route) {
        const routeParams = typeConfig.routeParams ? typeConfig.routeParams(item) : {};
        const hash = typeConfig.hash ? typeConfig.hash(item.entityId) : "";

        langParams.link = this.$router.resolve({
          name: typeConfig.route,
          params: routeParams,
          hash: hash,
        }).href;
      }

      return this.$t(typeConfig.langKeyNotificationAction, langParams);
    },

    /**
     * Gets the icon for the history item.
     * @param {Object} item - The history item.
     * @returns {string|null} - The icon for the item, or null if not defined.
     */
    getIcon(item) {
      const typeConfig = this.getTypeConfig(item);
      return typeConfig ? typeConfig.icon : null;
    },

    /**
     * Gets the title for the history item, including any dynamic parameters.
     * @param {Object} item - The history item.
     * @returns {string} - The localized title for the item.
     */
    getTitle(item) {
      const typeConfig = this.getTypeConfig(item);
      if (!typeConfig) return "";

      const langTitleParams = typeConfig.langTitleParams ? typeConfig.langTitleParams(item.info) : {};

      return this.$t(typeConfig.langKeyNotificationTitle, langTitleParams);
    }
  }
};

export default historyMixin;
