import {COUNTERPARTY_ADMIN_ROLE_ID, COUNTERPARTY_MANAGER_TYPE_ID} from "~/constants/counterparty/roleType"

/**
 * Initial state
 */

/**
 * ContractHistory Type
 */
// const contractHistoryTypeDatabaseCreating = - 51;
// const contractHistoryTypeDatabaseCreate = 51;
// const contractHistoryTypeDatabaseUpdating = -52;
// const contractHistoryTypeDatabaseUpdate = 52;
// const contractHistoryTypeDatabaseTurningOn = -53;
// const contractHistoryTypeDatabaseTurnOn = 53;
// const contractHistoryTypeDatabaseTurningOff = -54;
// const contractHistoryTypeDatabaseTurnOff = 54;
// const contractHistoryTypeDatabaseRemoving = -55;
// const contractHistoryTypeDatabaseRemove = 55;

/**
 * Customer user types
 */
const contractCustomerStorageTypeId = 1;
const contractCustomerDatabaseTypeId = 2;
// eslint-disable-next-line no-unused-vars
const contractCustomerStorageName = "storage";
// eslint-disable-next-line no-unused-vars
const contractCustomerDatabaseName = "database";

/**
 * Certificate types id
 */
const customCertificateTypeId = 10;
const letsEncryptCertificateTypeId = 20;
const selfSignedCertificateTypeId = 30;

/**
 * Certificate types names
 */
const customCertificateTypeName = "custom";
const letsEncryptCertificateTypeName = "letsEncrypt";
const selfSignedCertificateTypeName = "selfSigned";

/**
 * Entity status
 */
const entityDeclaredStatusId = 10;
const entityActivatedStatusId = 20;
const entityUpdatingStatusId = 30;
const entityRemovingStatusId = 40;
const entityTurnsOnStatusId = 60;
const entityTurnsOffStatusId = 70;
const entityInactiveStatusId = 80;

const entityDeclaredStatusName = "declared";
const entityActivatedStatusName = "activated";
const entityUpdatingStatusName = "updated";
const entityRemovingStatusName = "removed";
const entityTurnsOnStatusName = "turnsOn";
const entityTurnsOffStatusName = "turnsOff";
const entityInactiveStatusName = "inactive";

/**
 * Сounterparty User Types
 */
const counterpartyLegalTypeId = 2;
const counterpartyIndividualTypeId = 1;
// const counterpartySoleProprietorshipTypeId = 3;
const counterpartyLegalTypeName = "legal";
const counterpartyIndividualTypeName = "individual";
// const counterpartySoleProprietorshipTypeName = "soleProprietorship";

/**
 * Counterparty Roles
 */
const counterpartyAdminRoleName = "admin";
const counterpartyManagerTypeName = "manager";

/**
 * Сounterparty Roles
 */
const contractAdminRoleId = 1;
const contractFinanceTypeId = 2;
const contractTechnicalTypeId = 3;
const contractAdminRoleName = "admin";
const contractFinanceTypeName = "finance_user";
const contractTechnicalTypeName = "technical_user";

export const state = {
  //Certificate
  customCertificateTypeId: customCertificateTypeId,
  letsEncryptCertificateTypeId: letsEncryptCertificateTypeId,
  selfSignedCertificateTypeId: selfSignedCertificateTypeId,
  customCertificateTypeName: customCertificateTypeName,
  letsEncryptCertificateTypeName: letsEncryptCertificateTypeName,
  selfSignedCertificateTypeName: selfSignedCertificateTypeName,
  //
  contractCustomerStorageTypeId: contractCustomerStorageTypeId,
  contractCustomerDatabaseTypeId: contractCustomerDatabaseTypeId,
  counterpartyLegalTypeId: counterpartyLegalTypeId,
  counterpartyLegalTypeName: counterpartyLegalTypeName,
  counterpartyIndividualTypeId: counterpartyIndividualTypeId,
  counterpartyIndividualTypeName: counterpartyIndividualTypeName,
  entityDeclaredStatusId: entityDeclaredStatusId,
  entityActivatedStatusId: entityActivatedStatusId,
  entityUpdatingStatusId: entityUpdatingStatusId,
  entityTurnsOnStatusId: entityTurnsOnStatusId,
  entityTurnsOffStatusId: entityTurnsOffStatusId,
  entityInactiveStatusId: entityInactiveStatusId,
  certificateType: [
    {
      id: customCertificateTypeId,
      name: customCertificateTypeName,
    },
    {
      id: letsEncryptCertificateTypeId,
      name: letsEncryptCertificateTypeName,
    },
    {
      id: selfSignedCertificateTypeId,
      name: selfSignedCertificateTypeName,
    },
  ],
  counterpartyUserType: [
    {
      id: counterpartyIndividualTypeId,
      name: counterpartyIndividualTypeName,
    },
    {
      id: counterpartyLegalTypeId,
      name: counterpartyLegalTypeName,
    },
    // {
    //   id: counterpartySoleProprietorshipTypeId,
    //   name: counterpartySoleProprietorshipTypeName,
    // },
  ],
  counterpartyRoleTypes: [
    {
      id: COUNTERPARTY_ADMIN_ROLE_ID,
      name: counterpartyAdminRoleName,
    },
    {
      id: COUNTERPARTY_MANAGER_TYPE_ID,
      name: counterpartyManagerTypeName,
    },
  ],
  entityStatuses: [
    {
      id: entityDeclaredStatusId,
      name: entityDeclaredStatusName,
    },
    {
      id: entityActivatedStatusId,
      name: entityActivatedStatusName,
    },
    {
      id: entityUpdatingStatusId,
      name: entityUpdatingStatusName,
    },
    {
      id: entityRemovingStatusId,
      name: entityRemovingStatusName,
    },
    {
      id: entityTurnsOnStatusId,
      name: entityTurnsOnStatusName,
    },
    {
      id: entityTurnsOffStatusId,
      name: entityTurnsOffStatusName,
    },
    {
      id: entityInactiveStatusId,
      name: entityInactiveStatusName,
    },
  ],
  contractRoleTypes: [
    {
      id: contractAdminRoleId,
      name: contractAdminRoleName,
    },
    {
      id: contractFinanceTypeId,
      name: contractFinanceTypeName,
    },
    {
      id: contractTechnicalTypeId,
      name: contractTechnicalTypeName,
    },
  ],
  databaseTypesId: [
    {
      name: "Mysql 8.0",
      id: 1,
    },
  ],
  refsGlobalList: {
    siteForm: {
      storagesList: "storagesList",
    },
  },
  contractHistoryTypeDatabaseCreating: -51,
  contractHistoryTypeDatabaseCreate: 51,
  contractHistoryTypeDatabaseUpdating: -52,
  contractHistoryTypeDatabaseUpdate: 52,
  contractHistoryTypeDatabaseTurningOn: -53,
  contractHistoryTypeDatabaseTurnOn: 53,
  contractHistoryTypeDatabaseTurningOff: -54,
  contractHistoryTypeDatabaseTurnOff: 54,
  contractHistoryTypeDatabaseRemoving: -55,
  contractHistoryTypeDatabaseRemove: 55,
};

/**
 * Getters
 */
export const getters = {
  contractCustomerStorageTypeId: (state) => state.contractCustomerStorageTypeId,
  contractCustomerDatabaseTypeId: (state) =>
    state.contractCustomerDatabaseTypeId,

  counterpartyUserType: (state) => state.counterpartyUserType,
  counterpartyLegalTypeId: (state) => state.counterpartyLegalTypeId,
  counterpartyLegalTypeName: (state) => state.counterpartyLegalTypeName,
  counterpartyIndividualTypeId: (state) => state.counterpartyIndividualTypeId,
  counterpartyIndividualTypeName: (state) =>
    state.counterpartyIndividualTypeName,
  counterpartyRoleTypes: (state) => state.counterpartyRoleTypes,
  contractRoleTypes: (state) => state.contractRoleTypes,
  refsGlobalList: (state) => state.refsGlobalList,
  entityDeclaredStatusId: (state) => state.entityDeclaredStatusId,
  entityActivatedStatusId: (state) => state.entityActivatedStatusId,
  entityUpdatingStatusId: (state) => state.entityUpdatingStatusId,
  entityRemovingStatusId: (state) => state.entityRemovingStatusId,
  entityTurnsOnStatusId: (state) => state.entityTurnsOnStatusId,
  entityTurnsOffStatusId: (state) => state.entityTurnsOffStatusId,
  entityInactiveStatusId: (state) => state.entityInactiveStatusId,
  // Certificate
  certificateType: (state) => state.certificateType,
  customCertificateTypeId: (state) => state.customCertificateTypeId,
  letsEncryptCertificateTypeId: (state) => state.letsEncryptCertificateTypeId,
  selfSignedCertificateTypeId: (state) => state.selfSignedCertificateTypeId,
  customCertificateTypeName: (state) => state.customCertificateTypeName,
  letsEncryptCertificateTypeName: (state) =>
    state.letsEncryptCertificateTypeName,
  selfSignedCertificateTypeName: (state) => state.selfSignedCertificateTypeName,
  databaseTypesId: (state) => state.databaseTypesId,
  contractHistoryTypeDatabaseCreating: (state) =>
    state.contractHistoryTypeDatabaseCreating,
  contractHistoryTypeDatabaseCreate: (state) =>
    state.contractHistoryTypeDatabaseCreate,
  contractHistoryTypeDatabaseUpdating: (state) =>
    state.contractHistoryTypeDatabaseUpdating,
  contractHistoryTypeDatabaseUpdate: (state) =>
    state.contractHistoryTypeDatabaseUpdate,
  contractHistoryTypeDatabaseTurningOn: (state) =>
    state.contractHistoryTypeDatabaseTurningOn,
  contractHistoryTypeDatabaseTurnOn: (state) =>
    state.contractHistoryTypeDatabaseTurnOn,
  contractHistoryTypeDatabaseTurningOff: (state) =>
    state.contractHistoryTypeDatabaseTurningOff,
  contractHistoryTypeDatabaseTurnOff: (state) =>
    state.contractHistoryTypeDatabaseTurnOff,
  contractHistoryTypeDatabaseRemoving: (state) =>
    state.contractHistoryTypeDatabaseRemoving,
  contractHistoryTypeDatabaseRemove: (state) =>
    state.contractHistoryTypeDatabaseRemove,
};
