export const DATABASE_CREATED = "s0075";
export const DATABASE_CREATE_ERROR = "e0075";
export const DATABASE_UPDATED = "s0076";
export const DATABASE_UPDATE_ERROR = "e0076";
export const DATABASE_TURNED_ON = "s0077";
export const DATABASE_TURN_ON_ERROR = "e0077";
export const DATABASE_TURNED_OFF = "s0078";
export const DATABASE_TURN_OFF_ERROR = "e0078";
export const DATABASE_REMOVED = "s0079";
export const DATABASE_REMOVE_ERROR = "e0079";


export const database = {
  [DATABASE_CREATED]: {
    icon: "/img/database.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.database.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.database.title",
     routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_UPDATED]: {
    icon: "/img/database.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.database.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.database.title",
     routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.update"),
      entityType: $t("notification.contract.entity.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_REMOVED]: {
    icon: "/img/database.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.database.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.database.title",
     routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.delete"),
      entityType: $t("notification.contract.entity.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_TURNED_ON]: {
    icon: "/img/database.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.database.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.database.title",
     routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOn"),
      entityType: $t("notification.contract.entity.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_TURNED_OFF]: {
    icon: "/img/database.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.database.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.database.title",
     routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOff"),
      entityType: $t("notification.contract.entity.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
}