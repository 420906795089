import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("contractStorage.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, storageId, request) {
  return axios.put(
    api.path("contractStorage.update", {
      contractId: contractId,
      storageId: storageId,
    }),
    request
  );
}

export function show(contractId, storageId) {
  return axios.get(
    api.path("contractStorage.show", {
      contractId: contractId,
      storageId: storageId,
    })
  );
}

export function list(contractId) {
  return axios.get(
    api.path("contractStorage.index", { contractId: contractId })
  );
}

export function checkRemove(request) {
  return axios.post(api.path("contractStorage.checkRemove"), request);
}

export function remove(contractId, storageId) {
  return axios.delete(
    api.path("contractStorage.remove", {
      contractId: contractId,
      storageId: storageId,
    })
  );
}

export function turnOn(contractId, storageId) {
  return axios.put(
    api.path("contractStorage.turnOn", {
      contractId: contractId,
      storageId: storageId,
    }),
  );
}

export function turnOff(contractId, storageId, request) {
  return axios.put(
    api.path("contractStorage.turnOff", {
      contractId: contractId,
      storageId: storageId,
    }),
    request
  );
}
