import { isEmpty } from "../utils.js";
// import i18n from "~/plugins/i18n";

const emailValidator = (value) => {
  //TODO This check is not needed here, since we need to check the email, and not the presence of text
  // if (isEmpty(value)) {
  //   return "the_field_must_be_required";
  // }

  if (isEmpty(value)) {
    return true;
  }

  const re =
    /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (Array.isArray(value)) {
    return (
      value.every((val) => re.test(String(val))) ||
      "validate.this_field_must_be_a_valid_email"
    );
  }
  return re.test(String(value)) || "validate.this_field_must_be_a_valid_email";
};

export { emailValidator };
