import { index as getData } from "~/api/userReferralProgram";
import * as types from "../mutation-types";


export const state = {
  data: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_USER_REFERRAL_PROGRAM](state, status) {
    state.isLoading = status;
  },
  [types.SET_USER_REFERRAL_PROGRAM](state, data) {
    state.data = data;
  }
};

export const actions = {
  fetchUserReferralProgram({ commit } = null) {
    commit(types.SET_IS_LOADING_USER_REFERRAL_PROGRAM, true);

    getData().then((response) => {
      let { data } = response;

      commit(types.SET_USER_REFERRAL_PROGRAM, data.data);
      commit(types.SET_IS_LOADING_USER_REFERRAL_PROGRAM, false);
    });
  },
};

export const getters = {
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
};
