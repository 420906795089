<template>
  <div
    class="base-copier text-base-span-md"
    :class="{ 'full-width': fullWidth }"
    @click="copyValue(text)"
  >
    <span v-if="!isCopied">{{ text }}</span>
    <span v-else>{{$t("copied")}}!</span>
    <img class="icon" :src="`/image/icons/${icon}.svg`" alt="icon" />
  </div>
</template>

<script>
import { isMobile } from "~/mixins/isMobile";

export default {
  name: "BaseCopier",
  mixins: [isMobile],
  props: {
    text: String,
    fullWidth: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "new-copy-link-icon",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {},
  methods: {
    copyValue(val) {
      navigator.clipboard.writeText(`${val}`);
      this.isCopied = true;
      setTimeout(() => (this.isCopied = false), 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/design/mainIncludes";
.base-copier {
  display: flex;
  min-width: auto;
  column-gap: 20px;
  align-items: center;
  padding: 0 16px;
  color: $grey-4;
  background: $white-2;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
  span {
    overflow: hidden;
  }
  &.full-width {
    width: 100%;
  }
  img {
    height: 20px;
    width: 20px;
    margin-left: auto;
  }
}
</style>
