import { defineRule, configure } from "vee-validate";
import {
  min,
  max,
  confirmed,
  required,
  itn,
  edrpou,
  zipCode,
  requiredIf,
  mobilePhone,
  dateTo,
} from "~/validation/rules";

// defineRule("email", email);
defineRule("min", min);
defineRule("max", max);
defineRule("required", required);
defineRule("confirmed", confirmed);
defineRule("itn", itn);
defineRule("edrpou", edrpou);
defineRule("zipCode", zipCode);
defineRule("requiredIf", requiredIf);
defineRule("mobilePhone", mobilePhone);
defineRule("dateTo", dateTo);

configure();
