import axios from "axios";
import { api } from "~/router/apiConfig";

export function list() {
  return axios.get(api.path("userReferralWithdrawal.index"), null);
}

export function create(request) {
  return axios.post(api.path("userReferralWithdrawal.store"), request);

}