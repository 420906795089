<template>
  <teleport to="#app">
    <transition name="modal-transition">
      <div class="modal-mask" v-if="show" @click="$emit('close')">
        <div class="modal-wrapper">
          <div class="modal-container"
               :class="this.class"
               @click.stop>
            <div v-if="isLoading" class="modal-loader">
              <div class="spinner"></div>
            </div>
            <button class="modal-close" @click="$emit('close')"></button>
            <div class="modal-header" v-if="header">{{ header }}</div>
            <div class="modal-descr" v-html="descr" v-if="descr"></div>
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  name: "BaseModal",
  emits: ["close"],
  props: {
    class: String,
    isLoading: Boolean,
    header: String,
    descr: String,
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
