//CERTIFICATE
export const CERTIFICATE_CREATED = "s0085";
export const CERTIFICATE_CREATE_ERROR = "e0085";
export const CERTIFICATE_UPDATED = "s0086";
export const CERTIFICATE_UPDATE_ERROR = "e0086";
export const CERTIFICATE_TURNED_ON = "s0087";
export const CERTIFICATE_TURN_ON_ERROR = "e0087";
export const CERTIFICATE_TURNED_OFF = "s0088";
export const CERTIFICATE_TURN_OFF_ERROR = "e0088";
export const CERTIFICATE_REMOVED = "s0089";
export const CERTIFICATE_REMOVE_ERROR = "e0089";


export const certificate = {
  [CERTIFICATE_CREATED]: {
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [CERTIFICATE_UPDATED]: {
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.update"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [CERTIFICATE_REMOVED]: {
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.delete"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [CERTIFICATE_TURNED_ON]: {
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOn"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [CERTIFICATE_TURNED_OFF]: {
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOff"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [CERTIFICATE_CREATE_ERROR]: {
    isError: true,
    icon: "/img/certificate.svg",
    langKeyHistory: "notification.contract_entity.error",
    route: "contract.cabinet.certificate.index",
    langKeyNotificationAction: "notification.contract_entity.error",
    langKeyNotificationTitle: "notification.contract_entity.certificate.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.certificate"),
      entityName: data.subjectName,
      autoDeleteMessage: $t("notification.contract_entity.auto_delete", {entityType: $t("notification.contract.entity.certificate")}),
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
  },
}