import { list as getList } from "~/api/userInvitationCounterparty.js";
import * as types from "../mutation-types";

export const state = {
  list: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOAD_USER_INVITATION_COUNTERPARTIES](state, status) {
    state.isLoading = status;
  },
  [types.SET_USER_INVITATION_COUNTERPARTIES](state, data) {
    state.list = data;
  },
};

export const actions = {
  fetchUserInvitationCounterparties({ commit }, userId) {
    commit(types.SET_IS_LOAD_USER_INVITATION_COUNTERPARTIES, true);

    getList(userId).then((response) => {
      let { data } = response;
      commit(types.SET_USER_INVITATION_COUNTERPARTIES, data.data);
      commit(types.SET_IS_LOAD_USER_INVITATION_COUNTERPARTIES, false);
    });
  },
};

export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
