import { list as getList } from "~/api/contractProcessorPhpParams.js";
import * as types from "../mutation-types";

export const state = {
  list: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_DEFAULT_CONTRACT_PROCESSOR_PHP_PARAMS](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_DEFAULT_PROCESSOR_PHP_PARAMS](
    state,
    processors
  ) {
    state.list = processors;
  },
};

export const actions = {
  fetchContractDefaultProcessorPhpParams({ commit }) {
    commit(types.SET_IS_LOADING_DEFAULT_CONTRACT_PROCESSOR_PHP_PARAMS, true);
    getList().then((response) => {
      let { data } = response;

      commit(types.SET_CONTRACT_DEFAULT_PROCESSOR_PHP_PARAMS, data.data);
      commit(types.SET_IS_LOADING_DEFAULT_CONTRACT_PROCESSOR_PHP_PARAMS, false);
    });
  },
};

export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
