import layout from "~/layout/App";
import auth from "../middleware/auth";

const profileRoutes = {
  path: "/profile",
  redirect: "/profile/info",
  component: layout,
  name: "profile",
  children: [
    {
      path: "info",
      component: () => import("~/views/Profile/Info"),
      name: "profile.info",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "notifications",
      component: () => import("~/views/Profile/Notifications"),
      name: "profile.notifications",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "security",
      component: () => import("~/views/Profile/Security"),
      name: "profile.security",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "settings",
      component: () => import("~/views/Profile/Settings"),
      name: "profile.settings",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default profileRoutes;
