import { list as getList } from "~/api/contractBackupStorage";
import * as types from "../mutation-types";
import store from "../../store";

export const state = {
  list: null,
  isLoading: false,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_BACKUP_STORAGE](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_BACKUP_STORAGE](state, contractStorage) {
    state.list = contractStorage.data;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchContractBackupStorage({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_BACKUP_STORAGE, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_BACKUP_STORAGE, data);
        commit(types.SET_IS_LOADING_CONTRACT_BACKUP_STORAGE, false);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
