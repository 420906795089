export default {
  computed: {
    activeContractName() {
      return this.getContractName();
    }
  },
  methods: {
    getContractName() {
      return this.$route.params.contractName;
    }
  }
};