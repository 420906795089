import layout from "~/layout/App";
import auth from "../middleware/auth";

const counterpartyRoutes = {
  path: "/counterparty",
  component: layout,
  name: "counterparty",
  children: [
    {
      path: "",
      component: () => import("~/views/Counterparty/Index"),
      name: "counterparty.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "create",
      component: () => import("~/views/Counterparty/Create"),
      name: "counterparty.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: ":counterpartyId/update",
      component: () => import("~/views/Counterparty/Update"),
      name: "counterparty.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: ":counterpartyId/user",
      component: () => import("~/views/Counterparty/User"),
      name: "counterparty.user",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default counterpartyRoutes;
