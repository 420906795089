<template>
  <div class="cabinet_sidebar">
    <router-link :to="{ name: 'home.index' }">
      <div class="cabinet_logo">
        <img src="/image/header-logo.svg" alt="" />
      </div>
    </router-link>
    <ul class="cabinet_sidebar_menu">
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('counterparty.') === 0,
            },
          ]"
          :to="{ name: 'counterparty.index' }"
        >
          <BaseIcon
            icon="contragent-icon"
            :active="this.activeRoute.indexOf('counterparty.') === 0"
          />
          <span>{{ $t("counterparties") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('contract.') === 0,
            },
          ]"
          :to="{ name: 'contract.index' }"
        >
          <BaseIcon
            icon="contract-icon"
            :active="this.activeRoute.indexOf('contract.') === 0"
          />
          <span>{{ $t("contracts") }}</span>
        </router-link>
      </li>
      <!--      <li>-->
      <!--        <router-link-->
      <!--          :class="[-->
      <!--            'disabled',-->
      <!--            'text-base-small grey-5-color',-->
      <!--            {-->
      <!--              active: this.activeRoute.indexOf('contacts.') === 0,-->
      <!--            },-->
      <!--          ]"-->
      <!--          :to="{ name: 'contacts.index' }"-->
      <!--        >-->
      <!--          <BaseIcon-->
      <!--            icon="contacts-icon"-->
      <!--            :active="this.activeRoute.indexOf('contacts.') === 0"-->
      <!--          />-->
      <!--          <span>{{ $t("contacts") }}</span>-->
      <!--        </router-link>-->
      <!--      </li>-->
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('referral.') === 0,
            },
          ]"
          :to="{ name: 'referral.program' }"
        >
          <BaseIcon
            icon="referrals-icon"
            :active="this.activeRoute.indexOf('referral.') === 0"
          />
          <span>{{ $t("referral_program") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('profile.') === 0,
            },
          ]"
          :to="{ name: 'profile.info' }"
        >
          <BaseIcon
            icon="setting-icon"
            :active="this.activeRoute.indexOf('profile.') === 0"
          />
          <span>{{ $t("personal_settings") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('history.') === 0,
            },
          ]"
          :to="{ name: 'history.user.index' }"
        >
          <BaseIcon
            icon="history-icon"
            :active="this.activeRoute.indexOf('history.') === 0"
          />
          <span>{{ $t("sidebar.history") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :class="[
            'text-base-small grey-5-color',
            {
              active: this.activeRoute.indexOf('invitation.') === 0,
            },
          ]"
          :to="{ name: 'invitation.counterparties' }"
        >
          <BaseIcon
            icon="invitation-icon"
            :active="this.activeRoute.indexOf('invitation.') === 0"
          />
          <span>{{ $t("invitations") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activeRoute: this.$route.name
    };
  },
  watch: {
    "$route.name": {
      handler: function(name) {
        this.activeRoute = name;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
