import layout from "~/layout/App/index.vue";
import auth from "../middleware/auth";
import processorsRoutes from "~/router/modules/processorsRoutes";
import store from "../../store";
import PageNotFound from "~/views/404.vue";

const routes = {
  path: "/cabinet/:contractName/contract",
  component: layout,
  name: "contract.cabinet",
  // beforeEnter: async (to, from, next) => {
    // try {
    //   const contractName = to.params.contractName;
    //   const activeContract = store.getters['contract/activeContract'];
    //
    //   if (!store.getters['contract/list'] || store.getters['contract/list'].length === 0) {
    //     await store.dispatch('contract/fetchContracts');
    //   }
    //
    //   const contracts = store.getters['contract/list'];
    //   const foundContract = contracts.find(contract =>
    //     contract.name === contractName && contract.userRights && contract.userRights.isConnected === true
    //   );
    //
    //   if (foundContract) {
    //     if (activeContract && activeContract.name === contractName) {
    //       next();
    //     } else {
    //       await store.dispatch('contract/setActiveContract', foundContract.id);
    //       next();
    //     }
    //   } else {
    //     next('404');
    //   }
    // } catch (error) {
    //   console.error('Error during route resolution:', error);
    //   next('404');
    // }
  // },
  props: {
    navbarType: "main",
  },
  children: [
    ...processorsRoutes,
    {
      path: '/404',
      name: 'PageNotFound',
      component: PageNotFound
    },
    {
      path: "",
      component: () => import("~/views/ContractDashboard/Index"),
      name: "contract.cabinet.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "site",
      component: () => import("~/views/Sites/Index"),
      name: "contract.cabinet.site.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "site/create",
      component: () => import("~/views/Sites/Create"),
      name: "contract.cabinet.site.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "site/:siteId/edit",
      component: () => import("~/views/Sites/Update"),
      name: "contract.cabinet.site.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "customers",
      component: () => import("~/views/User/Index"),
      name: "contract.cabinet.customers.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "customers-db",
      component: () => import("~/views/User/Index"),
      name: "contract.cabinet.customers.db.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "storage",
      component: () => import("~/views/Storage/Index"),
      name: "contract.cabinet.storage.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "storage-manager",
      component: () => import("~/views/StorageManager/Index"),
      name: "contract.cabinet.storageManager.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "storage/create",
      component: () => import("~/views/Storage/Create"),
      name: "contract.cabinet.storage.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "storage/:storageId/edit",
      component: () => import("~/views/Storage/Update"),
      name: "contract.cabinet.storage.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "load",
      component: () => import("~/views/Load/Index"),
      name: "contract.cabinet.load.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "databases",
      component: () => import("~/views/Databases/Index"),
      name: "contract.cabinet.database.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "database/:typeDBId/:typeVersionId/create",
      component: () => import("~/views/Databases/Create"),
      name: "contract.cabinet.database.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "database/:databaseId/edit",
      component: () => import("~/views/Databases/Edit"),
      name: "contract.cabinet.database.edit",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "certificates",
      component: () => import("~/views/Certificates/Index"),
      name: "contract.cabinet.certificate.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "certificate/create",
      component: () => import("~/views/Certificates/Create"),
      name: "contract.cabinet.certificate.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "certificate/:certificateId/edit",
      component: () => import("~/views/Certificates/Edit"),
      name: "contract.cabinet.certificate.edit",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "balance",
      component: () => import("~/views/Balance/Index"),
      name: "contract.cabinet.balance",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "domains",
      component: () => import("~/views/Domains/Index"),
      name: "contract.cabinet.domains.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "domains/:domainId/edit",
      component: () => import("~/views/Domains/Update"),
      name: "contract.cabinet.domains.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "domain/:domainId/records",
      component: () => import("~/views/Domains/Records.vue"),
      name: "contract.cabinet.domains.records.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "cache",
      component: () => import("~/views/Cache/Index"),
      name: "contract.cabinet.cache.index",
      meta: {
        middleware: auth,
      },
    },
    // {
    //   path: "processors",
    //   component: () => import("~/views/Processors/Index"),
    //   name: "contract.cabinet.processors.index",
    //   meta: {
    //     middleware: auth,
    //   },
    // },
    {
      path: "processors/edit",
      component: () => import("~/views/Processors/Update"),
      name: "contract.cabinet.processors.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "logs",
      component: () => import("~/views/Logs/Index"),
      name: "contract.cabinet.logs.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "logs/details",
      component: () => import("~/views/Logs/Details"),
      name: "contract.cabinet.logs.details",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "crons",
      component: () => import("~/views/Crons/Index"),
      name: "contract.cabinet.crons.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "crons/:cronId/edit",
      component: () => import("~/views/Crons/Update"),
      name: "contract.cabinet.crons.update",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "crons/create",
      component: () => import("~/views/Crons/Create"),
      name: "contract.cabinet.crons.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "backups",
      component: () => import("~/views/Backups/Index"),
      name: "contract.cabinet.backups.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "histories",
      component: () => import("~/views/ContractHistory/Index"),
      name: "contract.cabinet.history.index",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default routes;
