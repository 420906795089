import { isEmpty } from "../utils.js";
import moment from 'moment/src/moment';
import i18n from "~/plugins/i18n";

const dateToValidator = (value, params) => {
  let dateTo = params[0] ?? moment();

  if (isEmpty(value)) {
    return true;
  }

  if (value.length !== 10) {
    return i18n.global.t("validate.date_format_is_not_correct");
  }

  return (
    moment(value, "DD.MM.YYYY").isBefore(dateTo) ||
    i18n.global.t("validate.date_to", { date: dateTo.format("DD.MM.YYYY") })
  );
};

export { dateToValidator };
