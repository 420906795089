// src/plugins/echo.js
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { api } from "~/router/apiConfig";
import axios from "axios";

window.Pusher = Pusher;

export function setupEcho() {
  let path = api.path("broadcasting.auth");

  window.Echo = new Echo({
    broadcaster: "pusher",
    wsHost: process.env.VUE_APP_ECHO_HOST,
    key: process.env.VUE_APP_ECHO_KEY,
    forceTLS: true,
    disableStats: true,
    wssPort: process.env.VUE_APP_ECHO_PORT,
    enabledTransports: ["wss", "ws"],
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios.post(path, {
            socket_id: socketId,
            channel_name: channel.name
          })
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
        }
      };
    },
  });
}
