const apiUrl = process.env.VUE_APP_API_SERVER;

export const settings = {
  siteName: process.env.VUE_APP_API_SERVER,
};

class URL {
  constructor(base) {
    this.base = base;
  }

  path(path, args = null) {
    path = path.split(".");
    let obj = this,
      url = this.base;

    for (let i = 0; i < path.length && obj; i++) {
      if (obj.url) {
        url += "/" + obj.url;
      }

      obj = obj[path[i]];
    }
    if (obj) {
      url = url + "/" + (typeof obj === "string" ? obj : obj.url);
    }

    if (args) {
      for (let key in args) {
        url = url.replace(":" + key, args[key]);
      }
    }

    return url;
  }
}

export const api = Object.assign(new URL(apiUrl), {
  url: "",

  logout: "api/auth/logout",
  csrf: "api/auth/csrf-cookie",
  verify: "api/auth/verify",
  register: "api/auth/registration/:referral",
  login: "api/auth/login",
  forgotPassword: "api/auth/forgot-password",
  resetPassword: "api/auth/reset-password/:token",
  verifyResetPassword: "api/auth/reset-password/:token",

  password: {
    url: "password",
    forgot: "email",
    reset: "reset",
  },
  broadcasting: {
    auth: "api/broadcasting/auth"
  },
  user: {
    index: "api/v1/user",
    search: "api/v1/user/search",
    updateProfile: "api/v1/user/info",
    updateSettings: "api/v1/user/settings",
    updateNotifications: "api/v1/user/notifications",
    changePassword: "api/v1/user/change-password",
    remove: "api/v1/user/delete",
    setDefaultContract: "api/user/default-contract",
    getDefaultContract: "api/user/default-contract",
  },
  userBroadcast: {
    index: "api/v1/user/broadcasts",
    update: "api/v1/user/broadcasts",
  },
  userHistory: {
    index: "api/v1/user/histories",
  },
  userSettingsNotificationCounterparty: {
    index: "api/v1/user/setting/notification/counterparty",
    update: "api/v1/user/setting/notification/counterparty",
  },
  userSettingsNotificationContract: {
    index: "api/v1/user/setting/notification/contract",
    update: "api/v1/user/setting/notification/contract",
  },
  userSettingsNotification: {
    index: "api/v1/user/setting/notification",
    update: "api/v1/user/setting/notification",
  },
  userReferral: {
    index: "api/v1/user/referrals",
  },
  userReferralProgram: {
    index: "api/v1/user/referral-program",
  },
  userReferralCounterparty: {
    index: "api/v1/user/referrals/counterparties",
  },
  userReferralWithdrawal: {
    index: "api/v1/user/referral/withdrawals",
    store: "api/v1/user/referral/withdrawal",
  },
  other: {
    timezones: "api/v1/timezones",
    countries: "api/v1/countries",
  },
  counterparty: {
    store: "api/v1/counterparty",
    delete: "api/v1/counterparty/:counterpartyId",
    update: "api/v1/counterparty/:counterpartyId",
    index: "api/v1/counterparties",
    show: "api/v1/counterparty/:counterpartyId",
    userInvitation: "api/v1/counterparty/:counterpartyId/user-invitation",
  },
  counterpartyInvitation: {
    store: "api/v1/counterparty/:counterpartyId/user-invitation",
    remove: "api/v1/counterparty/:counterpartyId/user-invitation/:userId",
    update: "api/v1/counterparty/:counterpartyId/user-invitation/:userId",
  },
  invitationCounterparty: {
    index: "api/v1/counterparty/:userId/user-invitation",
    confirm:
      "api/v1/counterparty/:counterpartyId/user-invitation/:userId/confirm",
    unConfirm:
      "api/v1/counterparty/:counterpartyId/user-invitation/:userId/un-confirm",
  },
  counterpartyHistory: {
    index: "api/v1/counterparty/histories",
  },



  contractInvitation: {
    store: "api/v1/contract/:contractId/user-invitation",
    remove: "api/v1/contract/:contractId/user-invitation/:userId",
    update: "api/v1/contract/:contractId/user-invitation/:userId",
  },

  invitationContract: {
    index: "api/v1/contract/:userId/user-invitation",
    confirm:
      "api/v1/contract/:contractId/user-invitation/:userId/confirm",
    unConfirm:
      "api/v1/contract/:contractId/user-invitation/:userId/un-confirm",
  },



  contract: {
    store: "api/v1/contract",
    update: "api/v1/contract/:contractId",
    delete: "api/v1/contract/:contractId",
    index: "api/v1/contract",
    userInvitation: "api/v1/contract/:contractId/user-invitation",
    turnOff: "api/v1/contract/:contractId/turn-off",
    turnOn: "api/v1/contract/:contractId/turn-on",
  },
  contractUser: {
    store: "api/v1/contract/:contractId/user",
    delete: "api/v1/contract/:contractId/user/:userId",
    update: "api/v1/contract/:contractId/user/:userId",
  },
  contractCustomer: {
    index: "api/v1/contract/:contractId/customers",
    show: "api/v1/contract/:contractId/customers/:customerId",
    store: "api/v1/contract/:contractId/customers",
    delete: "api/v1/contract/:contractId/customers/:customerId",
    update: "api/v1/contract/:contractId/customers/:customerId",
    turnOff: "api/v1/contract/:contractId/customers/:customerId/turn-off",
    turnOn: "api/v1/contract/:contractId/customers/:customerId/turn-on",
  },
  contractCustomerDatabase: {
    index: "api/v1/contract/:contractId/customers-databases",
    show: "api/v1/contract/:contractId/customers-databases/:customerDatabaseId",
    store: "api/v1/contract/:contractId/customers-databases",
    delete:
      "api/v1/contract/:contractId/customers-databases/:customerDatabaseId",
    update:
      "api/v1/contract/:contractId/customers-databases/:customerDatabaseId",
    turnOff:
      "api/v1/contract/:contractId/customers-databases/:customerDatabaseId/turn-off",
    turnOn:
      "api/v1/contract/:contractId/customers-databases/:customerDatabaseId/turn-on",
  },
  contractStorage: {
    index: "api/v1/contract/:contractId/storages",
    store: "api/v1/contract/:contractId/storages",
    remove: "api/v1/contract/:contractId/storages/:storageId",
    show: "api/v1/contract/:contractId/storages/:storageId",
    checkRemove: "api/storages/check-destroy",
    update: "api/v1/contract/:contractId/storages/:storageId",
    turnOff: "api/v1/contract/:contractId/storages/:storageId/turn-off",
    turnOn: "api/v1/contract/:contractId/storages/:storageId/turn-on",
  },
  contractStorageManager: {
    index: "api/v1/contract/:contractId/storage-manager",
    show: "api/v1/contract/:contractId/storage-manager/show",
  },
  contractDatabase: {
    index: "api/v1/contract/:contractId/databases",
    store: "api/v1/contract/:contractId/databases",
    remove: "api/v1/contract/:contractId/databases/:databaseId",
    show: "api/v1/contract/:contractId/databases/:databaseId",
    checkRemove: "api/storage/check-destroy",
    update: "api/v1/contract/:contractId/databases/:databaseId",
    turnOff: "api/v1/contract/:contractId/databases/:databaseId/turn-off",
    turnOn: "api/v1/contract/:contractId/databases/:databaseId/turn-on",
  },
  contractTransaction: {
    index: "api/v1/contract/:contractId/transactions",
  },
  contractCertificate: {
    index: "api/v1/contract/:contractId/certificates",
    store: "api/v1/contract/:contractId/certificates",
    show: "api/v1/contract/:contractId/certificates/:certificateId",
    update: "api/v1/contract/:contractId/certificates/:certificateId",
    remove: "api/v1/contract/:contractId/certificates/:certificateId",
    turnOff: "api/v1/contract/:contractId/certificates/:certificateId/turn-off",
    turnOn: "api/v1/contract/:contractId/certificates/:certificateId/turn-on"
  },
  counterpartyUser: {
    store: "api/v1/counterparty/:counterpartyId/user",
    update: "api/v1/counterparty/:counterpartyId/user/:userId",
    delete: "api/v1/counterparty/:counterpartyId/user/:userId",
  },
  contractBackupDatabase: {
    store: "api/v1/contract/:contractId/backup/database",
    index: "api/v1/contract/:contractId/backup/database",
    show: "api/v1/contract/:contractId/backup/database/:backupDatabaseId",
    update: "api/v1/contract/:contractId/backup/database/:backupDatabaseId",
    remove: "api/v1/contract/:contractId/backup/database/:backupDatabaseId",
    turnOff:
      "api/v1/contract/:contractId/backup/database/:backupDatabaseId/turn-off",
    turnOn:
      "api/v1/contract/:contractId/backup/database/:backupDatabaseId/turn-on",
  },
  contractBackupStorage: {
    store: "api/v1/contract/:contractId/backup/storage",
    index: "api/v1/contract/:contractId/backup/storage",
    show: "api/v1/contract/:contractId/backup/storage/:backupStorageId",
    update: "api/v1/contract/:contractId/backup/storage/:backupStorageId",
    remove: "api/v1/contract/:contractId/backup/storage/:backupStorageId",
    turnOff:
      "api/v1/contract/:contractId/backup/storage/:backupStorageId/turn-off",
    turnOn:
      "api/v1/contract/:contractId/backup/storage/:backupStorageId/turn-on",
  },
  contractHistory: {
    index: "api/v1/contract/:contractId/histories",
  },
  site: {
    store: "api/v1/contract/:contractId/sites",
    index: "api/v1/contract/:contractId/sites",
    show: "api/v1/contract/:contractId/sites/:siteId",
    update: "api/v1/contract/:contractId/sites/:siteId",
    remove: "api/v1/contract/:contractId/sites/:siteId",
    updateStatus: "api/v1/contract/:contractId/sites/update-status",
    turnOff: "api/v1/contract/:contractId/sites/:siteId/turn-off",
    turnOn: "api/v1/contract/:contractId/sites/:siteId/turn-on",
  },
  contractDomain: {
    index: "api/v1/contract/:contractId/domains",
    store: "api/v1/contract/:contractId/domains",
    show: "api/v1/contract/:contractId/domains/:domainId",
    // update: "api/v1/contract/:contractId/domains/:domainId",
    // remove: "api/v1/contract/:contractId/domains/:domainId",
    // show: "api/v1/contract/:contractId/domains/:domainId",
    // turnOff: "api/v1/contract/:contractId/domains/:domainId/turn-off",
    // turnOn: "api/v1/contract/:contractId/domains/:domainId/turn-on",
    // domainTypes: "api/v1/domain-types",
    // domainZonesSelect: "api/v1/domain-zones-for-select",
    // removeRecord:
    //   "api/v1/contract/:contractId/domains/:domainId/settings/:settingId",
  },
  contractDomainRecord: {
    store: "api/v1/contract/:contractId/domain/:domainId/records",
    index: "api/v1/contract/:contractId/domain/:domainId/records",
  },
  cron: {
    index: "api/v1/contract/:contractId/cron-tasks",
    create: "api/v1/contract/:contractId/cron-tasks",
    show: "api/v1/contract/:contractId/cron-tasks/:cronId",
    update: "api/v1/contract/:contractId/cron-tasks/:cronId",
    remove: "api/v1/contract/:contractId/cron-tasks/:cronId",
    turnOff: "api/v1/contract/:contractId/cron-tasks/:cronId/turn-off",
    turnOn: "api/v1/contract/:contractId/cron-tasks/:cronId/turn-on",
    bellOff: "api/v1/contract/:contractId/cron-tasks/:cronId/bell-off",
    bellOn: "api/v1/contract/:contractId/cron-tasks/:cronId/bell-on",
  },
  payment: {
    index: "api/contract/:contractId/payment",
    store: "api/contract/:contractId/payment",
    paid: "api/contract/:contractId/payment/:paymentId",
    test: "api/contract/test",
  },
  contractSpendingChart: {
    show: "api/contract/:contractId/spending-chart/:typeId/:stepTypeId",
  },
  contractBalance: {
    index: "api/v1/contract/:contractId/balance",
  },
  contractBalanceReplenishmentHistory: {
    index: "api/v1/contract/:contractId/balance/replenishment",
  },
  contractSetting: {
    show: "api/v1/contract/:contractId/settings",
    update: "api/v1/contract/:contractId/settings",
  },
  invoice: {
    pdf: "api/v1/contract/:contractId/invoice/:id/pdf"
  },
  contractProcessorPhpCustom: {
    index: "api/v1/contract/:contractId/processors-php",
    show: "api/v1/contract/:contractId/processors-php/:id",
    store: "api/v1/contract/:contractId/processors-php",
    update: "api/v1/contract/:contractId/processors-php/:id",
    delete: "api/v1/contract/:contractId/processors-php/:id",
  },

  contractConfigPhpIni: {
    index: "api/v1/config/contract/php-ini",
    show: "api/v1/config/contract/php-ini/:typeId",
  },
  contractConfigPhpIni: {
    index: "api/v1/config/contract/php-ini",
    show: "api/v1/config/contract/php-ini/:typeId",
  },

  //TODO новый подход, поместить внутрь contract ключа.
  contractDashboardContractMap: {
    index: "api/contract/:contractId/backup/contract-map",
  },
  contractMetric: {
    grafana_index: "api/v1/contract/:contractId/metric/grafana",
  },
  backup: {
    databasePlan: {
      index: "api/v1/contract/:contractId/backup/database-plan",
      store: "api/v1/contract/:contractId/backup/database-plan",
      show: "api/v1/contract/:contractId/backup/database-plan/:databasePlanId",
      update:
        "api/v1/contract/:contractId/backup/database-plan/:databasePlanId",
      turnOff:
        "api/v1/contract/:contractId/backup/database-plan/:databasePlanId/turn-off",
      turnOn:
        "api/v1/contract/:contractId/backup/database-plan/:databasePlanId/turn-on",
    },
    storagePlan: {
      index: "api/v1/contract/:contractId/backup/storage-plan",
      store: "api/v1/contract/:contractId/backup/storage-plan",
      show: "api/v1/contract/:contractId/backup/storage-plan/:storagePlanId",
      update: "api/v1/contract/:contractId/backup/storage-plan/:storagePlanId",
      turnOff:
        "api/v1/contract/:contractId/backup/storage-plan/:storagePlanId/turn-off",
      turnOn:
        "api/v1/contract/:contractId/backup/storage-plan/:storagePlanId/turn-on",
    },
  },
});
