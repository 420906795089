import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const domainsRoutes = {
  // path: "/domains",
  // component: layout,
  // name: "domains",
  // children: [
  //   {
  //     path: "",
  //     component: () => import("~/views/Domains/Index"),
  //     name: "domains.ind",
  //     meta: {
  //       middleware: auth,
  //     },
  //   },
  //   {
  //     path: "update",
  //     component: () => import("~/views/Domains/Update"),
  //     name: "domains.update",
  //     meta: {
  //       middleware: auth,
  //     },
  //   },
  //   {
  //     path: ":domainId/records",
  //     component: () => import("~/views/Domains/Records"),
  //     name: "records.index",
  //     meta: {
  //       middleware: auth,
  //     },
  //   },
  // ],
};

export default domainsRoutes;
