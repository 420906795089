import layout from "~/layout/App";
import auth from "../middleware/auth";

const balanceRoutes = {
  path: "/balance",
  component: layout,
  name: "balance",
  children: [
    {
      path: "",
      component: () => import("~/views/Balance/Index"),
      name: "balance.index",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default balanceRoutes;
