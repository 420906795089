<template>
  <div class="base-mobile-options-wrapper">
    <div class="base-mobile-options" @click="$emit('close')"></div>
    <div class="options-wrapper">
      <slot name="options" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/styles/design/mainIncludes";
.base-mobile-options {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background: rgba(141, 141, 156, 0.4);
  backdrop-filter: blur(30px);

  &-wrapper {
    position: relative;
  }
}

.options-wrapper {
  position: relative;
  z-index: 3;
  background: $white;
  border-radius: 8px;
  margin-top: 12px;
}
</style>
