import axios from "axios";
import { api } from "~/router/apiConfig";
import contract from "~/views/Invitation/Contract.vue";

export function list(userId) {
  return axios.get(
    api.path("invitationContract.index", { userId: userId })
  );
}

export function confirmInvitation(contractId, userId) {
  return axios.put(
    api.path("invitationContract.confirm", {
      contractId: contractId,
      userId: userId,
    })
  );
}

export function unConfirmInvitation(contractId, userId) {
  return axios.put(
    api.path("invitationContract.unConfirm", {
      contractId: contractId,
      userId: userId,
    })
  );
}

