import axios from "axios";
import { api } from "~/router/apiConfig";

export function list() {
  return axios.get(api.path("contractConfigPhpIni.index"));
}

export function show(typeId) {
  return axios.get(api.path("contractConfigPhpIni.show", { typeId: typeId }));
}
