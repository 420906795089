import { isEmpty } from "../utils.js";
const isValidDomain = require("is-valid-domain");

const domainValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  if (isValidDomain(value)) {
    return true;
  } else {
    return "validate.invalid_domain";
  }
};

export { domainValidator };
