import { list as getList } from "~/api/domainZone";
import * as types from "../mutation-types";


export const state = {
  list: null,
  isLoading: false,
};


export const mutations = {
  [types.SET_IS_LOADING_DOMAIN_ZONES](state, status) {
    state.isLoading = status;
  },
  [types.SET_DOMAIN_ZONES](state, data) {
    state.list = data.data;
  },
};

export const actions = {
  fetchDomainZones({ commit }) {
    commit(types.SET_IS_LOADING_DOMAIN_ZONES, true);
    getList().then((response) => {
      let { data } = response;
      commit(types.SET_DOMAIN_ZONES, data);
      commit(types.SET_IS_LOADING_DOMAIN_ZONES, false);
    });
  },
}

export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};