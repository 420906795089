import "~/assets/styles/index.scss";
import "vue-tel-input/dist/vue-tel-input.css";
import "floating-vue/dist/style.css";

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Toaster from "@incuca/vue3-toaster";
import i18n from "~/plugins/i18n";
import { vMaska } from "maska";

import vClickOutside from "click-outside-vue3";

import { setupEcho } from "./plugins/echo";

import "~/plugins/index";
import "./plugins/customValidations";

const app = createApp(App).directive("maska", vMaska);

import BaseButton from "~/components/BaseUI/BaseButton.vue";
import BaseSwitch from "~/components/BaseUI/BaseSwitch.vue";
import BaseInput from "~/components/BaseUI/BaseInput.vue";
import BaseCard from "~/components/BaseUI/BaseCard.vue";
import BaseSelect from "~/components/BaseUI/BaseSelect.vue";
import BaseTextarea from "~/components/BaseUI/BaseTextarea.vue";
import BaseFormItem from "~/components/BaseUI/BaseFormItem.vue";
import BaseItemLoader from "~/components/BaseUI/BaseItemLoader.vue";
import BaseListItemCard from "~/components/BaseUI/BaseListItemCard.vue";
import BaseSubItemCard from "~/components/BaseUI/BaseSubItemCard.vue";
import BaseCopier from "~/components/BaseUI/BaseCopier.vue";
import BaseIcon from "~/components/BaseUI/BaseIcon.vue";
import BaseEllipsis from "~/components/BaseUI/BaseEllipsis.vue";
import BaseTabs from "~/components/BaseUI/BaseTabs.vue";
import BaseCheckbox from "~/components/BaseUI/BaseCheckbox.vue";
import BaseToggleButton from "~/components/BaseUI/BaseToggleButton.vue";
import BaseMobileOptionsWrapper from "~/components/BaseUI/Mobile/BaseMobileOptionsWrapper.vue";
import BaseMobileOption from "~/components/BaseUI/Mobile/BaseMobileOption.vue";
import BaseMobileOptionFooter from "~/components/BaseUI/Mobile/BaseMobileOptionFooter.vue";
import InlineSvg from "vue-inline-svg";

import ValidationInputMessage from "~/components/BaseUI/_validationInputMessage.vue";
import BaseModal from "~/components/Modal/Base.vue";

import PromoPlan from "~/components/Promo/PromoPlan.vue";
import QuestionAlert from "~/components/Alert/QuestionAlert.vue";
import ContainerLoader from "~/components/BaseUI/_containerLoader.vue";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";

import stockInit from "highcharts/modules/stock";
import { VTooltip } from "floating-vue";

//Directives
import inputPattern from "./directives/inputPattern";
import vlink from "./directives/vlink";
import vSafeHtml from "./directives/vSafeHtml";

setupEcho();

app.directive("inputPattern", inputPattern);
app.directive("tooltip", VTooltip);
app.directive("link", vlink);
app.directive("safe-html", vSafeHtml);

//TODO вынести в отдельный файл глобальную регистрацию компонентов
app.component("BaseButton", BaseButton);
app.component("BaseEllipsis", BaseEllipsis);
app.component("BaseSwitch", BaseSwitch);
app.component("InlineSvg", InlineSvg);
app.component("BaseInput", BaseInput);
app.component("BaseCard", BaseCard);
app.component("BaseSelect", BaseSelect);
app.component("BaseTextarea", BaseTextarea);
app.component("BaseFormItem", BaseFormItem);
app.component("BaseItemLoader", BaseItemLoader);
app.component("BaseCopier", BaseCopier);
app.component("BaseIcon", BaseIcon);
app.component("BaseTabs", BaseTabs);
app.component("BaseCheckbox", BaseCheckbox);
app.component("BaseMobileOptionsWrapper", BaseMobileOptionsWrapper);
app.component("BaseMobileOption", BaseMobileOption);
app.component("BaseMobileOptionFooter", BaseMobileOptionFooter);
app.component("BaseDatePicker", BaseDatePicker);
app.component("BaseTelInput", BaseTelInput);
app.component("BaseListItemCard", BaseListItemCard);
app.component("BaseSubItemCard", BaseSubItemCard);
app.component("BaseToggleButton", BaseToggleButton);

app.component("ValidationInputMessage", ValidationInputMessage);
app.component("BaseModal", BaseModal);
app.component("PromoPlan", PromoPlan);
app.component("QuestionAlert", QuestionAlert);
app.component("ContainerLoader", ContainerLoader);

stockInit(Highcharts);
highchartsMore(Highcharts);

import BaseDatePicker from "~/components/BaseUI/BaseDatePicker";
import BaseTelInput from "~/components/BaseUI/BaseTelInput";

app
  .use(vClickOutside)
  .use(HighchartsVue)
  .use(store)
  .use(i18n)
  .use(Toaster)
  .use(router)
  .mount("#app");