<template>
  <BaseListItemCard size="sm" class="flex items-center mb-16">
    <div class="flex w-full items-center space-x-4">
      <div v-for="(loaderWidth, index) in loaderDistribution" :key="index" class="flex" :class="loaderWidth">
        <div class="loading-item w-full"></div>
      </div>
    </div>
  </BaseListItemCard>
</template>


<script>
export default {
  props: {
    loaderDistribution: {
      type: Array,
      // Example values: ['w-1/4', 'w-1/4', 'w-1/4', 'w-1/4'] for equal distribution of 4 blocks
      default: () => ["w-full", "w-full", "w-full"] // by default, 3 blocks are created to span the full width

    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";

.loading-article {
  display: flex;
  justify-content: space-between;
}

.cabinet-loader_item {
  width: 300px;
}

.cabinet-loader_item-two {
  width: 230px;
}

.cabinet-loader_item-three {
  width: 145px;
}

@include maxWidth1240 {
  .cabinet-loader_item {
    width: 210px;
  }
  .cabinet-loader_item_views-two {
    width: 160px;
  }
}

@include maxWidth992 {
  .cabinet-loader_item-two,
  .cabinet-loader_item-three {
    display: none;
  }
  .cabinet-loader_item {
    width: 100%;
  }
}
</style>