// import { list as getList } from "~/api/contractTransaction";
import * as types from "../mutation-types";
import store from "../../store";

/**
 * Initial state
 */
export const state = {
  list: null,
  isLoading: false,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_TRANSACTIONS](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_TRANSACTIONS](state, contractTransactions) {
    state.list = contractTransactions.data;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchContractTransactions({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_TRANSACTIONS, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      //TODO исправить данный роут и вернуть
      // getList(activeContractId).then((response) => {
      //   let { data } = response;
      //   commit(types.SET_CONTRACT_TRANSACTIONS, data);
      //   commit(types.SET_IS_LOADING_CONTRACT_TRANSACTIONS, false);
      // });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
