import layout from "~/layout/App";
import auth from "../middleware/auth";

const contractRoutes = {
  path: "/contracts",
  component: layout,
  name: "contracts",
  children: [
    {
      path: "",
      component: () => import("~/views/Contract/Index"),
      name: "contract.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: ":contractId/user",
      component: () => import("~/views/Contract/User"),
      name: "contract.user",
      meta: {
        middleware: auth,
      },
    },
    {
      path: ":contractId/settings",
      component: () => import("~/views/Contract/Setting"),
      name: "contracts.setting",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default contractRoutes;
