import { success as successStatus } from "~/router/apiResponseStatus";
import { mapGetters } from "vuex";
import { ACTIVE, DECLARED, INACTIVE, REMOVING, TURNING_OFF, TURNING_ON, UPDATING } from "~/constants/entityStatus";

const entityMixin = {
  methods: {
    isSuccessStatus(data) {
      return data?.status === successStatus;
    },
    isDeclaredEntityStatus(statusId) {
      return this.entityDeclaredStatusId === statusId;
    },
    isActivatedEntityStatus(statusId) {
      return statusId === ACTIVE;
    },
    isStableStatus(statusId) {
      return this.isActivatedEntityStatus(statusId) || this.isInactivatedEntityStatus(statusId);
    },
      isInactivatedEntityStatus(statusId) {
      return INACTIVE === statusId;
    },
    isUpdatingEntityStatus(statusId) {
      return this.entityUpdatingStatusId === statusId;
    },
    isRemovingEntityStatus(statusId) {
      return this.entityRemovingStatusId === statusId;
    },
    showText(statusId, entityName) {
      switch (statusId) {
        case DECLARED:
          return this.$t("process_item.entity_creating", { entity: entityName });
        case UPDATING:
          return this.$t("process_item.entity_updating", { entity: entityName });
        case REMOVING:
          return this.$t("process_item.entity_removing", { entity: entityName });
        case TURNING_ON:
          return this.$t("process_item.entity_turning_on", { entity: entityName });
        case TURNING_OFF:
          return this.$t("process_item.entity_turning_off", { entity: entityName });
      }
    },

  },
  computed: {
    ...mapGetters({
      entityDeclaredStatusId: "dictionary/entityDeclaredStatusId",
      entityActivatedStatusId: "dictionary/entityActivatedStatusId",
      entityUpdatingStatusId: "dictionary/entityUpdatingStatusId",
      entityRemovingStatusId: "dictionary/entityRemovingStatusId",
      entityTurnsOnStatusId: "dictionary/entityTurnsOnStatusId",
      entityTurnsOffStatusId: "dictionary/entityTurnsOffStatusId",
      entityInactiveStatusId: "dictionary/entityInactiveStatusId",
    }),
  },
};

export default entityMixin;
