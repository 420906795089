import * as types from "../mutation-types";
import store from "../../store";
import { index } from "~/api/contractDashboardContractMap";

export const state = {
  data: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_DASHBOARD_CONTRACT_MAP](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_DASHBOARD_CONTRACT_MAP](state, data) {
    state.data = data;
  },
};

export const actions = {
  fetchContractDashboardContractMap({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_DASHBOARD_CONTRACT_MAP, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      index(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_DASHBOARD_CONTRACT_MAP, data);
        commit(types.SET_IS_LOADING_CONTRACT_DASHBOARD_CONTRACT_MAP, false);
      });
    }
  },
};

export const getters = {
  data: (state) => state.data,
  isLoading: (state) => state.isLoading,
};