import { list as getList } from "~/api/userReferralWithdrawals";
import * as types from "../mutation-types";


export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS](state, status) {
    state.isLoading = status;
  },
  [types.SET_USER_REFERRAL_WITHDRAWALS](state, data) {
    state.list = data;
  },
  [types.SET_USER_REFERRAL_WITHDRAWALS_META](state, meta) {
    console.log(meta);
    state.meta = meta;
  },
};

export const actions = {
  fetchUserReferralWithdrawals({ commit } = null) {
    commit(types.SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS, true);

    getList().then((response) => {
      let { data } = response;

      commit(types.SET_USER_REFERRAL_WITHDRAWALS, data.data);
      commit(types.SET_USER_REFERRAL_WITHDRAWALS_META, data.meta);
      commit(types.SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS, false);
    });
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
