<template>
  <section class="plan-section">
    <div class="mainContainer">
      <div class="plan_wrapp">
        <div class="plan_info">
          Активований тестовий період: <span>Залишилось 7 днів</span>
        </div>
        <router-link
          class="plan_link btn btn-success"
          :to="{ name: 'balance.index' }"
        >
          Детальніше
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PromoPlan",
};
</script>
