<template>
  <div :class="computedClass" role="alert" class="rounded-lg shadow-lg mx-4 my-3">
    <div class="flex justify-between items-center h-full">
      <InlineSvg :src="icon" :class="iconClass + ' flex-shrink-0'"></InlineSvg>
      <small class="text-base-small self-center">{{ timedAt }}</small>
    </div>
    <div class="flex relative">
      <div
        v-if="!isRead"
        class="absolute top-0 right-0 mt-2 mr-2 w-4 h-4 orange-5-bg rounded-full"
      ></div>
      <div class="mt-12">
        <p class="font-bold">{{ title }}</p>
        <p class="text-sm mt-3" v-safe-html="message"></p>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "ItemNotification",
  components: {
    InlineSvg,
  },
  props: {
    timedAt: {
      type: String,
      required: true,
    },
    typeId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isRead: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    alertDetails() {
      const alerts = {
        success: {
          class:
            "bg-teal-100 border-t-4 border-teal-500 text-teal-900 px-4 py-3",
          iconClass: "h-6 w-6 text-teal-500 mr-10 green-5-fill",
        },
        info: {
          class:
            "green-1-bg border-t-4 border-blue-500 text-green-900 px-4 py-3",
          iconClass: "h-6 w-6  mr-10",
        },
        error: {
          class:
            "bg-red-100 border-t-4 border-red-500 text-red-900 px-4 py-3",
          iconClass: "h-6 w-6 text-red-500 mr-10",
        },
      };
      return alerts[this.type] || alerts.info;
    },
    computedClass() {
      return this.alertDetails.class;
    },
    iconClass() {
      return this.alertDetails.iconClass;
    },
  },
};
</script>
