import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(request) {
  return axios.post(api.path("counterparty.store"), request);
}

export function update(request, counterpartyId) {
  return axios.put(
    api.path("counterparty.update", {
      counterpartyId: counterpartyId,
    }),
    request
  );
}

export function index() {
  return axios.get(api.path("counterparty.index"));
}

export function deleteCounterpartyUser(request, counterpartyId, userId) {
  return axios.delete(
    api.path("counterpartyUser.delete", {
      counterpartyId: counterpartyId,
      userId: userId,
    }),
    request
  );
}

export function deleteCounterparty(counterpartyId) {
  return axios.delete(
    api.path("counterparty.delete", {
      counterpartyId: counterpartyId,
    })
  );
}

// export function addCounterpartyUser(request, counterpartyId) {
//   return axios.post(
//     api.path("counterpartyUser.store", {
//       counterpartyId: counterpartyId,
//     }),
//     request
//   );
// }

export function updateCounterpartyUser(request, counterpartyId, userId) {
  return axios.put(
    api.path("counterpartyUser.update", { counterpartyId: counterpartyId, userId: userId }),
    request
  );
}

export function show(counterpartyId) {
  return axios.get(
    api.path("counterparty.show", { counterpartyId: counterpartyId })
  );
}

export function addInvitationUser(request, counterpartyId) {
  return axios.post(
    api.path("counterparty.userInvitation", { counterpartyId: counterpartyId }),
    request
  );
}

export function updateInvitationUser(
  request,
  counterpartyId,
  counterpartyInvitationId
) {
  return axios.put(
    api.path("counterpartyInvitation.js.update", {
      counterpartyId: counterpartyId,
      counterpartyInvitationId: counterpartyInvitationId,
    }),
    request
  );
}

export function deteInvitationUser(counterpartyId, counterpartyInvitationId) {
  return axios.delete(
    api.path("counterpartyInvitation.js.delete", {
      counterpartyId: counterpartyId,
      counterpartyInvitationId: counterpartyInvitationId,
    })
  );
}

export function userListInvitation(userId) {
  return axios.get(
    api.path("counterpartyInvitation.index", { userId: userId })
  );
}

export function unConfirmCounterpartyInvitation(counterpartyId, userId) {
  return axios.put(
    api.path("counterpartyInvitation.js.unConfirm", {
      counterpartyId: counterpartyId,
      userId: userId,
    })
  );
}
