const CONTRACT = 10;
const COUNTERPARTY = 20;
const USER = 30;

import { counterpartyConfig } from "~/constants/history/counterparty/counterparty";
import { contractConfig } from "~/constants/history/contract/contract";
import { userProfileConfig } from "~/constants/history/user/profile";

// export const DATABASE_CUSTOMER_IDS = [DATABASE_CUSTOMER_CREATE, DATABASE_CUSTOMER_UPDATE, DATABASE_CUSTOMER_TURN_ON,DATABASE_CUSTOMER_TURN_OFF,DATABASE_CUSTOMER_REMOVE ]

export const TYPES_LIST = {
  [COUNTERPARTY]: counterpartyConfig,
  [USER]: userProfileConfig,
  [CONTRACT]: contractConfig
};
