import axios from "axios";
import { api } from "~/router/apiConfig";

export function updateUserProfile(request) {
  return axios.put(api.path("user.updateProfile"), request);
}

export function updateUserNotifications(request) {
  return axios.put(api.path("user.updateNotifications"), request);
}

export function updateUserSettings(request) {
  return axios.put(api.path("user.updateSettings"), request);
}

export function changePassword(request) {
  return axios.put(api.path("user.changePassword"), request);
}

export function removeUser(request) {
  return axios.put(api.path("user.remove"), request);
}

export function searchUser(request) {
  return axios.get(api.path("user.search"), { params: request });
}

export function deleteUser(request) {
  return axios.post(api.path("user.remove"), request);
}

export function readBroadcast() {
  return axios.put(api.path("userBroadcast.index"));
}

export function showBroadcast() {
  return axios.get(api.path("userBroadcast.update"));
}

export function setDefaultContract(contractId) {
  return axios.post(api.path("user.setDefaultContract"), {
    contractId: contractId,
  });
}

export function getDefaultContract() {
  return axios.get(api.path("user.getDefaultContract"));
}
