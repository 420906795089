import {
  COUNTERPARTY,
  COUNTERPARTY_INVITATION,
  USER_INVITATION_COUNTERPARTY,
  CONTRACT,
  CONTRACT_INVITATION,
  CONTRACT_CUSTOMER_STORAGE,
  CONTRACT_STORAGE,
  CONTRACT_DATABASE,
  USER_INVITATION_CONTRACT, CONTRACT_CUSTOMER_DATABASE, CONTRACT_CERTIFICATE, CONTRACT_SITE
} from "~/constants/broadcast/modifiedData";

export const reloadData = {
  [COUNTERPARTY]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["counterparty.index"],
        fetchData: ["counterparty/fetchCounterparties"]
      }
    ]
  },
  [COUNTERPARTY_INVITATION]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["counterparty.index"],
        fetchData: ["counterparty/fetchCounterparties"]
      }
    ]
  },
  [USER_INVITATION_COUNTERPARTY]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["invitation.counterparties"],
        fetchData: [
          {
            action:
              "userInvitationCounterparty/fetchUserInvitationCounterparties",
            paramsKey: "userId"
          }
        ]
      }
    ]
  },
  [CONTRACT_STORAGE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.storage.index"],
        fetchData: ["contractStorage/fetchContractStorages"]
      }
    ]
  },
  [CONTRACT]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.index"],
        fetchData: ["contract/fetchContracts"]
      }
    ]
  },
  [CONTRACT_INVITATION]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.history.index"],
        fetchData: ["contractHistory/fetchContractHistories"]
      }
    ]
  },
  [USER_INVITATION_CONTRACT]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["invitation.contracts"],
        fetchData: ["userInvitationContract/fetchUserInvitationContract"]
      }
    ]
  },
  [CONTRACT_CUSTOMER_DATABASE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.customers.db.index"],
        fetchData: ["contractCustomerDatabase/fetchContractCustomersDatabase"]
      }
    ]
  },
  [CONTRACT_CUSTOMER_STORAGE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.customers.index"],
        fetchData: ["contractCustomer/fetchContractCustomers"]
      }
    ]
  },
  [CONTRACT_DATABASE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.database.index"],
        fetchData: [
          "contractDatabase/fetchContractDatabases"
        ]
      }
    ]
  },
  [CONTRACT_CERTIFICATE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.certificate.index"],
        fetchData: [
          "contractCertificate/fetchContractCertificates"
        ]
      }
    ]
  },
  [CONTRACT_SITE]: {
    alwaysFetch: ["user/fetchBroadcast"],
    conditionalFetch: [
      {
        routeName: ["contract.cabinet.site.index"],
        fetchData: [
          "contractSite/fetchContractSites"
        ]
      }
    ]
  },
};
