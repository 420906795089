import axios from "axios";
import { api } from "~/router/apiConfig";

export function list(contractId) {
  return axios.get(
    api.path("contractProcessorPhpCustom.index", { contractId: contractId })
  );
}

export function show(contractId, id) {
  return axios.get(
    api.path("contractProcessorPhpCustom.show", {
      contractId: contractId,
      id: id,
    })
  );
}

export function store(contractId, request) {
  return axios.post(
    api.path("contractProcessorPhpCustom.store", {
      contractId: contractId,
    }),
    request
  );
}

export function update(contractId, id, request) {
  return axios.put(
    api.path(
      "contractProcessorPhpCustom.update",
      {
        contractId: contractId,
        id: id,
      }),      request

  );
}
