import { list as getList } from "~/api/userReferralUser.js";
import * as types from "../mutation-types";

export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  // [types.SET_IS_LOADING_USER_REFERRAL_USERS](state, status) {
  //   state.isLoading = status;
  // },
  [types.SET_USER_REFERRAL_USERS](state, data) {
    state.list = data;
  },
  [types.SET_USER_REFERRAL_USERS_META](state, meta) {
    state.meta = meta;
  },
};

export const actions = {
  fetchUserReferralUsers({ commit } = null) {
    // commit(types.SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS, true);

    getList().then((response) => {
      let { data } = response;
      commit(types.SET_USER_REFERRAL_USERS, data.data);
      commit(types.SET_USER_REFERRAL_USERS_META, data.meta);
      // commit(types.SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS, false);
    });
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
