<template>
  <div class="flex justify-center items-center mb-36">
    <button
      @click="changePage(1)"
      :disabled="currentPage === 1"
      :style="{ 'cursor': currentPage === 1 ? 'not-allowed' : 'pointer', 'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '0.5rem 1rem', 'margin': '0 0.25rem' }"
    >
      {{$t('pagination.first')}}
    </button>
    <button
      v-for="page in pagesToShow"
      :key="page"
      @click="changePage(page)"
      :disabled="page === currentPage"
      :style="{ 'cursor': page === currentPage ? 'not-allowed' : 'pointer', 'background-color': currentPage === page ? '#38a169' : 'transparent', 'color': currentPage === page ? 'white' : 'black', 'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '0.5rem 1rem', 'margin': '0 0.25rem' }"
    >
      {{ page }}
    </button>
    <button
      @click="changePage(totalPages)"
      :disabled="currentPage === totalPages"
      :style="{ 'cursor': currentPage === totalPages ? 'not-allowed' : 'pointer', 'border': '1px solid #ccc', 'border-radius': '5px', 'padding': '0.5rem 1rem', 'margin': '0 0.25rem' }"
    >
      {{$t('pagination.last')}}
    </button>
  </div>
</template>


<script>
export default {
  name: "PaginationDefault",
  props: {
    total: Number,
    count: Number,
    perPage: Number,
    currentPage: Number,
    totalPages: Number,
    lastPage: Number,
    from: Number,
    to: Number,
  },
  computed: {
    pagesToShow() {
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(this.currentPage + 2, this.totalPages);

      let pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      // Always include the first and last page
      if (!pages.includes(1)) {
        pages.unshift(1);
      }
      if (!pages.includes(this.totalPages)) {
        pages.push(this.totalPages);
      }

      return pages;
    },
  },
  methods: {
    changePage(page) {
      if (this.currentPage === page) return;
      this.$emit("change", page);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
};
</script>


<style scoped>
.border {
  border: 1px solid #ccc;
}
.rounded {
  border-radius: 5px;
}
.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
</style>
