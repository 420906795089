<template>
  <div
    :class="[
      { 'validation-alert': isShowValidationIcon },
      { 'error-field': validation.hasErrors === true },
      {
        'success-field':
          validation.hasErrors === false && modelValue?.length > 0,
      },
      { 'base-textarea-wrapper': !withoutWrapper },
      textareaWrapperClass,
    ]"
  >
    <textarea
      :style="style"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      :placeholder="placeholder"
      :class="textareaClass"
      ref="baseTexatea"
      :required="required"
      :disabled="disabled"
      @change="changed()"
    >
    </textarea>
    <img
      v-if="icon"
      class="icon"
      :class="{
        'icon-right': this.iconRight,
        error: !this.valid,
      }"
      :src="`/image/icons/${icon}.svg`"
      alt="icon"
    />
  </div>
  <ValidationInputMessage
    :key="index"
    v-for="(error, index) in validation.errors"
    class="invalid-feedback show"
  >
    {{ $t(error) }}
  </ValidationInputMessage>
</template>

<script>
import { isMobile } from "~/mixins/isMobile";
import checkValidationMixin from "~/mixins/checkValidation";

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: "text",
    },
    style: {
      type: String,
      default: "",
    },
    placeholder: {
      type: [String, Number, Object, Boolean],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowValidationIcon: {
      type: Boolean,
      default: true,
    },
    withError: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modelValue: [String, Number, Object],
    valid: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    withoutWrapper: {
      type: Boolean,
      default: false,
    },
    borderTransparent: {
      type: Boolean,
      default: false,
    },
    maska: {
      type: String,
    },
  },
  methods: {
    changed: function () {
      this.makeValidation();
    },
    getValue: function () {
      return this.value ?? this.modelValue;
    },
  },
  mixins: [isMobile, checkValidationMixin],
  computed: {
    textareaClass() {
      return {
        "base-textarea": true,
        [this.isMobile ? "size-md" : `size-${this.size}`]: true,
        disabled: this.disabled,
        error: !this.valid,
      };
    },
    textareaWrapperClass() {
      return {
        [this.isMobile ? "size-md" : `size-${this.size}`]: true,
        "state-disabled": this.disabled,
        "state-error": !this.valid,
        "full-width": this.fullWidth,
        "border-transparent": this.borderTransparent,
      };
    },
  },
  watch: {
    modelValue: function () {
      //TODO костыль, что бы при превой загрузки данный в компонент, обработала их валидация.
      // А дальше только при изменениях.
      if (!this.validation.isFirstRunWatch) {
        this.makeValidation();
        this.validation.isFirstRunWatch = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";

.error-field.validation-alert {
  border-color: $red-3;

  textarea {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("/public/image/icons/icon-info-danger.svg");
    background-repeat: no-repeat;
    background-position: right calc(1.37em + 0.1875rem) top
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.success-field.validation-alert {
  border-color: $green-3 !important;

  textarea {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("/public/image/icons/invitation-success-icon.svg");
    background-repeat: no-repeat;
    background-position: right calc(1.37em + 0.1875rem) top
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.base-textarea-wrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  background: #fff;
  border: 1px solid $grey-2;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  column-gap: 2px;
  flex-direction: row;
  padding: 0;
  &.valid-danger {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
  }

  &.error-field {
    border-color: $red-3;
  }

  &:focus-within {
    border: 1px solid $grey-5;
  }
  &.state-disabled {
    border: 1px solid $grey-3;
  }
  &.state-error,
  &.state-error:focus-within {
    border: 1px solid $red-3;
  }
  &.full-width {
    width: 100%;
  }
  &.border-transparent {
    border-color: transparent;
  }
  &.size-lg {
    column-gap: 6px;
  }
  img.icon {
    order: -1;
    width: 20px;
    height: 20px;
    margin: 0 0 0 12px;
    &-right {
      margin: 0 12px 0 0;
      order: 0;
    }
    &.error {
      filter: brightness(80%) sepia(100%) hue-rotate(-50deg) saturate(8)
        contrast(1);
    }
    @include maxWidth768 {
      width: 15px;
      height: 15px;
    }
  }

  .base-textarea {
    width: 100%;
    outline: none;
    background: transparent;
    color: $black;
    border-radius: inherit;
    border: none;
    box-sizing: border-box;
    height: auto;
    padding: 4px 2px;
    resize: none;
    -webkit-appearance: none;
    &.size-sm {
      font-size: 14px;
      height: 80px;
    }
    &.size-md {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      height: 120px;
    }
    &.size-lg {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 10px;
      height: 160px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.error {
      color: $red-3;
    }
    &::placeholder {
      color: $black-2;
    }
  }
}
</style>
