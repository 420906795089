import { customerDatabase } from "~/constants/history/contract/customerDatabase";
import { database } from "~/constants/history/contract/database";
import { storage } from "~/constants/history/contract/storage";
import { customerStorage } from "~/constants/history/contract/customerStorage";
import { certificate } from "~/constants/history/contract/certificate";
import { site } from "~/constants/history/contract/site";

const INVITATION_CREATED = "s2015";
const INVITATION_UPDATED = "s2016";
const INVITATION_REMOVED = "s2019";
const USER_INVITATION_CONFIRMED = "s2014";
const USER_INVITATION_UN_CONFIRMED = "s2013";
const CONTRACT_USER_UPDATED = "s2036";
const CONTRACT_USER_REMOVED = "s2039";



export const contractConfig = {
  ...customerDatabase,
  ...database,
  ...storage,
  ...customerStorage,
  ...certificate,
  ...site,
  [INVITATION_CREATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.contract.invitation",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.contract.invitation",
    langKeyNotificationTitle: "notification.contract.title",
    langTextParams: (data, $t) => ({
      contractorName: data.name,
      targetUser: data.initiatorName,
      link: data.link,
      action: $t("notification.action.sent"),
      user: data.userName,
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [INVITATION_UPDATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.contract.invitation",
    langKeyNotificationAction: "notification.contract.invitation",
    langKeyNotificationTitle: "notification.contract.title",
    route: "contract.index",
    langTextParams: (data, $t) => ({
      contractorName: data.name,
      targetUser: data.initiatorName,
      link: data.link,
      action: $t("notification.action.update"),
      user: data.userName,
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [INVITATION_REMOVED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.contract.invitation",
    langKeyNotificationAction: "notification.contract.invitation",
    route: "contract.index",
    langKeyNotificationTitle: "notification.contract.title",
    langTextParams: (data, $t) => ({
      targetUser: data.initiatorName,
      contractorName: data.name,
      link: data.link,
      action: $t("notification.action.delete"),
    }),
    langTitleParams: (data) => ({
      name: data.name,
    }),
    hash: (id) => `#${id}`,
  },
  [USER_INVITATION_CONFIRMED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.contract.user_invitation",
    langKeyNotificationAction: "notification.contract.user_invitation",
    langKeyNotificationTitle: "notification.contract.title",
    route: "contract.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      link: data.link,
      name: data.name,
      link: data.link,
      action: $t("action.accepte"),
    }),
    hash: (id) => `#${id}`,
  },
  [USER_INVITATION_UN_CONFIRMED]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.contract.user_invitation",
    langKeyNotificationAction: "notification.contract.user_invitation",
    langKeyNotificationTitle: "notification.contract.title",
    route: "contract.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      user: data.initiatorName,
      link: data.link,
      contract: data.name,
      action: $t("action.decline"),
    }),
    hash: (id) => `#${id}`,
  },
  [CONTRACT_USER_UPDATED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.contract_user.action",
    langKeyNotificationAction: "notification.contract_user.action",
    langKeyNotificationTitle: "notification.contract.title",
    route: "contract.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      executor: data.initiatorName,
      user: data.subjectName,
      contract: data.name,
      link: data.link,
      action: $t("notification.action.update"),
    }),
    hash: (id) => `#${id}`,
  },
  [CONTRACT_USER_REMOVED]: {
    icon: "/img/add-user.svg",
    langKeyHistory: "notification.counterparty_user.action",
    langKeyNotificationAction: "notification.counterparty_user.action",
    langKeyNotificationTitle: "notification.contract.title",
    route: "contract.index",
    langTitleParams: (data) => ({
      name: data.name,
    }),
    langTextParams: (data, $t) => ({
      executor: data.initiatorName,
      user: data.subjectName,
      contract: data.name,
      action: $t("action.remove"),
    }),
    hash: (id) => `#${id}`,
  },
};
