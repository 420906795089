import { index as getList } from "~/api/counterparty";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
  list: null,
  isLoad: true,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_COUNTERPARTIES](state, data) {
    state.list = data;
  },
  [types.SET_IS_LOADING_SET_COUNTERPARTIES](state, status) {
    state.isLoad = status;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchCounterparties({ commit }) {
    commit(types.SET_IS_LOADING_SET_COUNTERPARTIES, true);

    getList().then((response) => {
        let { data } = response
        commit(types.SET_COUNTERPARTIES, data.data);
        commit(types.SET_IS_LOADING_SET_COUNTERPARTIES, false);
    });
    return false;
    // } catch (e) {
    //   // commit(types.FETCH_USER_FAILURE);
    // }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoad: (state) => state.isLoad,
};
