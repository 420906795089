import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("backup.databasePlan.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, databasePlanId, request) {
  return axios.put(
    api.path("backup.databasePlan.update", {
      contractId: contractId,
      databasePlanId: databasePlanId,
    }),
    request
  );
}

export function list(contractId) {
  return axios.get(
    api.path("backup.databasePlan.index", { contractId: contractId })
  );
}

export function show(contractId, databasePlanId) {
  return axios.get(
    api.path("backup.databasePlan.show", {
      contractId: contractId,
      databasePlanId: databasePlanId,
    })
  );
}

export function remove(contractId, databasePlanId) {
  return axios.delete(
    api.path("backup.databasePlan.remove", {
      contractId: contractId,
      databasePlanId: databasePlanId,
    })
  );
}

export function turnOn(contractId, databasePlanId) {
  return axios.put(
    api.path("backup.databasePlan.turnOn", {
      contractId: contractId,
      databasePlanId: databasePlanId,
    })
  );
}

export function turnOff(contractId, databasePlanId) {
  return axios.put(
    api.path("backup.databasePlan.turnOff", {
      contractId: contractId,
      databasePlanId: databasePlanId,
    })
  );
}
