import router from "~/router";

export default {
  mounted(el, binding) {
    el.innerHTML = binding.value;
    el.querySelectorAll('.custom-link').forEach(link => {
      link.addEventListener('click', (e) => {
        console.log(4324234);
        e.preventDefault();
        const path = link.getAttribute('data-link');
        if (path) {
          router.push(path);
        }
      });
    });
  }
};
