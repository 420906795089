import { list as getList } from "~/api/counterpartyHistory";
import * as types from "../mutation-types";

export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_COUNTERPARTY_HISTORY](state, status) {
    state.isLoading = status;
  },
  [types.SET_COUNTERPARTY_HISTORY](state, counterpartyHistory) {
    state.list = counterpartyHistory;
  },
  [types.SET_COUNTERPARTY_HISTORY_META](state, meta) {
    state.meta = meta;
  },
};

export const actions = {
  fetchCounterpartyHistories({ commit }, params) {
    commit(types.SET_IS_LOADING_COUNTERPARTY_HISTORY, true);

      getList(params).then((response) => {
        let { data } = response;
        
        commit(types.SET_COUNTERPARTY_HISTORY, data.data);
        commit(types.SET_COUNTERPARTY_HISTORY_META, data.meta);
        commit(types.SET_IS_LOADING_COUNTERPARTY_HISTORY, false);
      });
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
