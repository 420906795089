<template>
  <div class="base-card" :size="size" :loader="loader" :class="cardClasses">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      return {
        "full-width": this.fullWidth,
        loader: this.loader,
        "card-sm": this.size === "sm",
        "card-md": this.size === "md",
        "card-lg": this.size === "lg",
      };
    },
  },
};
</script>
