import { isNullOrUndefined, isEmptyArray } from "../utils.js";

const requiredValidator = (value) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
    return "validate.the_field_must_be_required";
  }

  return !!String(value).trim().length || "validate.the_field_must_be_required";
};

export { requiredValidator };
