<template>
  <div class="container-loader">
    <div class="animation">
      <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2461 20.6182L23.5236 27.3091L34.8012 20.4728L23.5236 13.7819L12.2461 20.6182Z" fill="#009440" class="svg-elem-1"></path>
        <path d="M57.2147 20.6182L57.3557 34L46.0781 40.6909V27.3091L57.2147 20.6182Z" fill="#009440" class="svg-elem-2"></path>
        <path d="M34.8008 47.3818V60.7636L45.9373 54.0727L46.0783 40.6909L34.8008 47.3818Z" fill="#009440" class="svg-elem-3"></path>
        <path d="M12.2461 7.09093L23.5236 0.400024L34.8012 7.09093L23.5236 13.7818L12.2461 7.09093Z" fill="#009440" class="svg-elem-4"></path>
        <path d="M0.96875 27.3091V40.6909L12.2463 47.3818V34L0.96875 27.3091Z" fill="#009440" class="svg-elem-5"></path>
        <path d="M23.5236 54.0727L12.2461 47.3818V60.7637L23.5236 67.6V54.0727Z" fill="#009440" class="svg-elem-6"></path>
        <path d="M45.9375 13.7819L57.215 7.09094L68.4926 13.9273L57.215 20.6182L45.9375 13.7819Z" fill="#009440" class="svg-elem-7"></path>
        <path d="M68.4924 40.6909L57.2148 47.3818V60.7636L68.4924 54.0727V40.6909Z" fill="#009440" class="svg-elem-8"></path>
        <path d="M23.5254 40.6909V27.3091L34.8029 34V47.3818L23.5254 40.6909Z" fill="#009440" class="svg-elem-9"></path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animation {
  width: 65px;
  height: 65px;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes appear-and-disappear {
  0%, 100% { fill: rgb(0, 148, 64); }
  50% { fill: transparent; }
}

.svg-elem-1 { animation: appear-and-disappear 1.8s ease-in-out 0s infinite; }
.svg-elem-2 { animation: appear-and-disappear 1.8s ease-in-out 0.2s infinite; }
.svg-elem-3 { animation: appear-and-disappear 1.8s ease-in-out 0.4s infinite; }
.svg-elem-4 { animation: appear-and-disappear 1.8s ease-in-out 0.6s infinite; }
.svg-elem-5 { animation: appear-and-disappear 1.8s ease-in-out 0.8s infinite; }
.svg-elem-6 { animation: appear-and-disappear 1.8s ease-in-out 1s infinite; }
.svg-elem-7 { animation: appear-and-disappear 1.8s ease-in-out 1.2s infinite; }
.svg-elem-8 { animation: appear-and-disappear 1.8s ease-in-out 1.4s infinite; }
.svg-elem-9 { animation: appear-and-disappear 1.8s ease-in-out 1.6s infinite; }
</style>
