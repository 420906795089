<template>
  <div
    :class="[
      'cabinet_sidebar transition-width duration-300',
      { 'w-20': isCollapsed, 'w-68': !isCollapsed && !isMobile },
    ]"
    @transitionend="handleTransitionEnd"
  >
    <router-link :to="{ name: 'home.index' }">
      <div v-if="isCollapsed" class="ml-5 mb-7 text-center">
        <img alt="" class="w-10" src="/img/logo-short.svg" />
      </div>
      <div v-else class="cabinet_logo">
        <img class="h-10 w-46" src="/image/header-logo.svg" alt="" />
      </div>
    </router-link>
    <ul class="cabinet_sidebar_menu">
      <li v-for="route in routes" :key="route.name" class="relative group">
        <router-link
          :class="[
            'text-base-small grey-5-color flex items-center',
            {
              active: isActiveRoute(route.name),
              'pl-4': !isCollapsed,
              'disabled': route.isDisabled,
              'pl-0': isCollapsed,
            },
          ]"
          :to="{ name: route.name }"
        >
          <BaseIcon :icon="route.icon" :active="isActiveRoute(route.name)" />
          <span v-if="!isCollapsed && !isTransitioning">{{ $t(route.label) }}</span>
          <span v-else-if="!route.isDisabled" class="tooltip">{{ $t(route.label) }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import InlineSvg from "~/main";

export default {
  name: "SidebarMain",
  components: { InlineSvg },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeRoute: this.$route.name,
      routes: [
        { name: 'contract.cabinet.index', icon: 'info-icon', label: 'general_information' },
        { name: 'contract.cabinet.balance', icon: 'balance-icon', label: 'balance', isDisabled: false },
        { name: 'contract.cabinet.site.index', icon: 'site-icon', label: 'my_sites' },
        { name: 'contract.cabinet.storage.index', icon: 'cloud-icon', label: 'file_storage' },
        { name: 'contract.cabinet.storageManager.index', icon: 'cloud-icon', label: 'storage_manager', isDisabled: false },
        { name: 'contract.cabinet.database.index', icon: 'database-icon', label: 'databases' },
        { name: 'contract.cabinet.cache.index', icon: 'cache-icon', label: 'cache', isDisabled: true },
        { name: 'contract.cabinet.domains.index', icon: 'domain-icon', label: 'domains', isDisabled: true },
        { name: 'contract.cabinet.certificate.index', icon: 'certificate-icon', label: 'certificates' },
        { name: 'contract.cabinet.processors.index', icon: 'processors-icon', label: 'processors', isDisabled: true },
        { name: 'contract.cabinet.crons.index', icon: 'cron-icon', label: 'crown_of_task', isDisabled:true },
        { name: 'contract.cabinet.customers.index', icon: 'users-icon', label: 'users' },
        { name: 'contract.cabinet.backups.index', icon: 'reserved-icon', label: 'backup_copies', isDisabled:true },
        { name: 'contract.cabinet.logs.index', icon: 'logs-icon', label: 'logs', isDisabled: true },
        { name: 'contract.cabinet.load.index', icon: 'server-icon', label: 'web_server_load' },
        { name: 'contract.cabinet.history.index', icon: 'invitation-icon', label: 'Історія' },
      ],
      isMobile: window.innerWidth < 992,
      isTransitioning: false,
    };
  },
  methods: {
    isActiveRoute(routeName) {
      return this.activeRoute.indexOf(routeName) === 0;
    },
    setActiveContract(contractId) {
      return this.$store.dispatch("contract/setActiveContract", contractId);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 992;
    },
    handleTransitionEnd() {
      this.isTransitioning = false;
    },
  },
  watch: {
    isCollapsed(newVal) {
      this.isTransitioning = true;
    },
    "$route.name": {
      handler: function (name) {
        this.activeRoute = name;
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.group-hover\:block {
  display: none;
}
.group:hover .group-hover\:block {
  display: block;
}
.tooltip {
  display: none;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  left: 100%;
  margin-left: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}
.group:hover .tooltip {
  display: block;
  opacity: 1;
}
</style>
