import layout from "~/layout/App/order.vue";
import auth from "../middleware/auth";

const orderRoutes = {
  path: "/order",
  component: layout,
  name: "order",
  children: [
    {
      path: "",
      component: () => import("~/views/Order/Index"),
      name: "order.index",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default orderRoutes;
