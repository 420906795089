<template>
  <div
    :class="[
        { 'validation-alert': isShowValidationIcon },
        { 'error-input': validation.hasErrors === true },
        { 'success-input': validation.hasErrors === false && modelValue },
        inputWrapperClass,
      ]"
  >
    <select
      :class="[selectClass, customClass]"
      v-model="selection"
      :disabled="disabled"
      :name="name"
      @change="onSelectionChanged"
      :style="customStyle"
    >
      <slot></slot>
    </select>
  </div>
  <ValidationInputMessage
    :key="index"
    v-for="(error, index) in validation.errors"
    class="invalid-feedback show"
  >
    <span>
      {{ $t(error) }}
    </span>
  </ValidationInputMessage>
</template>

<script>
import checkValidationMixin from "~/mixins/checkValidation";
import debounce from "lodash.debounce";

export default {
  props: {
    size: {
      type: String,
      default: "",
    },
    isShowValidationIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: [String, Number, Object],
    selectedOption: [String, Number, Object],
    customClass: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [checkValidationMixin],
  data() {
    return {
      selection: this.modelValue ?? this.selectedOption,
    };
  },
  methods: {
    onSelectionChanged() {
      this.$emit("update:modelValue", this.selection);
    },
    getValue() {
      return this.selection;
    },
  },
  computed: {
    selectClass() {
      return {
        "base-select": true,
        large: this.size === "lg",
        small: this.size === "sm",
        disabled: this.disabled,
        invalid: this.invalid,
      };
    },
  },
  watch: {
    modelValue: function (data) {
      this.selection = data;

      if (this.rules) {
        debounce(() => {
          this.makeValidation(this.rules);
        }, 300)();

        this.validation.isFirstRunWatch = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/design/mainIncludes";

.error-input.validation-alert {
  select {
    border-color: $red-3 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("/public/image/icons/icon-info-danger.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.success-input.validation-alert {
  select {
    border-color: $green-3 !important;

    padding-right: calc(1.5em + 0.75rem);
    background-image: url("/public/image/icons/invitation-success-icon.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.base-select {
  background-image: url(/public/image/arrow-select-black.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 50%;
  background-size: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid $grey-3;
  width: 100%;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
  &.valid-danger {
    border-color: #dc3545;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.invalid {
    border: 1px solid $red-3;
    color: $red-3;
  }
  &.small {
    height: 26px;
    font-size: 12px;
  }
  &.large {
    height: 50px;
  }
}
</style>
