export const state = {
  actionsByType: {
    refreshCounterparty: {
      refreshData: [
        "counterparty/fetchCounterparties",
        "user/fetchBroadcast",
        "counterparty/fetchCounterparties",
      ],
      isAddNotify: false,
    },
    counterpartyInvitation: {
      refreshData: ["user/fetchBroadcast", "counterparty/fetchCounterparties"],
      isAddNotify: false,
    },
    successCreateInvitation: {
      refreshData: ["contract/fetchContracts", "user/fetchBroadcast"],
      isAddNotify: false,
    },
    refreshContract: {
      refreshData: ["contract/fetchContracts", "user/fetchBroadcast"],
      isAddNotify: false,
    },
    successCreateContractCustomer: {
      refreshData: ["contractCustomer/fetchContractCustomers"],
      isAddNotify: false,
    },
    successCreateContractStorage: {
      refreshData: [
        "contractCustomer/fetchContractCustomers",
        "contractStorage/fetchContractStorages",
      ],
      isAddNotify: false,
    },
    refreshContractCertificate: {
      refreshData: ["contractCertificate/fetchContractCertificates"],
    },
    createContractCertificate: {
      refreshData: ["contractCertificate/fetchContractCertificates"],
    },
    updateContractCertificate: {
      refreshData: ["contractCertificate/fetchContractCertificates"],
    },
    refreshCreateContractCustomer: {
      refreshData: ["contractCustomer/fetchContractCustomers"],
      isAddNotify: false,
    },
    successCreateContractCustomerDatabase: {
      refreshData: ["contractCustomerDatabase/fetchContractCustomersDatabase"],
      isAddNotify: true,
    },
    refreshCustomerDatabase: {
      refreshData: ["contractCustomerDatabase/fetchContractCustomersDatabase"],
      isAddNotify: false,
    },
    successTurnOnContractCustomerDatabase: {
      refreshData: ["contractCustomerDatabase/fetchContractCustomersDatabase"],
      isAddNotify: false,
    },
    successTurnOffContractCustomerDatabase: {
      refreshData: ["contractCustomerDatabase/fetchContractCustomersDatabase"],
      isAddNotify: false,
    },
    deleteCreateContractCustomer: {
      refreshData: ["contractCustomer/fetchContractCustomers"],
      isAddNotify: false,
    },
    contractTransactionPaid: {
      refreshData: ["contractTransaction/fetchContractTransactions"],
      isAddNotify: false,
    },
    createContractDatabase: {
      refreshData: ["contractDatabase/fetchContractDatabases"],
      isAddNotify: true,
    },
    siteCreate: {
      refreshData: ["contractSite/fetchContractSites"],
      isAddNotify: false,
    },
    siteUpdate: {
      refreshData: ["contractSite/fetchContractSites"],
      isAddNotify: false,
    },
    siteRefresh: {
      refreshData: ["contractSite/fetchContractSites"],
      isAddNotify: false,
    },
  },
};

/**
 * Getters
 */
export const getters = {
  actionsByType: (state) => state.actionsByType,
};
