import layout from "~/layout/App";
import auth from "../middleware/auth";

const referralRoutes = {
  path: "/referral",
  component: layout,
  name: "referral",
  children: [
    {
      path: "",
      component: () => import("~/views/Referral/Program"),
      name: "referral.program",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "program",
      component: () => import("~/views/Referral/Program"),
      name: "referral.program",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "withdrawals",
      component: () => import("~/views/Referral/Withdrawals"),
      name: "referral.withdrawals",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "counterparties",
      component: () => import("~/views/Referral/Counterparties.vue"),
      name: "referral.counterparties",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "users",
      component: () => import("~/views/Referral/Users"),
      name: "referral.users",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default referralRoutes;
