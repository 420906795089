<template>
  <div :class="[{ 'full-width': fullWidth }]">
    <div
      class="base-input-wrapper"
      :class="[
        { 'validation-alert': isShowValidationIcon },
        { 'error-input': validation.hasErrors === true },
        { 'success-input': validation.hasErrors === false && modelValue },
        inputWrapperClass,
      ]"
    >
      <span v-if="prefix" class="span-group-text">{{ prefix }}</span>
      <input
        v-input-pattern="inputPattern"
        v-bind="directives"
        :autocomplete="type === 'password' ? 'new-password' : 'off'"
        :step="step"
        :value="getValue()"
        @input="input($event)"
        :type="type"
        :placeholder="placeholder"
        :class="inputClass"
        ref="baseInput"
        :required="required"
        :disabled="disabled"
        v-maska
        :data-maska="maska"
        @change="changed()"
      />
      <span v-if="postfix" class="span-group-text">{{ postfix }}</span>

      <img
        v-if="icon"
        class="icon"
        :class="{ 'icon-right': this.iconRight, error: !this.valid }"
        :src="`/image/icons/${icon}.svg`"
        alt="icon"
      />
    </div>
    <transition-group name="fade" tag="div">
      <ValidationInputMessage
        v-if="validation.errors.length > 0"
        v-for="(error, index) in validation.errors"
        :key="index"
        class="invalid-feedback show"
      >
        <span>{{ $t(error) }}</span>
      </ValidationInputMessage>
    </transition-group>
  </div>
</template>
<script>
import { isMobile } from "~/mixins/isMobile";
import checkValidationMixin from "~/mixins/checkValidation";
import debounce from "lodash.debounce";

export default {
  components: {},
  emits: ["change", "keydown", "onUpdate:modelValue", "update:modelValue"],

  props: {
    directives: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    inputPattern: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    step: {
      number: Number,
      default: 1,
    },
    placeholder: {
      type: [String, Number, Object, Boolean],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withError: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    prefix: {
      type: String,
      default: "",
    },
    postfix: {
      type: String,
      default: "",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    isShowValidationIcon: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: "md",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    modelValue: [String, Number, null, Object],
    class: [String],
    valid: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: false,
    },
    borderTransparent: {
      type: Boolean,
      default: false,
    },
    maska: {
      type: String,
    },
  },
  mixins: [isMobile, checkValidationMixin],
  created() {
    // this.$nextTick(() => {
    //   this.makeValidation();
    // });
  },
  mounted() {
  },
  data() {
    return {
      inputValue: null,
    };
  },
  computed: {
    inputClass() {
      return {
        "base-input": true,
        [this.isMobile ? "size-md" : `size-${this.size}`]: true,
        disabled: this.disabled,
        error: !this.valid,
      };
    },
    inputWrapperClass() {
      return {
        [this.isMobile ? "size-md" : `size-${this.size}`]: true,
        "state-disabled": this.disabled,
        "state-error": !this.valid,
        "full-width": this.fullWidth,
        "border-transparent": this.borderTransparent,
      };
    },
  },
  methods: {
    input: function(event) {
      let value = event.target.value
        ? event.target.value.replace(this.prefix, "")
        : event.target.value;

      this.$emit("update:modelValue", value);
      //TODO эта идиотическая штука для того, что бы значения в инпуте, которые вставляем сам браузер  с какого то перепуга, когда страница только загрузилась (например пароли),
      // были доступны для нас и мы могли их сразу провалидировать как нормальные люди.
      this.inputValue = value;
    },
    getValue() {
      // Тут какая-то каша. Нужно так, что бы и валидация работала адекватная и при этом,
      // когда мы очищали значение, оно так же очищалось и тут. Плюс ещё костыль с inputValue, может и его нужно очищать.
      if (!this.modelValue) {
        return  this.value ?? null;
      }

      return (
        this.value ??
        (this.modelValue && typeof this.modelValue !== "number"
          ? this.modelValue.replace(this.prefix, "")
          : this.modelValue) ??
        this.inputValue
      );
    },
    changed: function() {
      //TODO Exactly why I added it here. And is it necessary here, but in order to understand it exactly, I will comment for now
      // this.makeValidation();
      //TODO данный порядок  важен, сперва мы валидируем то что у нас есть сейчас,
      // а  потом уже проверяем что и как проверяется дальше.
      this.$emit("change");
    },
  },
  watch: {
    modelValue: function(value) {
      if (!value) {
        this.hasErrors = null;
      } else if (this.rules) {
        this.inputValue = value;
        //TODO костыль, что бы при превой загрузки данны] в компонент, не обработала их валидация.
        // А дальше только при изменениях.

        debounce(() => {
          this.makeValidation(this.rules);
        }, 300)();

        this.validation.isFirstRunWatch = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";

.full-width {
  width: 100% !important;
}

.base-input-wrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  background: #fff;
  border: 1px solid $grey-2;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  column-gap: 2px;
  flex-direction: row;
  padding: 0;

  &.valid-danger {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
  }

  &.success-input.validation-alert {
    border-color: $green-3 !important;

    input {
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("/public/image/icons/invitation-success-icon.svg");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  &.error-input.validation-alert {
    border-color: $red-3 !important;

    input {
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("/public/image/icons/icon-info-danger.svg");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  &:focus-within {
    border: 1px solid $grey-5;
  }

  &.state-disabled {
    border: 1px solid $grey-3;
  }

  &.state-error,
  &.state-error:focus-within {
    border: 1px solid $red-3;
  }

  &.border-transparent {
    border-color: transparent;
  }

  &.text-center {
    .base-input {
      text-align: center;
    }
  }

  &.table-input {
    &.state-disabled {
      border-color: #f4f7f4;
    }

    .base-input {
      &.disabled {
        background: #f4f7f4;
        color: $grey-4;
        opacity: 1;
      }
    }
  }

  &.size-lg {
    column-gap: 6px;
  }

  img.icon {
    order: -1;
    width: 20px;
    height: 20px;
    margin: 0 0 0 12px;

    &-right {
      margin: 0 12px 0 0;
      order: 0;
    }

    &.error {
      filter: brightness(80%) sepia(100%) hue-rotate(-50deg) saturate(8) contrast(1);
    }

    @include maxWidth768 {
      width: 15px;
      height: 15px;
    }
  }

  .base-input {
    width: 100%;
    outline: none;
    background: transparent;
    color: $black;
    border-radius: inherit;
    border: none;
    box-sizing: border-box;
    height: auto;
    padding: 4px 2px;
    -webkit-appearance: none;

    &.size-sm {
      font-size: 14px;
    }

    &.size-md {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      height: 38px;
    }

    &.size-lg {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 10px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.error {
      color: $red-3;
    }

    &::placeholder {
      color: $black-2;
    }
  }

  .span-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

