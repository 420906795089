<template>
  <header :class="['header', { 'active-mobile': activeMenu }]">
    <div class="mainContainer">
      <div class="header_wrapp flex items-center justify-between">
        <InlineSvg
          v-if="!isMobile && layout === 'main'"
          style="height:22px; transition: transform 0.3s ease, color 0.3s ease"
          src="/img/collapse.svg"
          :class="[
            'pointer mr-auto',
            { 'rotate-90': isCollapsed, 'grey-5-color': !isCollapsed, 'green-5-color': isCollapsed }
          ]"
          @click="toggleSidebar"
        ></InlineSvg>

        <router-link class="header_logo" :to="{ name: 'home.index' }">
          <img src="/image/header-logo.svg" alt="" />
        </router-link>
        <div class="header_lang hidden lg:flex">
          <button
            v-for="locale in locales"
            :key="locale.id"
            @click="setLanguage(locale.key)"
            :class="[
              'semibold-font',
              { active: locale.key === selectedLocale },
            ]"
          >
            {{ $t("language_short." + locale.name) }}
          </button>
        </div>
        <div
          v-click-outside="clickOutsideNotificationMenu"
          class="header_notification relative"
        >
          <button
            :class="[
              'header_notification_toggler',
              { active: showNotificationMenu },
            ]"
            @click="showBroadcast"
          >
            <BaseIcon icon="notification-icon" />
            <div class="count" v-if="notificationUnreadCount > 0">
              {{ notificationUnreadCount > 9 ? '!' : notificationUnreadCount }}
            </div>
          </button>
          <NotificationMenu
            :show="showNotificationMenu"
            @close="showNotificationMenu = false"
          />
        </div>
        <div class="header_menu hidden lg:flex">
          <div class="header_lang">
            <button
              v-for="loc in locales"
              :key="loc.key"
              @click="setLanguage(loc.key)"
              :class="['active', { active: loc.key === locale }]"
            >
              {{ $t("language_short." + loc.name) }}
            </button>
          </div>
          <div class="header_menu_item header_balance" v-if="balance">
            <div class="label-menu">{{ $t("balance") }}</div>
            <div class="header_balance_btn">
              <BaseIcon icon="header-balance-icon" />
              <span>{{ balance }} $</span>
            </div>
          </div>
          <div class="header_menu_item header_details">
            <div class="label-menu">{{ $t("contract") }}</div>
            <button
              :disabled="isLoadContract"
              @click="showCounterpartyMenu = !showCounterpartyMenu"
              :class="[
                'header_details_toggler',
                { active: showCounterpartyMenu },
                { current: layout === 'main' },
                { empty: emptyCounterpartyMenu },
              ]"
              v-click-outside="clickOutsideCounterpartyMenu"
            >
              <BaseIcon icon="contragent-details-icon" />
              <span>{{ activeContract?.name }}</span>
            </button>
            <CounterpartyMenu
              :show="showCounterpartyMenu"
              @close="showCounterpartyMenu = false"
              @emptyList="checkCounterpartyMenu"
            />
          </div>
          <div class="header_menu_item header_profile">
            <div class="label-menu">Персональний кабінет</div>
            <button
              :class="[
                'header_profile_toggler',
                { active: showProfileMenu },
                { current: layout === 'profile' },
              ]"
              @click="showProfileMenu = !showProfileMenu"
              v-click-outside="clickOutsideProfileMenu"
            >
              <BaseIcon icon="profile-icon" />
            </button>
            <ProfileMenu
              :show="showProfileMenu"
              @close="showProfileMenu = false"
            />
          </div>
        </div>
        <button class="header-toggler lg:hidden" @click="activeMenu = !activeMenu">
          <i v-if="activeMenu" class="icon close-menu-icon"></i>
          <i v-else class="icon burger-menu-icon"></i>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { readBroadcast } from "~/api/user";
import { mapGetters, mapActions } from "vuex";
import ProfileMenu from "~/components/Menu/HeaderProfileMenu.vue";
import CounterpartyMenu from "~/components/Menu/HeaderCounterpartyMenu.vue";
import NotificationMenu from "~/components/Menu/HeaderNotificationMenu.vue";
import debounce from "lodash.debounce";
import { loadLocaleMessages } from "~/plugins/i18n";
import { updateUserSettings } from "~/api/user";
import InlineSvg from "~/main";

export default {
  name: "Navbar",
  props: {
    layout: String,
    isCollapsed: Boolean,
  },
  components: {
    InlineSvg,
    ProfileMenu,
    CounterpartyMenu,
    NotificationMenu,
  },
  data() {
    return {
      showProfileMenu: false,
      showCounterpartyMenu: false,
      showNotificationMenu: false,
      activeMenu: false,
      readBroadcastDebounced: null,
      emptyCounterpartyMenu: null,
      isMobile: window.innerWidth < 992,
    };
  },
  mounted() {
    if (this.activeContract) {
      this.$store.dispatch(
        "contractTransaction/fetchContractTransactions",
        this.activeContract.id
      );
    }

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions("user", ["fetchUser", "fetchBroadcasts", "readBroadcast"]),
    ...mapActions("lang", ["loadLocaleMessages"]),
    ...mapActions("contractTransaction", ["fetchContractTransactions"]),
    showBroadcast() {
      // const wasOpened = this.showNotificationMenu;
      this.showNotificationMenu = !this.showNotificationMenu;

      // if (wasOpened && !this.showNotificationMenu) {
      //   this.updateBroadcast();
      // }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 992;
    },
    async setLanguage(language) {
      await loadLocaleMessages(language);

      let form = {
        language: this.localeValue,
        timezone: this.user.settings.timezone.timezone,
      };
      updateUserSettings(form).then(() => {
        this.$store.dispatch("user/fetchUser");
      });
    },
    updateBroadcast() {
      // if (this.showNotificationMenu && this.notificationUnreadCount > 0) {
      //   readBroadcast().then(({ data }) => {
      //     if (data) {
      //       this.$store.dispatch("user/fetchBroadcast");
      //     }
      //   });
      // }
    },
    clickOutsideProfileMenu: function() {
      this.showProfileMenu === true ? (this.showProfileMenu = false) : "";
    },
    clickOutsideCounterpartyMenu: function() {
      this.showCounterpartyMenu === true
        ? (this.showCounterpartyMenu = false)
        : "";
    },
    clickOutsideNotificationMenu() {
      if (this.showNotificationMenu) {
        // this.updateBroadcast();
        this.showNotificationMenu = false;
      }
    },
    checkCounterpartyMenu(data) {
      this.emptyCounterpartyMenu = data;
    },
    toggleSidebar() {
      this.$emit('toggle');
    }
  },
  create: function() {
    this.showCountBroadcast(this.broadcasts);
  },
  computed: {
    ...mapGetters({
      balance: "contractTransaction/balance",
      user: "user/user",
      selectedLocale: "lang/locale",
      locales: "lang/locales",
      locale: "lang/locale",
      localeValue: "lang/localeValue",
      activeContract: "contract/activeContract",
      isLoadContract: "contract/isLoad",
      broadcasts: "user/broadcasts",
      notificationUnreadCount: "user/notificationUnreadCount",
    }),
  },
  watch: {
    showNotificationMenu: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.notificationUnreadCount > 0) {
          this.readBroadcastDebounced = debounce(() => {
            readBroadcast().then(() => {
              this.$store.dispatch("user/fetchBroadcast");
            });
          }, 800);
          this.readBroadcastDebounced();
        } else {
          if (this.readBroadcastDebounced) {
            this.readBroadcastDebounced.cancel();
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.rotate-90 {
  transform: rotate(180deg);
}
</style>
