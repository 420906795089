import { isNumber, isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const isNumberValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  const numberValue = Number(value);


  console.log(numberValue);

  return (
    !isNaN(numberValue) ||
    i18n.global.t("validate.the_field_must_contain_only_numbers")
  );
};

export { isNumberValidator };
