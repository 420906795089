import { createRouter, createWebHistory } from "vue-router";

import Main from "~/layout/App/index";
import UserHistory from "~/views/CounterpartyAndUserHistory/User.vue";
import CounterpartiesHistory from "~/views/CounterpartyAndUserHistory/Counterparties.vue";
import PageNotFound from "~/views/404.vue";
import authRoutes from "~/router/modules/authRoutes";
import profileRoutes from "~/router/modules/profileRoutes";
import counterpartyRoutes from "~/router/modules/counterpartyRoutes";
import contractRoutes from "~/router/modules/contractRoutes";
import invitationRoutes from "~/router/modules/invitationRoutes";
import contractCabinetRoute from "~/router/modules/contractCabinetRoute";
// import certificatesRoutes from "~/router/modules/certificatesRoutes";
import databasesRoutes from "~/router/modules/databasesRoutes";
import balanceRoutes from "~/router/modules/balanceRoutes";
import referralRoutes from "~/router/modules/referralRoutes";
import orderRoutes from "~/router/modules/orderRoutes";
import domainsRoutes from "~/router/modules/domainsRoutes";
import cacheRoutes from "~/router/modules/cacheRoutes";
// import processorsRoutes from "~/router/modules/processorsRoutes";
import logsRoutes from "~/router/modules/logsRoutes";
import cronsRoutes from "~/router/modules/cronsRoutes";
import backupsRoutes from "~/router/modules/backupsRoutes";
import store from "~/store";
import BaseUI from "~/views/BaseUI.vue";

// import auth from "./middleware/auth";

const routes = [
  authRoutes,
  profileRoutes,
  counterpartyRoutes,
  contractRoutes,
  invitationRoutes,
  balanceRoutes,
  referralRoutes,
  orderRoutes,
  // certificatesRoutes,
  databasesRoutes,
  contractCabinetRoute,
  domainsRoutes,
  cacheRoutes,
  // processorsRoutes,
  logsRoutes,
  cronsRoutes,
  backupsRoutes,
  {
    path: "/",
    name: "Home",
    component: Main,
    meta: {
      // middleware: auth,
    },
    children: [
      { path: "404",
        component: PageNotFound,
        name: "PageNotFound"
      },
      {
        path: "",
        component: () => import("~/views/MainDashboard/Index"),
        name: "home.index",
        meta: {
          // middleware: auth,
        }
      },
      {
        path: "history/user",
        component: UserHistory,
        name: "history.user.index",
        meta: {
          // middleware: auth,
        }
      },
      {
        path: "history/counterparties",
        component: CounterpartiesHistory,
        name: "history.counterparties.index",
        meta: {
          // middleware: auth,
        }
      }
    ]
  },
  {
    path: "/base-ui",
    name: "BaseUI",
    component: BaseUI
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound }
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return {
      el: "#cabinet",
      top: 10
    };
  },
  history: createWebHistory(),
  routes
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
// function nextFactory(context, middleware, index) {
//   const subsequentMiddleware = middleware[index];
//   // If no subsequent Middleware exists,
//   // the default `next()` callback is returned.
//   if (!subsequentMiddleware) return context.next;

//   return (...parameters) => {
//     // Run the default Vue Router `next()` callback first.
//     context.next(...parameters);
//     // Then run the subsequent Middleware with a new
//     // `nextMiddleware()` callback.
//     const nextMiddleware = nextFactory(context, middleware, index + 1);
//     subsequentMiddleware({ ...context, next: nextMiddleware });
//   };
// }

router.beforeEach((to, from, next) => {
  if (to.meta.middleware && to.name !== from.name) {
    const middlewares = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    };

    for (let i = 0; i < middlewares.length; i++) {
      const path = middlewares[i](context);
      if (path !== null) {
        return next(path);
      }
    }
  }

  if (!to.params.contractName) {
    to.params.contractName = store.getters["contract/activeContract"]?.name;
  }

  return next();
});

export default router;
