import { list as getList } from "~/api/contractProcessorPhpCustom.js";
import * as types from "../mutation-types";
import store from "~/store";


export const state = {
  list: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CUSTOM_CONTRACT_PROCESSOR_PHP_PARAMS](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_CUSTOM_PROCESSOR_PHP_PARAMS](
    state,
    response
  ) {
    state.list = response.data;
  },
};

export const actions = {
  fetchContractCustomProcessorPhpParams({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CUSTOM_CONTRACT_PROCESSOR_PHP_PARAMS, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    getList(activeContractId).then((response) => {
      let { data } = response;
      console.log(data);
      commit(types.SET_CONTRACT_CUSTOM_PROCESSOR_PHP_PARAMS, data);
      commit(types.SET_IS_LOADING_CUSTOM_CONTRACT_PROCESSOR_PHP_PARAMS, false);
    });
  },
};

export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
