export const DATABASE_CUSTOMER_CREATED = "s0055";
export const DATABASE_CUSTOMER_CREATE_ERROR = "e0055";
export const DATABASE_CUSTOMER_UPDATED = "s0056";
export const DATABASE_CUSTOMER_UPDATE_ERROR = "e0056";
export const DATABASE_CUSTOMER_TURNED_ON = "s0057";
export const DATABASE_CUSTOMER_TURN_ON_ERROR = "e0057";
export const DATABASE_CUSTOMER_TURNED_OFF = "s0058";
export const DATABASE_CUSTOMER_TURN_OFF_ERROR = "e0058";
export const DATABASE_CUSTOMER_REMOVED = "s0059";
export const DATABASE_CUSTOMER_REMOVE_ERROR = "e0059";


export const customerDatabase = {
  [DATABASE_CUSTOMER_CREATED]: {
    icon: "/img/users.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.customers.db.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.customer_db.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.customer.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_CUSTOMER_UPDATED]: {
    icon: "/img/users.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.customers.db.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.customer_db.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.update"),
      entityType: $t("notification.contract.entity.customer.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_CUSTOMER_REMOVED]: {
    icon: "/img/users.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.customers.db.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.customer_db.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.deleted"),
      entityType: $t("notification.contract.entity.customer.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_CUSTOMER_TURNED_ON]: {
    icon: "/img/users.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.customers.db.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.customer_db.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOn"),
      entityType: $t("notification.contract.entity.customer.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [DATABASE_CUSTOMER_TURNED_OFF]: {
    icon: "/img/users.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.customers.db.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.customer_db.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOff"),
      entityType: $t("notification.contract.entity.customer.database"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
}