import { list as getList } from "~/api/contractStorage";
import * as types from "../mutation-types";
import store from "../../store";
import { ACTIVE, INACTIVE } from "~/constants/entityStatus";

/**
 * Initial state
 */
export const state = {
  list: null,
  isLoading: false,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_STORAGE](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_STORAGE](state, contractStorage) {
    state.list = contractStorage.data;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchContractStorages({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_STORAGE, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_STORAGE, data);
        commit(types.SET_IS_LOADING_CONTRACT_STORAGE, false);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  listOfActive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === ACTIVE
      )
      : [],
  listOfInactive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === INACTIVE
      )
      : [],
  listOfStable: (state) =>
    state.list
      ? state.list.filter(
        (item) => (item.statusId === ACTIVE || item.statusId === INACTIVE)
      )
      : [],
};
