import layout from "~/layout/Auth";
import login from "~/router/middleware/login";

const authRoutes = {
  path: "/auth",
  component: layout,
  redirect: "/auth/login",
  name: "auth",
  meta: {
    middleware: login,
  },
  children: [
    {
      path: "login",
      component: () => import("~/views/Auth/Login"),
      name: "auth.login",
    },
    {
      path: "register/:referral?",
      component: () => import("~/views/Auth/Register"),
      name: "auth.register",
    },
    {
      path: "forgot-password",
      component: () => import("~/views/Auth/ForgotPassword"),
      name: "auth.forgot_passport",
    }, {
      path: "verification/:token",
      component: () => import("~/views/Auth/Verification"),
      name: "auth.verification",
    },
    {
      path: "reset-password/:token",
      component: () => import("~/views/Auth/ResetPassword"),
      name: "auth.reset_passport",
    },
  ],
};

export default authRoutes;
