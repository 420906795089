<template>
    <div class="truncate w-full relative">
      <slot />
    </div>
</template>

<script>
export default {
  name: 'BaseEllipsis',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    customStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>