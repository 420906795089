import { index } from "~/api/contractBalance.js";
import * as types from "../mutation-types";
import store from "../../store";

export const state = {
  amount: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_BALANCE_AMOUNT](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_BALANCE_AMOUNT](state, data) {
    state.amount = data.data;
  },
};

export const actions = {
  fetchContractBalanceAmount({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_BALANCE_AMOUNT, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      index(activeContractId).then((data) => {
        //TODO тут мы только  запрос,  что бы получить данные. А может быть их получать по http запросу?
        //Одним словом подумать, может быть просто запросом или всё такие сокетом?
        // let { data } = response;
        commit(types.SET_IS_LOADING_CONTRACT_BALANCE_AMOUNT, false);
        commit(types.SET_CONTRACT_BALANCE_AMOUNT, data.data);
      });
    }
  },
};

export const getters = {
  amount: (state) => state.amount,
  isLoading: (state) => state.isLoading,
};
