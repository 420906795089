export const isMobile = {
  data() {
    return {
      isMobile: null,
    };
  },
  created() {
    this.isMobile = window.innerWidth <= 768;
  },
  mounted() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
