import { list as getList } from "~/api/contractCustomerDatabase";
import * as types from "../mutation-types";
import store from "../../store";
import { ACTIVE, INACTIVE } from "~/constants/entityStatus";

export const state = {
  list: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_CUSTOMER_DATABASE](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_CUSTOMER_DATABASE](state, contractCustomer) {
    state.list = contractCustomer.data;
  },
};

export const actions = {
  fetchContractCustomersDatabase({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_CUSTOMER_DATABASE, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_CUSTOMER_DATABASE, data);
        commit(types.SET_IS_LOADING_CONTRACT_CUSTOMER_DATABASE, false);
      });
    }
  },
};

export const getters = {
  list: (state) => state.list,
  listOfActive: (state) =>
    state.list
      ? state.list.filter(
          (item) => item.statusId === ACTIVE
        )
      : [],
  listOfInactive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === INACTIVE
      )
      : [],
  listOfStable: (state) =>
    state.list
      ? state.list.filter(
        (item) => (item.statusId === ACTIVE || item.statusId === INACTIVE)
      )
      : [],
  isLoading: (state) => state.isLoading,

};