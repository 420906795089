import layout from "~/layout/App";
import auth from "../middleware/auth";

const invitationRoutes = {
  path: "/invitation",
  component: layout,
  name: "invitation",
  children: [
    {
      path: "",
      component: () => import("~/views/Invitation/Contract"),
      name: "invitation.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "contracts",
      component: () => import("~/views/Invitation/Contract"),
      name: "invitation.contracts",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "counterparties",
      component: () => import("~/views/Invitation/Counterparties"),
      name: "invitation.counterparties",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default invitationRoutes;
