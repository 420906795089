import axios from "axios";
import { api } from "~/router/apiConfig";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
  timezones: [],
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_TIME_ZONES](state, user) {
    state.timeZones = user;
  },
};

/**
 * Actions
 */
export const actions = {
  async fetchTimeZones({ commit }) {
    // try {
    const { data } = await axios.get(api.path("other.timezones"));

    commit(types.SET_TIME_ZONES, data);

    return data.data;

    // } catch (e) {
    //   // commit(types.FETCH_USER_FAILURE);
    // }
  },
};

/**
 * Getters
 */
export const getters = {
  timeZones: (state) => state.timeZones,
};
