import auth from "../middleware/auth";

const processorsRoutes = [
  {
    path: "processors",
    component: () => import("~/views/Processors/Index"),
    name: "contract.cabinet.processors.index",
    meta: {
      middleware: auth,
    },
  },
  {
    path: "processors/create",
    component: () => import("~/views/Processors/Create"),
    name: "contract.cabinet.processors.create",
    meta: {
      middleware: auth,
    },
  },
  {
    path: "processors/:id/php-default",
    component: () => import("~/views/Processors/ShowPhpDefault"),
    name: "contract.cabinet.processors.show.php.default",
    meta: {
      middleware: auth,
    },
  },
  {
    path: "processors/:id/php-custom",
    component: () => import("~/views/Processors/Update"),
    name: "contract.cabinet.processors.php.update",
    meta: {
      middleware: auth,
    },
  },
];

export default processorsRoutes;
