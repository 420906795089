<template>
  <BaseModal
    @close="$emit('close')">
    <template v-slot:content>
      <div v-if="isLoading" class="modal-loader">
        <div class="spinner"></div>
      </div>
      <div style="max-width: 85%;margin: 24px auto 0;" class="modal-nav">
        <BaseButton
          type="button"
          class="w-1/2 btn btn-bordered btn-md"
          @click="$emit('close')"
        >
          {{ $t("buttons.no") }}
        </BaseButton>
        <BaseButton
          variant="primary"
          color="red-3"
          size="md"
          class="btn-delete w-1/2"
          @click="handleClick"
          type="button"
        >
          {{ $t("buttons.yes") }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseButton from "~/components/BaseUI/BaseButton.vue";

export default {
  name: "setContragentPermission",
  components: { BaseButton },
  props: {
    isLoading: {
      type: Boolean,
      required: false
    },
  },
  emits: ["make", "close"],
  methods: {
    handleClick() {
      this.$emit('make');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-nav {
  flex-wrap:nowrap !important;
}
</style>
