import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const databasesRoutes = {
  path: "/databases",
  component: layout,
  name: "databases",
  children: [
    {
      path: "",
      component: () => import("~/views/Databases/Index"),
      name: "databases.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "edit",
      component: () => import("~/views/Databases/Edit"),
      name: "databases.edit",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default databasesRoutes;
