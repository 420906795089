const domainZoneNameValidator = (value) => {
  if (!value) {
    return true;
  }

  const regex = /^(?=.{1,253}$)((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,}$/;

  return regex.test(value) ? true : "validate.zone_failed";
};

export { domainZoneNameValidator };
