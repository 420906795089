//TODO все SVG иконки передалать в классы иконок. Далее меню сформировать через данный store.

export const state = {
  notifications: [
    { name: "balande", icon: "", path: "" },
    { name: "counterparties", icon: "", path: "" },
    { name: "contracts", icon: "", path: "" },
    { name: "referral_program", icon: "", path: "" },
    { name: "referral_program", icon: "", path: "" },
    { name: "personal_settings", icon: "", path: "" },
    { name: "invitations", icon: "", path: "" },
  ],
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.notifications,
};
