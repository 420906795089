import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("contractBackupDatabase.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, backupDatabaseId, request) {
  return axios.put(
    api.path("contractBackupDatabase.update", {
      contractId: contractId,
      backupDatabaseId: backupDatabaseId,
    }),
    request
  );
}

export function list(contractId) {
  return axios.get(
    api.path("contractBackupDatabase.index", { contractId: contractId })
  );
}

export function show(contractId, backupDatabaseId) {
  return axios.get(
    api.path("contractBackupDatabase.show", {
      contractId: contractId,
      backupDatabaseId: backupDatabaseId,
    })
  );
}

export function remove(contractId, backupDatabaseId) {
  return axios.delete(
    api.path("contractBackupDatabase.remove", {
      contractId: contractId,
      backupDatabaseId: backupDatabaseId,
    })
  );
}

export function turnOn(contractId, backupDatabaseId) {
  return axios.put(
    api.path("contractBackupDatabase.turnOn", {
      contractId: contractId,
      backupDatabaseId: backupDatabaseId,
    })
  );
}

export function turnOff(contractId, backupDatabaseId) {
  return axios.put(
    api.path("contractBackupDatabase.turnOff", {
      contractId: contractId,
      backupDatabaseId: backupDatabaseId,
    })
  );
}
