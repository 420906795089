export const COUNTERPARTY_CREATED = "s2005";
export const COUNTERPARTY_UPDATED = "s2006";
export const COUNTERPARTY_REMOVED = "s2009";
export const INVITATION_CREATED = "s2015";
export const INVITATION_UPDATED = "s2016";
export const INVITATION_REMOVED = "s2019";

export const USER_INVITATION_CONFIRMED = "s2014";
export const USER_INVITATION_UN_CONFIRMED = "s2013";

export const COUNTERPARTY_USER_UPDATED = "s2036";
export const COUNTERPARTY_USER_REMOVED = "s2039";

export const CONTRACT_UPDATED_ATTACHED = "s2026";
export const CONTRACT_UPDATED_DETACHED = "s2024";
export const CONTRACT_CREATED = "s2025";
export const CONTRACT_DELETED = "s2029";


