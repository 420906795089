<template>
  <div
    class="tabs-switcher"
    :class="
      ({
        [`tabs-switcher-sm`]: this.small && this.isMobile,
      },
      { 'tabs-nav': navTab })
    "
    :style="{
      width: cssWidth,
    }"
  >
    <div
      :class="{
        disabled: tab.disabled || false,
        tab: true,
        'tab-active': isSelected(tab),
        'tab-sm': this.small,
        bold: isSelected(tab),
      }"
      v-for="tab in tabs"
      :key="tab.value"
      @click="onTabClicked(tab)"
    >
      <span class="fw-600" v-if="tab.link">{{ tab.text }}</span>
      <template class="fw-600" v-else>{{ tab.text }}</template>
    </div>
  </div>
</template>

<script>
import { isMobile } from "~/mixins/isMobile";

export default {
  props: {
    //TODO  прописать более детально, что именно должно быть в массиве.
    tabs: Array,
    modelValue: [String, Number, Object],
    small: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Boolean,
      default: false,
    },
    cssWidth: {
      type: String,
      default: "100%",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    navTab: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isMobile],
  methods: {
    onTabClicked(tab) {
      let inputValue = tab.value;

      if (this.disabled) return;

      if (tab.link) {
        this.$router.push({ name: tab.link });
      }

      this.$emit("update:modelValue", inputValue);
    },
    isSelected(tab) {
      if (tab.link) {
        return this.$route.name.indexOf(tab.link) === 0;
      }
      return this.modelValue === tab.value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/design/mainIncludes";

.tabs-switcher {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
  overflow: auto;
  background: transparent;
  &-sm {
    column-gap: 16px;
  }
  &.tabs-nav {
    .tab {
      padding: 8px 0;
      //font-family: "Museo Regular", sans-serif;
      font-size: 14px;
    }
  }
}

.tab {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 8px;
  text-align: center;
  overflow: hidden;
  width: fit-content;
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
  align-items: center;
  box-sizing: border-box;
  color: $grey-4;
  padding: 24px 0;
  a {
    line-height: 2;
  }
  &-sm {
    font-size: 14px;
    padding: 16px 0;
  }
  @include maxWidth768 {
    min-width: fit-content;
  }
}

.tab-active {
  border-bottom: 2px solid $green-5;
  color: $green-5;
}
.tabs-switcher.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
