<template>
  <BaseHistory>
    <div v-if="!isLoading || histories" class="mt-32">
      <HistoryItem
        :key="item.id"
        :item="item"
        :date="item.timedAt"
        :title="getTitle(item)"
        :icon="getIcon(item)"
        :text="getText(item)"
        v-for="item in histories"
      ></HistoryItem>
      <div v-if="histories?.length == 0" class="mt-20 grey-4-color">
        {{ $t("counterparty.notification.no_events") }}
      </div>
    </div>
    <div v-if="isLoading && !histories" class="w-full">
      <BaseItemLoader :loaderDistribution="['w-2/12', 'w-8/12', 'w-2/12']" v-for="index in 3" :key="index" />
    </div>
  </BaseHistory>
  <PaginationDefault
    v-if="historiesMeta && histories?.length > 0 && historiesMeta.totalPages > 0"
    :total="historiesMeta.total"
    :count="historiesMeta.count"
    :perPage="historiesMeta.perPage"
    :currentPage="historiesMeta.currentPage"
    :totalPages="historiesMeta.totalPages"
    :lastPage="historiesMeta.lastPage"
    :from="historiesMeta.from"
    :to="historiesMeta.to"
    @change="getData"
  ></PaginationDefault>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "~/mixins/base";
import HistoryItem from "~/views/CounterpartyAndUserHistory/components/Item.vue";
import PaginationDefault from "~/components/Pagination/default.vue";
import BaseHistory from "./components/BaseHistory.vue";
import historyMixin from "~/mixins/history";

export default {
  data() {
    return {
      loadingStyle: {
        cursor: "progress"
      }
    };
  },
  mixins: [baseMixin, historyMixin],
  components: {
    HistoryItem,
    BaseHistory,
    PaginationDefault
  },
  created() {
    this.loadData(() => {
      this.$store.dispatch("counterpartyHistory/fetchCounterpartyHistories");
    });
  },
  methods: {
    async getData(page) {
      let params = {
        page: page
      };

      await this.loadData(async () => {
        await this.$store.dispatch(
          "counterpartyHistory/fetchCounterpartyHistories",
          params
        );
      });
    }
  },
  computed: {
    ...mapGetters({
      histories: "counterpartyHistory/list",
      historiesMeta: "counterpartyHistory/meta",
      isLoadingHistories: "counterpartyHistory/isLoading"
    })
  }
};
</script>