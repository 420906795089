<template>
  <div class="option-item" @click="$emit('selected', type)">
    <BaseButton
      size="md"
      circle
      iconSize="md"
      :icon="icon"
      color="white-3"
      :brightIcon="false"
      variant="primary"
    />
    <span class="text-base-span-lg"> {{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/design/mainIncludes";

.option {
  &-item {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 12px 20px;
    cursor: pointer;

    &:not(:first-child) {
      border-top: 1px solid #e5e6e5;
    }
  }
}
</style>
