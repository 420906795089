import axios from "axios";
import { api } from "~/router/apiConfig";

export function show(contractId, request) {
  return axios.get(
    api.path("contractSetting.show", { contractId: contractId }),
    request
  );
}

export function update(contractId, request) {
  return axios.put(
    api.path("contractSetting.update", { contractId: contractId }),
    request
  );
}
