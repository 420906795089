import axios from "axios";
import { api } from "~/router/apiConfig";


export function list(contractId, query = null) {
  return axios.get(
    api.path("payment.index", { contractId: contractId }),
    { params: query }
  );
}


export function create(contractId, request) {
  return axios.post(
    api.path("payment.store", { contractId: contractId }),
    request
  );
}

export function paid(contractId, paymentId) {
  return axios.put(
    api.path("payment.paid", {
      contractId: contractId,
      paymentId: paymentId,
    })
  );
}
