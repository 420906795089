import axios from "axios";
import { api } from "~/router/apiConfig";
import * as types from "../mutation-types";

export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_USER_SETTINGS_NOTIFICATION_CONTRACT](state, status) {
    state.isLoading = status;
  },
  [types.SET_USER_SETTINGS_NOTIFICATION_CONTRACT](state, settings) {
    state.list = settings;
  },
  [types.SET_USER_SETTINGS_NOTIFICATION_CONTRACT_META](state, meta) {
    state.meta = meta;
  },
};

export const actions = {
  fetchNotifications({ commit }) {
    commit(types.SET_IS_LOADING_USER_SETTINGS_NOTIFICATION_CONTRACT, true);
    axios.get(api.path("userSettingsNotificationContract.index"))
      .then((response) => {
        let { data } = response;
        commit(types.SET_USER_SETTINGS_NOTIFICATION_CONTRACT, data.data);
        commit(types.SET_USER_SETTINGS_NOTIFICATION_CONTRACT_META, data.meta);
        commit(types.SET_IS_LOADING_USER_SETTINGS_NOTIFICATION_CONTRACT, false);
      });
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
