import { isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const maxValidator = (value, length) => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return (
      value.every((val) => maxValidator(val, { length })) ||
      i18n.global.t("validate.the_field_must_not_be_greater_than_characters", {
        max: Number(length),
      })
    );
  }

  if (typeof value === "string") {
    value = !isNaN(value) ? value.toString() : value;

    return (
      value.length <= Number(length) ||
      i18n.global.t("validate.the_field_must_not_be_greater_than_characters", {
        max: Number(length),
      })
    );
  }

  return true;
};

export { maxValidator };
