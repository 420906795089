export const CHANGED_PASSWORD = 1;
export const NEW_REFERRAL_USER = 10;
export const CONFIRM_INVITATION_COUNTERPARTY = 21;
export const UN_CONFIRM_INVITATION_COUNTERPARTY = 22;
export const CREATE_INVITATION_COUNTERPARTY = 23;
export const REMOVE_INVITATION_COUNTERPARTY = 24;
export const CONFIRM_INVITATION_CONTRACT = 25;
export const UN_CONFIRM_INVITATION_CONTRACT = 26;
export const REMOVE_FROM_COUNTERPARTY = 33;
export const CREATE_INVITATION_CONTRACT = 43;
export const REMOVE_INVITATION_CONTRACT = 44;
export const REMOVE_FROM_CONTRACT = 53;

export const userProfileConfig = {
  [CHANGED_PASSWORD]: {
    icon: "/img/user.svg",
    langKeyHistory: "notification.user.changed_password",
    langKeyNotificationAction: "notification.user.changed_password",
    langKeyNotificationTitle: "notification.personal_settings.title",
  },
  [NEW_REFERRAL_USER]: {
    icon: "/img/referral-program.svg",
    langKeyHistory: "notification.referral_program.new_user",
    route: "referral.program",
    langKeyNotificationAction: "notification.referral_program.new_user",
    langKeyNotificationTitle: "notification.referral_program.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      email: data.name,
      link: data.link,
    }),
  },
  [REMOVE_FROM_COUNTERPARTY]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.user.remove_from_counterparty",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.remove_from_counterparty",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      counterpartyName: data.name,
      userName: data.initiatorName,
    }),
  },
  [REMOVE_FROM_CONTRACT]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.user.remove_from_contract",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.remove_from_contract",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      contractName: data.name,
      userName: data.initiatorName,
    }),
  },

  [UN_CONFIRM_INVITATION_CONTRACT]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.user.un_confirm_invitation_contract",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.un_confirm_invitation_contract",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      contractName: data.name,
      userName: data.initiatorName,
    }),
  },
  [CONFIRM_INVITATION_CONTRACT]: {
    icon: "/img/contract.svg",
    langKeyHistory: "notification.user.confirm_invitation_contract",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.confirm_invitation_contract",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      contractName: data.name,
      userName: data.initiatorName,
    }),
  },

  [UN_CONFIRM_INVITATION_COUNTERPARTY]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.user.un_confirm_invitation_counterparty",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.un_confirm_invitation_counterparty",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      counterpartyName: data.name,
      userName: data.initiatorName,
    }),
  },
  [CONFIRM_INVITATION_COUNTERPARTY]: {
    icon: "/img/counterparty.svg",
    langKeyHistory: "notification.user.confirm_invitation_counterparty",
    route: "counterparty.index",
    langKeyNotificationAction: "notification.user.confirm_invitation_counterparty",
    langKeyNotificationTitle: "notification.user.title",
    // eslint-disable-next-line no-unused-vars
    langTextParams: (data, $t) => ({
      counterpartyName: data.name,
      link: data.link,
    }),
    hash: (id) => `#${id}`,
  },
  [CREATE_INVITATION_COUNTERPARTY]: {
    icon: "/img/invitation.svg",
    langKeyHistory: "notification.user.invited_to_counterparty",
    route: "invitation.counterparties",
    langKeyNotificationAction: "notification.user.invited_to_counterparty",
    langKeyNotificationTitle: "notification.invitation.title",
    langTextParams: (data, $t) => ({
      counterpartyName: data.name,
      user: data.initiatorName,
      link: data.link,
    }),
  },
  [CREATE_INVITATION_CONTRACT]: {
    icon: "/img/invitation.svg",
    langKeyHistory: "notification.user.invited_to_contract",
    route: "invitation.counterparties",
    langKeyNotificationAction: "notification.user.invited_to_contract",
    langKeyNotificationTitle: "notification.invitation.title",
    langTextParams: (data, $t) => ({
      contractName: data.name,
      user: data.initiatorName,
      link: data.link,
    }),
  },
  [REMOVE_INVITATION_COUNTERPARTY]: {
    icon: "/img/invitation.svg",
    langKeyHistory: "notification.user.invitation_to_counterparty_removed",
    langKeyNotificationAction: "notification.user.invitation_to_counterparty_removed",
    langKeyNotificationTitle: "notification.invitation.title",
    langTextParams: (data, $t) => ({
      counterpartyName: data.name,
    }),
  },
  [REMOVE_INVITATION_CONTRACT]: {
    icon: "/img/invitation.svg",
    langKeyHistory: "notification.user.invitation_to_contract_removed",
    langKeyNotificationAction: "notification.user.invitation_to_contract_removed",
    langKeyNotificationTitle: "notification.invitation.title",
    langTextParams: (data, $t) => ({
      contractName: data.name,
    }),
  },
};
