import axios from "axios";
import { api } from "~/router/apiConfig";

export function list(userId) {
  return axios.get(
    api.path("invitationCounterparty.index", { userId: userId })
  );
}

export function confirmCounterpartyInvitation(counterpartyId, userId) {
  return axios.put(
    api.path("invitationCounterparty.confirm", {
      counterpartyId: counterpartyId,
      userId: userId,
    })
  );
}

export function unConfirmCounterpartyInvitation(counterpartyId, userId) {
  return axios.put(
    api.path("invitationCounterparty.unConfirm", {
      counterpartyId: counterpartyId,
      userId: userId,
    })
  );
}

