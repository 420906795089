export const SITE_CREATING = "p0095";
export const SITE_CREATED = "s0095";
export const SITE_CREATE_ERROR = "e0095";
export const SITE_UPDATING = "p0096";
export const SITE_UPDATED = "s0096";
export const SITE_UPDATE_ERROR = "e0096";
export const SITE_TURNING_ON = "p0097";
export const SITE_TURNED_ON = "s0097";
export const SITE_TURN_ON_ERROR = "e0097";
export const SITE_TURNING_OFF = "p0098";
export const SITE_TURNED_OFF = "s0098";
export const SITE_TURN_OFF_ERROR = "e0098";
export const SITE_REMOVING = "p0099";
export const SITE_REMOVED = "s0099";
export const SITE_REMOVE_ERROR = "e0099";


export const site = {
  [SITE_CREATED]: {
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [SITE_UPDATED]: {
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.update"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [SITE_REMOVED]: {
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.delete"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [SITE_TURNED_ON]: {
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOn"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [SITE_TURNED_OFF]: {
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.action",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.action",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.turnOff"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
    hash: (id) => `#${id}`,
  },
  [SITE_CREATE_ERROR]: {
    isError: true,
    icon: "/img/site.svg",
    langKeyHistory: "notification.contract_entity.error",
    route: "contract.cabinet.site.index",
    langKeyNotificationAction: "notification.contract_entity.error",
    langKeyNotificationTitle: "notification.contract_entity.site.title",
    routeParams: (data) => ({
      contractName: data.contractName,
    }),
    langTextParams: (data, $t) => ({
      link: data.link,
      action: $t("notification.action.create"),
      entityType: $t("notification.contract.entity.site"),
      entityName: data.subjectName,
      autoDeleteMessage: $t("notification.contract_entity.auto_delete", {entityType: $t("notification.contract.entity.site")}),
      user: data.initiatorName
    }),
    langTitleParams: (data) => ({
      name: data.subjectName,
    }),
  },
}