import { index as getList } from "~/api/contract";
import * as types from "../mutation-types";
import { setDefaultContract, getDefaultContract } from "~/api/user";

/**
 * Initial state
 */
export const state = {
  contracts: null,
  isLoad: false,
  activeContract: null
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_CONTRACT](state, contracts) {
    state.list = contracts.data;
  },
  [types.SET_IS_LOAD_CONTRACT](state, status) {
    state.isLoad = status;
  },
  [types.SET_ACTIVE_CONTRACT](state, contractObject) {
    state.activeContract = contractObject;
    // window.localStorage.setItem(
    //   "activeContract",
    //   JSON.stringify(contractObject)
    // );
  }
};

/**
 * Actions
 */
export const actions = {
  fetchContracts({ commit }) {
    commit(types.SET_IS_LOAD_CONTRACT, true);

    getList().then((response) => {
      let { data } = response;
      commit(types.SET_IS_LOAD_CONTRACT, false);
      commit(types.SET_CONTRACT, data);
    });
  },
  // eslint-disable-next-line no-unused-vars
  async setActiveContract({ commit }, contractId) {
    console.log(contractId);
    try {
      const { data } = await setDefaultContract(contractId);
      if (data?.data) {
        commit(types.SET_ACTIVE_CONTRACT, data.data);
        return data.data;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  },
async fetchActiveContract({ commit }) {
    try {
      await getDefaultContract().then(({ data }) => {
        commit(types.SET_ACTIVE_CONTRACT, data?.data);
      });
    } catch (e) {
      // commit(types.FETCH_USER_FAILURE);
    }
  }
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  activeContract: (state) => state.activeContract,
  isLoad: (state) => state.isLoad
};
