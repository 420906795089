<template>
  <div class="base-card" :size="size" :loader="loader" :class="cardClasses">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";
.base-card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 50px #f4f7f4;
  border-radius: 8px;
  padding: 18px 24px;
  width: fit-content;
  //overflow: hidden;
  @include maxWidth768 {
    box-shadow: 0px 0px 20px #e9e9e9;
    padding: 16px !important;
  }
  &.loader {
    row-gap: 10px;
    display: flex;
    flex-flow: column;
  }
  &.p-0 {
    padding: 0 !important;
  }
  &.no-spacing {
    padding: 0 !important;
    margin: 0 !important;
  }
  &.overflow-hidden {
    overflow: hidden !important;
  }
  &.disabled {
    opacity: 0.6;
  }

  &.full-width {
    width: 100%;
  }
  &.card-sm {
    padding: 12px 20px;
  }
  &.card-lg {
    padding: 40px 32px;
  }
}
</style>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      return {
        "full-width": this.fullWidth,
        loader: this.loader,
        "card-sm": this.size === "sm",
        "card-md": this.size === "md",
        "card-lg": this.size === "lg",
      };
    },
  },
};
</script>
