import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const cacheRoutes = {
  path: "/cache",
  component: layout,
  name: "cache",
  children: [
    {
      path: "",
      component: () => import("~/views/Cache/Index"),
      name: "cache.index",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default cacheRoutes;
