import { list as getList } from "~/api/contractCertificate";
import * as types from "../mutation-types";
import store from "~/store";
import { ACTIVE, INACTIVE } from "~/constants/entityStatus";

/**
 * Initial state
 */
export const state = {
  isLoading: false,
  list: null,
  isInitialLoadPending: true,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_CERTIFICATES](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_CERTIFICATES](state, certificates) {
    state.list = certificates.data;
    state.isInitialLoadPending = false;
  },
};

/**
 * Actions
 */
export const actions = {
  async fetchContractCertificates({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_CERTIFICATES, true);

    let activeContractId = contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      try {
        let response = await getList(activeContractId);
        let { data } = response;
        commit(types.SET_CONTRACT_CERTIFICATES, data);
      } catch (error) {
      } finally {
        commit(types.SET_IS_LOADING_CONTRACT_CERTIFICATES, false);
      }
    } else {
      commit(types.SET_IS_LOADING_CONTRACT_CERTIFICATES, false);
    }
  },
};

/**
 * Getters
 */
export const getters = {

  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  isInitialLoadPending: (state) => state.isInitialLoadPending,
  listOfActive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === ACTIVE
      )
      : [],
  listOfInactive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === INACTIVE
      )
      : [],
  listOfStable: (state) =>
    state.list
      ? state.list.filter(
        (item) => (item.statusId === ACTIVE || item.statusId === INACTIVE)
      )
      : [],
};
