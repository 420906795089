import axios from "axios";
import { api } from "~/router/apiConfig";
import { idToLocaleKey } from "./lang";
import * as types from "../mutation-types";
import { showBroadcast } from "~/api/user";

/**
 * Initial state
 */
export const state = {
  user: null,
  notificationUnreadCount: 0,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_USER_INFO](state, user) {
    state.user = user;
  },
  [types.SET_USER_BROADCAST](state, data) {
    state.broadcasts = data;
  },
  [types.SET_LANGUAGE](state, language) {
    state.language = language;
    // Cookies.set("locale", language, { expires: 365 });
  },[types.SET_USER_UNREAD_NOTIFICATION](state,count) {
    state.notificationUnreadCount = count;
  },
};

/**
 * Actions
 */
export const actions = {
  async fetchUser({ commit, dispatch }) {
    try {
      const { data } = await axios.post(api.path("user.index"), null);
      commit(types.SET_USER_INFO, data.data);
      // Правильно вызываем действие изменения языка, предполагая, что оно определено в модуле language
      // Или, если мутация находится в том же модуле, используем commit(types.SET_LANGUAGE, data.data.settings.language);
      dispatch(
        "lang/setLocale",
        { locale: idToLocaleKey[data.data.settings.language] },
        { root: true }
      );

      return data.data;
    } catch (e) {
      // console.error(e);
    }
  },
  fetchBroadcast({ commit }) {
    showBroadcast()
      .then(({ data }) => {
        if (data.data) {
          commit(types.SET_USER_BROADCAST, data.data);
          commit(types.SET_USER_UNREAD_NOTIFICATION, data.countNotViewed);
        }
      })
      .catch((e) => console.error(e));
  },
};

/**
 * Getters
 */
export const getters = {
  user: (state) => state.user,
  language: (state) => state.language,
  broadcasts: (state) => state.broadcasts,
  notificationUnreadCount: (state) => state.notificationUnreadCount,
};
