import { isNullOrUndefined, isEmptyArray } from "../utils.js";
import i18n from "~/plugins/i18n";

const requiredIfValidator = (value, params) => {
  if (
    params === true ||
    isNullOrUndefined(value) ||
    isEmptyArray(value) ||
    value === i18n.global.t("validate.required_if")
  ) {
    return i18n.global.t("validate.required_if");
  }
  return !!String(value).trim().length || i18n.global.t("validate.required_if");
};

export { requiredIfValidator };
