import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("contractBackupDatabase.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, backupStorageId, request) {
  return axios.put(
    api.path("contractBackupStorage.update", {
      contractId: contractId,
      backupStorageId: backupStorageId,
    }),
    request
  );
}

export function list(contractId) {
  return axios.get(
    api.path("contractBackupStorage.index", { contractId: contractId })
  );
}

export function show(contractId, backupStorageId) {
  return axios.get(
    api.path("contractBackupStorage.show", {
      contractId: contractId,
      backupStorageId: backupStorageId,
    })
  );
}

export function remove(contractId, backupStorageId) {
  return axios.delete(
    api.path("contractBackupStorage.remove", {
      contractId: contractId,
      backupStorageId: backupStorageId,
    })
  );
}

export function turnOn(contractId, backupStorageId) {
  return axios.put(
    api.path("contractBackupStorage.turnOn", {
      contractId: contractId,
      backupStorageId: backupStorageId,
    })
  );
}

export function turnOff(contractId, backupStorageId) {
  return axios.put(
    api.path("contractBackupStorage.turnOff", {
      contractId: contractId,
      backupStorageId: backupStorageId,
    })
  );
}
