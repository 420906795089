import router from "~/router";

export default {
  beforeMount(el, binding) {
    el.setAttribute('href', '#');
    el.addEventListener('click', e => {
      e.preventDefault();
      console.log(binding.value);
      if (binding.value) {
        router.push(binding.value);
      }
    });
  }
};