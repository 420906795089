import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import store from "~/store";

export const SUPPORT_LOCALES = ["en", "ua"];

const i18n = createI18n({ locale: "ua", globalInjection: true });

export function setI18nLanguage(locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(locale) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `~/language/${locale}.json`
  );

  i18n.global.setLocaleMessage(locale, messages.default);
  setI18nLanguage(locale);

  store.dispatch("lang/setLocale", { locale: locale });

  return nextTick();
}
(async function () {
  await loadLocaleMessages(store.getters["lang/locale"]);
})();

export default i18n;
