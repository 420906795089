<template>
  <transition name="header-profile-transition">
    <div class="header_profile_menu" v-show="show">
      <div class="header_profile_menu_top">{{ $t("notifications") }}</div>
      <div
        v-if="broadcasts && broadcasts.length"
        class="header_profile_menu_notification"
        style="overflow-y: auto; max-height: 300px"
      >
        <ItemNotification
          v-for="(broadcast, index) in broadcasts"
          :key="index"
          :type="getIsError(broadcast) ? 'error' : 'info'"
          :type-id="broadcast.typeId"
          :is-read="broadcast.isViewed"
          :message="getText(broadcast)"
          :timedAt="broadcast.timedAt"
          :icon="getIcon(broadcast)"
          :title="getTitle(broadcast)"
        ></ItemNotification>{{key}}
      </div>
      <div v-else class="notifications-empty">
        {{$t('"head_notification.empty"')}}
      </div>
    </div>
  </transition>
</template>

<script>

import ItemNotification from "./ItemNotification.vue";
import historyMixin from "~/mixins/history";
export default {
  mixins: [historyMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemNotification,
  },
  data() {
    return {};
  },
  methods: {},
  mounted: function () {},
  computed: {
    broadcasts: function () {
      return this.$store.getters["user/broadcasts"];
    },
    user: function () {
      return this.$store.getters["user/user"];
    },
    activeContract: function () {
      return this.$store.getters["contract/activeContract"];
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";
.notifications-empty {
  padding: 20px;
}
.block-message {
  background: #f4f7f4;
  margin-bottom: 5px;
  cursor: pointer;

  .message {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #009440;
    width: 100% !important;
  }
}
</style>
