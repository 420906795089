<template>
  <div class="base-mobile-option-footer">
    <img :src="`/image/icons/${icon}.svg`" v-if="icon" alt="icon" />
    <small class="text-base-small-2 grey-4-color">{{ text }}</small>
    <h4 class="text-base-span-md">{{ value }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    value: String,
  },
};
</script>

<style scoped>
.base-mobile-option-footer {
  display: flex;
  align-items: center;
  padding: 18px 20px;
  border-top: 1px solid #e5e6e5;
  column-gap: 8px;
}
</style>
