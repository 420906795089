const confirmedValidator = (value, params) => {
  if (!value && !params) {
    return true;
  }
  
  
  return String(value) === String(params) || "validate.is_confirmed";
};

export { confirmedValidator };
