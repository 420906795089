import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("contractCustomerDatabase.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, customerDatabaseId, request) {
  return axios.put(
    api.path("contractCustomerDatabase.update", {
      contractId: contractId,
      customerDatabaseId: customerDatabaseId,
    }),
    request
  );
}

export function remove(contractId, customerDatabaseId) {
  return axios.delete(
    api.path("contractCustomerDatabase.delete", {
      contractId: contractId,
      customerDatabaseId: customerDatabaseId,
    })
  );
}

export function list(contractId) {
  return axios.get(
    api.path("contractCustomerDatabase.index", { contractId: contractId })
  );
}

export function show(contractId, customerDatabaseId) {
  return axios.get(
    api.path("contractCustomerDatabase.show", {
      contractId: contractId,
      customerDatabaseId: customerDatabaseId,
    })
  );
}

export function turnOn(contractId, customerDatabaseId, request) {
  return axios.put(
    api.path("contractCustomerDatabase.turnOn", {
      contractId: contractId,
      customerDatabaseId: customerDatabaseId,
    }),
    request
  );
}

export function turnOff(contractId, customerDatabaseId, request) {
  return axios.put(
    api.path("contractCustomerDatabase.turnOff", {
      contractId: contractId,
      customerDatabaseId: customerDatabaseId,
    }),
    request
  );
}
