import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const logsRoutes = {
  path: "/logs",
  component: layout,
  name: "logs",
  children: [
    {
      path: "",
      component: () => import("~/views/Logs/Index"),
      name: "logs.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "",
      component: () => import("~/views/Logs/Details"),
      name: "logs.details",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default logsRoutes;
