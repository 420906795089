import { isEmpty } from "../utils.js";
import * as x509 from "@peculiar/x509";

const certificateValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  try {
    const cert = new x509.X509Certificate(value);

    if (cert?.serialNumber) {
      return true;
    }

    return "validate.invalid certificate";
  } catch (e) {
    return "validate.invalid certificate";
  }
};

export { certificateValidator };
