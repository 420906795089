import { list as getList } from "~/api/userHistory";
import * as types from "../mutation-types";

export const state = {
  list: null,
  meta: null,
  isLoading: false,
};

export const mutations = {
  [types.SET_IS_LOADING_USER_HISTORY](state, status) {
    state.isLoading = status;
  },
  [types.SET_USER_HISTORY](state, userHistories) {
    state.list = userHistories;
  },
  [types.SET_USER_HISTORY_META](state, meta) {
    console.log(meta);
    state.meta = meta;
  },
};

export const actions = {
  fetchUserHistories({ commit }, params) {
    commit(types.SET_IS_LOADING_USER_HISTORY, true);
      getList(params).then((response) => {
        console.log(response);
        let { data } = response;
        commit(types.SET_USER_HISTORY, data.data);
        commit(types.SET_USER_HISTORY_META, data.meta);
        commit(types.SET_IS_LOADING_USER_HISTORY, false);
      });
  },
};

export const getters = {
  list: (state) => state.list,
  meta: (state) => state.meta,
  isLoading: (state) => state.isLoading,
};
