import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(contractId, request) {
  return axios.post(
    api.path("backup.storagePlan.store", { contractId: contractId }),
    request
  );
}

export function update(contractId, storagePlanId, request) {
  return axios.put(
    api.path("backup.storagePlan.update", {
      contractId: contractId,
      storagePlanId: storagePlanId,
    }),
    request
  );
}

export function list(contractId) {
  return axios.get(
    api.path("backup.storagePlan.index", { contractId: contractId })
  );
}

export function show(contractId, storagePlanId) {
  return axios.get(
    api.path("backup.storagePlan.show", {
      contractId: contractId,
      storagePlanId: storagePlanId,
    })
  );
}

export function remove(contractId, storagePlanId) {
  return axios.delete(
    api.path("backup.storagePlan.remove", {
      contractId: contractId,
      storagePlanId: storagePlanId,
    })
  );
}

export function turnOn(contractId, storagePlanId) {
  return axios.put(
    api.path("backup.storagePlan.turnOn", {
      contractId: contractId,
      storagePlanId: storagePlanId,
    })
  );
}

export function turnOff(contractId, storagePlanId) {
  return axios.put(
    api.path("backup.storagePlan.turnOff", {
      contractId: contractId,
      storagePlanId: storagePlanId,
    })
  );
}
