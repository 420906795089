<template>
  <div>
    <section class="autorization-section">
      <div class="autorization_wrapp">
        <div class="autorization_preview">
          <img src="/image/autorization_preview.png" alt="" />
        </div>
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
import { csrf } from "~/api/auth";

export default {

  async created() {
    await csrf();
  },

  methods: {
  },
};
</script>

