import axios from "axios";
import { api } from "~/router/apiConfig";

export function create(request) {
  return axios.post(api.path("contract.store"), request);
}

export function deleteContract(contractId) {
  return axios.delete(api.path("contract.delete", { contractId: contractId }));
}

export function turnOnContract(contractId, request) {
  return axios.put(
    api.path("contract.turnOn", { contractId: contractId }),
    request
  );
}

export function turnOffContract(contractId, request) {
  return axios.put(
    api.path("contract.turnOff", { contractId: contractId }),
    request
  );
}

export function updateContract(contractId, request) {
  return axios.put(
    api.path("contract.update", { contractId: contractId }),
    request
  );
}

export function index(request) {
  return axios.get(api.path("contract.index"), request);
}

export function addInvitationUser(request, contractId) {
  return axios.post(
    api.path("contractInvitation.store", { contractId: contractId }),
    request
  );
}

export function updateInvitationUser(
  request,
  contractId,
  contractInvitationId
) {
  return axios.put(
    api.path("contractInvitation.update", {
      contractId: contractId,
      contractInvitationId: contractInvitationId,
    }),
    request
  );
}

export function userListInvitation(userId) {
  return axios.get(api.path("contractInvitation.userList", { userId: userId }));
}

export function confirmContractInvitation(contractId, userId) {
  return axios.put(
    api.path("contractInvitation.confirm", {
      contractId: contractId,
      userId: userId,
    })
  );
}
export function unConfirmContractInvitation(contractId, userId) {
  return axios.put(
    api.path("contractInvitation.unConfirm", {
      contractId: contractId,
      userId: userId,
    })
  );
}

export function deleteInvitationUser(contractId, contractInvitationId) {
  return axios.delete(
    api.path("contractInvitation.remove", {
      contractId: contractId,
      contractInvitationId: contractInvitationId,
    })
  );
}

export function updateContractUser(contractId, userId, request) {
  return axios.put(
    api.path("contractUser.update", {
      contractId: contractId,
      userId: userId,
    }),
    request
  );
}
export function deleteContractUser(contractId, userId, request) {
  return axios.delete(
    api.path("contractUser.delete", {
      contractId: contractId,
      userId: userId,
    }),
    request
  );
}
