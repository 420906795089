import { success as successStatus } from "~/router/apiResponseStatus";
import debounce from "lodash.debounce";

const baseMixin = {
  data() {
    return {
      statuses: {
        isLoading: false,
        isFirstLoad: true,
      },
      minLoadTime: 900,
      startTime: null,
      minLoadTimer: null,
    };
  },
  computed: {
    isLoading() {
      return this.statuses.isLoading;
    },
    isFirstLoad() {
      return this.statuses.isFirstLoad;
    },
  },
  methods: {
    startLoading() {
      this.enabledLoading();
      this.startTime = Date.now();
    },
    finishLoading() {
      const elapsedTime = Date.now() - this.startTime;
      const remainingTime = Math.max(this.minLoadTime - elapsedTime, 0);

      setTimeout(() => {
        this.disabledLoading();
        this.disabledFirstLoad();
      }, remainingTime);
    },
    async loadData(callback) {
      this.startLoading();
      try {
        await callback();
      } catch (error) {
      } finally {
        this.finishLoading();
      }
    },
    disabledFirstLoad() {
      this.statuses.isFirstLoad = false;
    },
    enabledLoading() {
      this.statuses.isLoading = true;
    },
    disabledLoading() {
      this.statuses.isLoading = false;
    },
    isSuccessStatus(data) {
      return data?.status === successStatus;
    },
    setSubmitErrors(errors, formName) {
      let errorsData = errors.response.data.errors;

      Object.keys(errorsData).forEach((key) => {
        let error = {};
        error[key] = errorsData[key][0];
        this.$refs[formName].setErrors(error);
      });
    },
  },
};

export default baseMixin;
