<template>
  <BaseInvitation>
    <div class="cabinet_invitation">
      <div class="text-h6 mt-32 mb-20">{{ $t("invitation.requests") }}</div>
      <ContractInvitation
        @refresh="getContractInvitations"
        v-if="contractInvitation"
        :invitations="contractInvitation"
        :user-id="user.id"
      >
      </ContractInvitation>
    </div>
    <div v-if="!contractInvitation && isLoading">
      <BaseItemLoader
        :loaderDistribution="['w-9/12', 'w-3/12']"
        v-for="index in 3"
        :key="index"
      />
    </div>
  </BaseInvitation>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInvitation from "./components/BaseInvitation.vue";
import entityMixin from "~/mixins/entity";
import { isMobile } from "~/mixins/isMobile";
import baseMixin from "~/mixins/base";
import ContractInvitation from "~/views/Invitation/components/ContractInvitation.vue";

export default {
  name: "InfoProfile",
  components: {
    ContractInvitation,
    BaseInvitation,
  },
  mixins: [entityMixin, isMobile, baseMixin],

  data() {
    return {
      isLoad: true,
    };
  },
  created() {
    this.loadData(() => {
      this.$store.dispatch(
        "userInvitationContract/fetchUserInvitationContracts",
        this.user.id
      );
    });
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      contractInvitation: "userInvitationContract/list",
    }),
  },
  methods: {
    getContractInvitations: function () {
      this.loadData(() => {
        this.$store.dispatch(
          "userInvitationContract/fetchUserInvitationContracts",
          this.user.id
        );
      });
    },
  },
};
</script>
