import { list as getList } from "~/api/contractDatabase";
import * as types from "../mutation-types";
import store from "../../store";
import { ACTIVE, INACTIVE } from "~/constants/entityStatus";

/**
 * Initial state
 */
export const state = {
  list: null,
  typesAndVersions: {
    mysql: [
      { id: 1, version: '5.7' },
      { id: 2, version: '8.0' },
    ],
    postgresql: [
      { id: 3, version: '12' },
      { id: 4, version: '13' },
    ],
  },
  isLoading: false,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_DATABASE](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_DATABASE](state, contractDatabase) {
    state.list = contractDatabase.data;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchContractDatabases({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_DATABASE, true);
    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_DATABASE, data);
        commit(types.SET_IS_LOADING_CONTRACT_DATABASE, false);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  typesAndVersions: (state) => state.typesAndVersions,
  listOfActive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === ACTIVE
      )
      : [],
  listOfInactive: (state) =>
    state.list
      ? state.list.filter(
        (item) => item.statusId === INACTIVE
      )
      : [],
  listOfStable: (state) =>
    state.list
      ? state.list.filter(
        (item) => (item.statusId === ACTIVE || item.statusId === INACTIVE)
      )
      : [],
};
