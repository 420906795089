<template>
  <div :class="['cabinet_container mx-auto max-w-7xl w-full px-4 sm:px-6 lg:px-8', containerClass]">
    <div class="cabinet_container_top">
      <div class="text-base-h6">{{ $t("invitations") }}</div>
    </div>

    <BaseTabs
      v-model="personalSettings"
      small
      class="mt-20"
      :tabs="[
        {
          text: $t('name.counterparties'),
          value: 'counterparties',
          link: 'invitation.counterparties',
        },
                {
          text: $t('name.contracts'),
          value: 'contracts',
          link: 'invitation.contracts',
        },
      ]"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseInvitation",
  data() {
    return {
      personalSettings: "contracts",
    };
  },
  computed: {
    containerClass: function () {
      return this.personalSettings;
    },
  },
};
</script>
