<template>
  <div
    :class="[
      'cabinet_container mx-auto max-w-7xl w-full px-4 sm:px-6 lg:px-8',
      containerClass,
    ]"
  >
    <div class="cabinet_container_top">
      <div class="text-base-h6">{{ $t("history") }}</div>
    </div>
    <BaseTabs
      v-model="personalSettings"
      small
      class="mt-20"
      :tabs="[
        {
          text: $t('name.user_chronicles'),
          value: 'user',
          link: 'history.user.index',
        },
        {
          text: $t('name.counterparties_chronicles'),
          value: 'counterparties',
          link: 'history.counterparties.index',
        },
      ]"
    />
    <div class="overflow-x-auto overflow-y-clip">
      <div class="min-w-[980px]">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseHistory",
  data() {
    return {
      personalSettings: "user"
    };
  },
  computed: {
    containerClass: function() {
      return this.personalSettings;
    }
  }
};
</script>
