import { isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const maxNumberValidator = (value, max) => {
  if (isEmpty(value)) {
    return true;
  }

  const numberValue = Number(value);
  return (
    !isNaN(numberValue) &&
    numberValue <= max ||
    i18n.global.t("validate.the_number_must_not_be_greater_than", {
      max: max,
    })
  );
};

export { maxNumberValidator };
