import { CONTRACT_HISTORY_TYPE_DATABASE_CREATE } from "~/constants/historyTypeConfigs";

const contractHistoryType = {
  data() {
    return {
      isMobile: null,
      storyProcessors: {
        CONTRACT_HISTORY_TYPE_DATABASE_CREATE: this.processorDatabaseCreate,
        // contractHistoryTypeDatabaseUpdate: this.processorDatabaseUpdate,
        // contractHistoryTypeDatabaseTurnOn: this.processorDatabaseTurnOn,
        // contractHistoryTypeDatabaseTurnOff: this.processorDatabaseTurnOff,
        // contractHistoryTypeDatabaseRemove: this.processorDatabaseRemove,
      },
    };
  },
  methods: {
    getContractHistoryText(type, data) {
      CONTRACT_HISTORY_TYPE_DATABASE_CREATE;

      const processor = this.storyProcessors[type];
      if (processor) {
        return processor(data) + " якийсь вірний опис";
      }

      return data.name + " Якийсь вірний опис";
    },
    processorDatabaseCreate(data) {
      return data.name;
    },
    processorDatabaseUpdate(data) {
      return data.name;
    },
    processorDatabaseTurnOn(data) {
      return data.name;
    },
    processorDatabaseTurnOff(data) {
      return data.name;
    },
    processorDatabaseRemove(data) {
      return data.name;
    },
  },
};

export default contractHistoryType;
