/**
 * auth.js
 */
export const SET_USER = "SET_USER";
export const SET_USER_BROADCAST = "SET_USER_BROADCAST";
export const SET_USER_UNREAD_NOTIFICATION = "SET_USER_UNREAD_NOTIFICATION";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const SET_TOKEN = "SET_TOKEN";
// export const UPDATE_TICKER = 'UPDATE_TICKER'
// export const ADD_COIN_PAIR = 'ADD_COIN_PAIR'
// export const REMOVE_COIN_PAIR = 'REMOVE_COIN_PAIR'

/**
 * lang.js
 */
export const SET_LOCALE = "SET_LOCALE";

/**
 * user.js
 */
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_LANGUAGE = "SET_LANGUAGE";

/**
 * other.js
 */
export const SET_TIME_ZONES = "SET_TIME_ZONES";

/**
 * counterparties.js
 */
export const SET_COUNTERPARTIES = "SET_COUNTERPARTIES";
export const SET_IS_LOADING_SET_COUNTERPARTIES = "SET_IS_LOADING_SET_COUNTERPARTIES";

/**
 * contact.js
 */
export const SET_CONTRACT = "SET_CONTRACT";
export const SET_ACTIVE_CONTRACT = "SET_ACTIVE_CONTRACT";
export const SET_IS_LOAD_CONTRACT = "SET_IS_LOAD_CONTRACT";

/**
 * contactCustomer.js
 */

export const SET_CONTRACT_CUSTOMER = "SET_CONTRACT_CUSTOMER";
export const SET_IS_LOADING_CONTRACT_CUSTOMER =
  "SET_IS_LOADING_CONTRACT_CUSTOMER";
/**
 * contactCustomerDatabase.js
 */

export const SET_CONTRACT_CUSTOMER_DATABASE = "SET_CONTRACT_CUSTOMER_DATABASE";
export const SET_IS_LOADING_CONTRACT_CUSTOMER_DATABASE =
  "SET_IS_LOADING_CONTRACT_CUSTOMER_DATABASE";

/**
 * contactStorage.js
 */
export const SET_CONTRACT_STORAGE = "SET_CONTRACT_STORAGE";
export const SET_IS_LOADING_CONTRACT_STORAGE =
  "SET_IS_LOADING_CONTRACT_STORAGE";

/**
 * contactStorage.js
 */
export const SET_CONTRACT_DATABASE = "SET_CONTRACT_DATABASE";
export const SET_IS_LOADING_CONTRACT_DATABASE =
  "SET_IS_LOADING_CONTRACT_DATABASE";

/**
 * contactTransactions.js
 */
export const SET_CONTRACT_TRANSACTIONS = "SET_CONTRACT_DATABASE";
export const SET_IS_LOADING_CONTRACT_TRANSACTIONS =
  "SET_IS_LOADING_CONTRACT_DATABASE";

/**
 * domainStorage.js
 */
export const SET_CONTRACT_DOMAINS = "SET_CONTRACT_DOMAINS";
export const SET_IS_LOADING_CONTRACT_DOMAINS =
  "SET_IS_LOADING_CONTRACT_DOMAINS";
export const SET_DOMAINS_TYPES = "SET_DOMAINS_TYPES";
export const SET_IS_LOADING_DOMAINS_TYPES = "SET_IS_LOADING_DOMAINS_TYPES";
export const SET_DOMAINS_ZONES_SELECT = "SET_DOMAINS_ZONES_SELECT";
export const SET_IS_LOADING_DOMAINS_ZONES_SELECT =
  "SET_IS_LOADING_DOMAINS_ZONES_SELECT";

/**
 * siteStorage.js
 */
export const SET_CONTRACT_SITES = "SET_CONTRACT_SITES";
export const SET_IS_LOADING_CONTRACT_SITES = "SET_IS_LOADING_CONTRACT_SITES";

/**
 * certificateStorage.js
 */
export const SET_IS_LOADING_CERTIFICATE_PUBLISHERS =
  "SET_IS_LOADING_CERTIFICATE_PUBLISHERS";
export const SET_IS_LOADING_CERTIFICATE_ALGORITHMS =
  "SET_IS_LOADING_CERTIFICATE_ALGORITHMS";
export const SET_CERTIFICATE_ALGORITHMS = "SET_CERTIFICATE_ALGORITHMS";
export const SET_CERTIFICATE_PUBLISHERS = "SET_CERTIFICATE_PUBLISHERS";
export const SET_CONTRACT_CERTIFICATES = "SET_CONTRACT_CERTIFICATES";
export const SET_IS_LOADING_CONTRACT_CERTIFICATES =
  "SET_IS_LOADING_CONTRACT_CERTIFICATES";

/**
 * contractSpendingChart.js
 */
export const SET_SERIES_CONTRACT_SPENDING_CHART =
  "SET_SERIES_CONTRACT_SPENDING_CHART";
export const SET_SERIES_CONTRACT_SPENDING_BAR_INFO_CHART =
  "SET_SERIES_CONTRACT_SPENDING_WEEK_BAR_INFO_CHART";
export const SET_IS_LOADING_SERIES_CONTRACT_SPENDING_CHART =
  "SET_IS_LOADING_SERIES_CONTRACT_SPENDING_CHART";

/**
 * contractBalance.js
 */
export const SET_CONTRACT_BALANCE_AMOUNT = "SET_CONTRACT_BALANCE_AMOUNT";
export const SET_IS_LOADING_CONTRACT_BALANCE_AMOUNT =
  "SET_IS_LOADING_CONTRACT_BALANCE_AMOUNT";

/**
 * contractBalanceReplenishmentHistory.js.js
 */
export const SET_CONTRACT_BALANCE_REPLENISHMENT_LIST =
  "SET_CONTRACT_BALANCE_REPLENISHMENT_LIST";
export const SET_IS_LOADING_CONTRACT_BALANCE_REPLENISHMENT_LIST =
  "SET_IS_LOADING_CONTRACT_BALANCE_REPLENISHMENT_LIST";
export const SET_CONTRACT_BALANCE_REPLENISHMENT_LIST_GROUP_USER =
  "SET_CONTRACT_BALANCE_REPLENISHMENT_LIST_GROUP_USER";
export const SET_IS_LOADING_CONTRACT_BALANCE_REPLENISHMENT_LIST_GROUP_USER =
  "SET_IS_LOADING_CONTRACT_BALANCE_REPLENISHMENT_LIST_GROUP_USER";

/**
 * contractSettings.js
 */
export const SET_IS_LOADING_CONTRACT_SETTINGS =
  "SET_IS_LOADING_CONTRACT_SETTINGS";
export const SET_CONTRACT_SETTINGS = "SET_CONTRACT_SETTINGS";
export const SET_CONTRACT_SETTINGS_DATA = "SET_CONTRACT_SETTINGS_DATA";

/**
 *
 * contractBackupDatabasePlan
 */
export const SET_CONTRACT_BACKUP_DATABASE_PLAN =
  "CONTRACT_BACKUP_DATABASE_PLAN";
export const SET_IS_LOADING_CONTRACT_BACKUP_DATABASE_PLAN =
  "IS_LOADING_CONTRACT_BACKUP_DATABASE_PLAN";

/**
 *
 * contractBackupStorage
 */
export const SET_CONTRACT_BACKUP_STORAGE = "CONTRACT_BACKUP_STORAGE";
export const SET_IS_LOADING_CONTRACT_BACKUP_STORAGE =
  "IS_LOADING_CONTRACT_BACKUP_STORAGE";

/**
 *
 * contractBackupStoragePlan
 */
export const SET_CONTRACT_BACKUP_STORAGE_PLAN = "CONTRACT_BACKUP_STORAGE_PLAN";
export const SET_IS_LOADING_CONTRACT_BACKUP_STORAGE_PLAN =
  "IS_LOADING_CONTRACT_BACKUP_STORAGE_PLAN";

/**
 *
 * contractBackupDatabase
 */
export const SET_CONTRACT_BACKUP_DATABASE = "CONTRACT_BACKUP_DATABASE";
export const SET_IS_LOADING_CONTRACT_BACKUP_DATABASE =
  "IS_LOADING_CONTRACT_BACKUP_DATABASE";

/**
 *
 * contractDefaultProcessorPhpParams
 */
export const SET_CONTRACT_DEFAULT_PROCESSOR_PHP_PARAMS =
  "CONTRACT_DEFAULT_PROCESSOR_PHP_PARAMS";
export const SET_IS_LOADING_DEFAULT_CONTRACT_PROCESSOR_PHP_PARAMS =
  "IS_LOADING_CONTRACT_DEFAULT_PROCESSOR_PHP_PARAMS";


/**
 *
 * contractCustomProcessorPhpParams
 */
export const SET_CONTRACT_CUSTOM_PROCESSOR_PHP_PARAMS =
  "CONTRACT_CUSTOM_PROCESSOR_PHP_PARAMS";
export const SET_IS_LOADING_CUSTOM_CONTRACT_PROCESSOR_PHP_PARAMS =
  "IS_LOADING_CONTRACT_CUSTOM_PROCESSOR_PHP_PARAMS";


/**
 *
 * contractHistory
 */
export const SET_CONTRACT_HISTORY = "CONTRACT_HISTORY";
export const SET_CONTRACT_HISTORY_META = "CONTRACT_HISTORY_META";
export const SET_IS_LOADING_CONTRACT_HISTORY = "IS_LOADING_CONTRACT_HISTORY";

/**
 *
 * userHistory
 */
export const SET_USER_HISTORY = "USER_HISTORY";
export const SET_USER_HISTORY_META = "USER_HISTORY_META";
export const SET_IS_LOADING_USER_HISTORY = "IS_LOADING_USER_HISTORY";

/**
 *
 * counterparty History
 */
export const SET_COUNTERPARTY_HISTORY = "COUNTERPARTY_HISTORY";
export const SET_COUNTERPARTY_HISTORY_META = "COUNTERPARTY_HISTORY_META";
export const SET_IS_LOADING_COUNTERPARTY_HISTORY ="IS_LOADING_COUNTERPARTY_HISTORY";

/**
 *
 * user Referral Withdrawals
 */
export const SET_USER_REFERRAL_WITHDRAWALS = "USER_REFERRAL_WITHDRAWALS";
export const SET_USER_REFERRAL_WITHDRAWALS_META =
  "USER_REFERRAL_WITHDRAWALS_META";
export const SET_IS_LOADING_USER_REFERRAL_WITHDRAWALS =
  "IS_LOADING_USER_REFERRAL_WITHDRAWALS";


/**
 *
 * user Referral Withdrawals
 */
export const SET_USER_INVITATION_COUNTERPARTIES = "USER_INVITATION_COUNTERPARTIES";
export const SET_IS_LOAD_USER_INVITATION_COUNTERPARTIES = "IS_LOAD_USER_INVITATION_COUNTERPARTIES";


export const SET_USER_INVITATION_CONTRACT = "USER_INVITATION_CONTRACT";
export const SET_IS_LOAD_USER_INVITATION_CONTRACT = "IS_LOAD_USER_INVITATION_CONTRACT";


/**
 *
 * user Referral Counterparties.vue
 */
export const SET_USER_REFERRAL_COUNTERPARTIES = "USER_REFERRAL_COUNTERPARTIES";
export const SET_USER_REFERRAL_COUNTERPARTIES_META =
  "USER_REFERRAL_COUNTERPARTIES_META";
export const SET_IS_LOADING_USER_REFERRAL_COUNTERPARTIES =
  "IS_LOADING_USER_REFERRAL_COUNTERPARTIES";

/**
 *
 * user Referral Program
 */
export const SET_USER_REFERRAL_PROGRAM = "USER_REFERRAL_PROGRAM";
export const SET_IS_LOADING_USER_REFERRAL_PROGRAM =
  "IS_LOADING_USER_REFERRAL_PROGRAM";

/**
 *
 * user Referral Users
 */
export const SET_USER_REFERRAL_USERS = "USER_REFERRAL_USERS";
export const SET_USER_REFERRAL_USERS_META =
  "USER_REFERRAL_USERS_META";
export const SET_IS_LOADING_USER_REFERRAL_USERS =
  "IS_LOADING_USER_REFERRAL_USERS";


// DOMAIN ZONES
export const SET_IS_LOADING_DOMAIN_ZONES = "IS_LOADING_DOMAIN_ZONES";
export const SET_DOMAIN_ZONES = "DOMAIN_ZONES";


// user setting notification contracts
export const SET_IS_LOADING_USER_SETTINGS_NOTIFICATION_CONTRACT = 'SET_IS_LOADING_USER_SETTINGS_NOTIFICATION_CONTRACT';
export const SET_USER_SETTINGS_NOTIFICATION_CONTRACT = 'SET_USER_SETTINGS_NOTIFICATION_CONTRACT';
export const SET_USER_SETTINGS_NOTIFICATION_CONTRACT_META = 'SET_USER_SETTINGS_NOTIFICATION_CONTRACT_META';

// contract dashboard
export const SET_IS_LOADING_CONTRACT_DASHBOARD_CONTRACT_MAP = 'SET_IS_LOADING_CONTRACT_DASHBOARD_CONTRACT_MAP';
export const SET_CONTRACT_DASHBOARD_CONTRACT_MAP = 'SET_CONTRACT_DASHBOARD_CONTRACT_MAP';

// contract metrics
export const SET_IS_LOADING_CONTRACT_GRAFANA_METRIC = 'SET_IS_LOADING_CONTRACT_GRAFANA_METRIC';
export const SET_CONTRACT_GRAFANA_METRIC = 'SET_CONTRACT_GRAFANA_METRIC';


//payments
export const SET_IS_LOADING_CONTRACT_PAYMENT_LIST = 'SET_IS_LOADING_CONTRACT_PAYMENT_LIST';
export const SET_CONTRACT_PAYMENT_LIST = 'SET_CONTRACT_PAYMENT_LIST';