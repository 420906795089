import {
  list as getList,
} from "~/api/contractDomain";
import * as types from "../mutation-types";
import store from "../../store";

/**
 * Initial state
 */
export const state = {
  list: null,
  isLoading: null,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_CONTRACT_DOMAINS](state, status) {
    state.isLoading = status;
  },
  [types.SET_CONTRACT_DOMAINS](state, domainList) {
    state.list = domainList.data;
  }
};

/**
 * Actions
 */
export const actions = {
  fetchContractDomains({ commit }, contractId = null) {
    commit(types.SET_IS_LOADING_CONTRACT_DOMAINS, true);

    let activeContractId =
      contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      getList(activeContractId).then((response) => {
        let { data } = response;
        commit(types.SET_CONTRACT_DOMAINS, data);
        commit(types.SET_IS_LOADING_CONTRACT_DOMAINS, false);
      });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
};
