<template>
  <img
    v-if="svgIcon && svgIcon.length"
    ref="icon"
    class="icon"
    :src="svgIcon"
  />
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
    },
  },
  data() {
    return {
      svgIcon: "",
    };
  },
  watch: {
    icon: function () {
      this.setSvgIcon();
    },
    active: function () {
      this.setSvgIcon();
    },
  },
  created() {
    this.setSvgIcon();
  },
  methods: {
    async setSvgIcon() {
      if (this.icon === "") {
        throw new Error("The name of the icon is missing");
      }
      if (this.icon && this.icon.length) {
        let file;
        if (this.active) {
          file = await `/image/icons/${this.icon}-active.svg`;
        } else {
          file = await `/image/icons/${this.icon}.svg`;
        }
        try {
          this.svgIcon = file;
        } catch (e) {
          throw new Error("The name of the icon is missing");
        }
      }
    },
  },
};
</script>
