import { isEmpty } from "../utils.js";
import i18n from "~/plugins/i18n";

const minNumberValidator = (value, min) => {
  if (isEmpty(value)) {
    return true;
  }

  const numberValue = Number(value);
  return (
    !isNaN(numberValue) &&
    numberValue >= min ||
    i18n.global.t("validate.the_number_must_not_be_less_than", {
      min: min,
    })
  );
};

export { minNumberValidator };
