export const COUNTERPARTY = 1;
export const COUNTERPARTY_INVITATION = 2;
export const USER_INVITATION_COUNTERPARTY = 3;
export const CONTRACT = 11;
export const CONTRACT_INVITATION = 12;
export const USER_INVITATION_CONTRACT = 13;
export const CONTRACT_CUSTOMER_DATABASE = 51;
export const CONTRACT_CUSTOMER_STORAGE = 52;
export const CONTRACT_DATABASE = 53;
export const CONTRACT_STORAGE = 54;
export const CONTRACT_CERTIFICATE = 55;
export const CONTRACT_SITE = 59;