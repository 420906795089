<template>
  <date-picker
    locale="en"
    :min-date="minDate"
    @input="changed()"
    class="base-input-wrapper"
    :class="[{ 'error-input': validation.hasErrors }, inputWrapperClass]"
    :popover="{ visibility: 'focus' }"
    v-model="data"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :class="[
          { 'validation-alert': isShowValidationIcon },
          { 'error-input': validation.hasErrors === true },
          { 'success-input': validation.hasErrors === false && modelValue },
        ]"
        @input="changed()"
        @change="changed()"
        width="100%"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </date-picker>
  <div v-if="validation.errors && validation.errors.length > 0">
    <ValidationInputMessage
      :key="index"
      v-for="(error, index) in validation.errors"
      class="invalid-feedback show"
    >
      {{ $t(error) }}
    </ValidationInputMessage>
  </div>
</template>

<script>
import checkValidationMixin from "~/mixins/checkValidation";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";

export default {
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    minDate: {
      type: [Object],
      default: null,
    },
    placeholder: {
      type: [String, Number, Object, Boolean],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modelValue: [String, Number, Object],
    class: [String],
    name: {
      type: String,
      required: false,
    },
    borderTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [checkValidationMixin],
  created() {},
  data() {
    return {
      inputValue: null,
      autoDefaultCountry: false,
      data: this.modelValue,
      phoneObject: null,
      //TODO разобраться как мержиться миксины. И сделатьк красиво. что бы можно было добавлять
      // только необходимые правила,но не дублировать те которые есть в миксине.
      // validation: {
      //   rules: {
      //     required: required,
      //     phone: this.checkPhone,
      //   },
      // },
    };
  },
  computed: {
    inputClass() {
      return {
        "base-input": true,
        "error-input": this.validation.hasErrors === true,
        [`size-${this.size}`]: true,
        disabled: this.disabled,
        error: !this.valid,
      };
    },
    inputWrapperClass() {
      return {
        [`size-${this.size}`]: true,
        "state-disabled": this.disabled,
        "full-width": this.fullWidth,
        "border-transparent": this.borderTransparent,
      };
    },
  },
  create() {},
  methods: {
    input: function (event) {
      let value = event.target.value;

      this.$emit("update:modelValue", value);

      this.inputValue = value;
    },
    getValue: function () {
      if (this.data) {
        return this.data.toISOString();
      }
      return null;
    },
    changed: function () {
      this.makeValidation();
    },
  },
  watch: {
    modelValue: function (value) {
      this.data = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/design/mainIncludes";

.custom-wrapper {
  overflow: hidden;
  background: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  box-sizing: border-box;

  background: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  box-sizing: border-box;
}

.base-input-wrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  background: #fff;
  border: 1px solid $grey-2;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  column-gap: 2px;
  flex-direction: row;
  padding: 0;

  &.valid-danger {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
  }

  &.error-input {
    border-color: $red-3 !important;
  }

  &:focus-within {
    border: 1px solid $grey-5;
  }

  &.state-disabled {
    border: 1px solid $grey-3;
  }

  &.state-error,
  &.state-error:focus-within {
    border: 1px solid $red-3;
  }

  &.full-width {
    width: 100%;
  }

  &.border-transparent {
    border-color: transparent;
  }

  &.text-center {
    .base-input {
      text-align: center;
    }
  }

  &.table-input {
    &.state-disabled {
      border-color: #f4f7f4;
    }

    .base-input {
      &.disabled {
        background: #f4f7f4;
        color: $grey-4;
        opacity: 1;
      }
    }
  }

  &.size-lg {
    column-gap: 6px;
  }

  img.icon {
    order: -1;
    width: 20px;
    height: 20px;
    margin: 0 0 0 12px;

    &-right {
      margin: 0 12px 0 0;
      order: 0;
    }

    &.error {
      filter: brightness(80%) sepia(100%) hue-rotate(-50deg) saturate(8)
        contrast(1);
    }

    @include maxWidth768 {
      width: 15px;
      height: 15px;
    }
  }

  .base-input {
    width: 100%;
    outline: none;
    background: transparent;
    color: $black;
    border-radius: inherit;
    border: none;
    box-sizing: border-box;
    height: auto;
    padding: 4px 2px;
    -webkit-appearance: none;

    &.size-sm {
      font-size: 14px;
    }

    &.size-md {
      font-size: 16px;
      line-height: 18px;
      padding: 10px;
      height: 38px;
    }

    &.size-lg {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 10px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.error {
      color: $red-3;
    }

    &::placeholder {
      color: $black-2;
    }
  }

  .span-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>
