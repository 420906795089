<template>
  <div class="container">
    <h1>Base UI</h1>
    <hr />
    <h2>Typography</h2>
    <h1 class="text-base-h1">Base H1 text</h1>
    <h1 class="text-base-h1 bold-font">Base H1 bold-font text</h1>
    <h2 class="text-base-h2">Base H2 text</h2>
    <h2 class="text-base-h2 bold-font">Base H2 bold-font text</h2>
    <h3 class="text-base-h3">Base H3 text</h3>
    <h3 class="text-base-h3 bold-font">Base H3 bold-font text</h3>
    <h4 class="text-base-h4">Base H4 text</h4>
    <h4 class="text-base-h4 bold-font">Base H4 bold-font text</h4>
    <h5 class="text-base-h5">Base H5 text</h5>
    <h5 class="text-base-h5 bold-font">Base H5 bold-fonttext</h5>
    <h6 class="text-base-h6">Base H6 text</h6>
    <h6 class="text-base-h6 bold-font">Base H6 bold-font text</h6>
    <p class="text-base-p-lg">Base p/span large text</p>
    <p class="text-base-p-md">Base p/span medium text</p>
    <p class="text-base-p-sm">Base p/span small text</p>

    <div class="inline">
      <small class="text-base-small">Base small text</small>
      <small class="text-base-small-2">Base small-2 text</small>
    </div>

    <hr />
    <h2>Buttons</h2>
    <BaseButton bold fullWidth size="lg" variant="primary" color="green"
      >Large primary button</BaseButton
    >
    <br />
    <BaseButton fullWidth size="lg" variant="secondary" color="green"
      >Large secondary button</BaseButton
    >
    <br />
    <BaseButton fullWidth size="md" variant="primary" color="green"
      >Medium button</BaseButton
    >
    <br />
    <BaseButton fullWidth size="md" variant="secondary" color="green"
      >Medium button</BaseButton
    >
    <br />
    <BaseButton fullWidth size="sm" variant="primary" color="green"
      >Small button</BaseButton
    >
    <br />
    <BaseButton fullWidth size="sm" variant="secondary" color="green"
      >Small button</BaseButton
    >
    <br />
    <div class="inline">
      <BaseButton size="lg" variant="secondary" color="green"
        >Large button</BaseButton
      >
      <BaseButton size="md" variant="secondary" color="green"
        >Medium button</BaseButton
      >
      <BaseButton size="sm" variant="secondary" color="green"
        >Small button</BaseButton
      >
      <BaseButton size="sm" variant="primary" color="green"
        >Small button</BaseButton
      >
      <BaseButton size="md" variant="primary" color="green"
        >Medium button</BaseButton
      >
      <BaseButton size="lg" variant="primary" color="green"
        >Large button</BaseButton
      >
      <BaseButton size="lg" disabled variant="primary" color="green"
        >disabled button</BaseButton
      >
      <BaseButton size="md" disabled variant="primary" color="green"
        >disabled button</BaseButton
      >
      <BaseButton size="sm" disabled variant="primary" color="green"
        >disabled button</BaseButton
      >
    </div>
    <br />

    <div class="inline">
      <BaseButton
        size="lg"
        icon="add-user-icon"
        iconPosition="left"
        variant="secondary"
        color="green"
        >button with left icon</BaseButton
      >
      <BaseButton
        size="lg"
        icon="add-user-icon"
        iconPosition="right"
        variant="secondary"
        color="green"
        >button with right icon</BaseButton
      >
      <BaseButton size="md" icon="add-user-icon" variant="primary" color="green"
        >primary button with icon</BaseButton
      >
      <BaseButton
        size="md"
        icon="add-user-icon"
        disabled
        variant="secondary"
        color="green"
        >disabled button with icon</BaseButton
      >
      <BaseButton size="md" icon="add-user-icon" variant="secondary" />
      <BaseButton
        size="md"
        icon="add-user-icon"
        variant="primary"
        color="green"
      />
      <BaseButton
        size="md"
        disabled
        icon="add-user-icon"
        variant="primary"
        color="green"
      />
      <BaseButton size="sm" icon="cache-icon" variant="secondary" />
      <BaseButton size="lg" color="red-3" icon="cache-icon" variant="primary" />
      <BaseButton
        size="lg"
        color="blue-3"
        icon="cache-icon"
        variant="primary"
      />
      <BaseButton
        size="lg"
        color="orange-3"
        icon="cache-icon"
        variant="primary"
      />
    </div>
    <hr />
    <h2>Inputs</h2>
    <div class="inline">
      <BaseInput placeholder="base input" />
      <BaseInput placeholder="base input disabled" disabled />
      <BaseInput
        placeholder="input with icon"
        icon="search-icon"
        iconPosition="right"
      />
      <BaseInput
        placeholder="input with right icon"
        icon="search-icon"
        iconRight
      />
      <BaseInput
        :valid="false"
        value="invalid input with icon"
        icon="search-icon"
      />

      <BaseInput placeholder="base medium input" size="md" />
      <BaseInput placeholder="base large input" icon="search-icon" size="lg" />
    </div>
    <br />
    <div class="inline">
      <BaseSelect :selectedOption="null" size="sm">
        <option :value="null" selected disabled>Please choose</option>
        <option value="option1">option1</option>
        <option value="option2">option2</option>
      </BaseSelect>
      <BaseSelect :selectedOption="null">
        <option :value="null" selected disabled>Please choose</option>
        <option value="option1">option1</option>
        <option value="option2">option2</option>
      </BaseSelect>
      <BaseSelect selectedOption="option1" invalid>
        <option :value="null" selected disabled>Please choose</option>
        <option value="option1">Not valid</option>
        <option value="option2">option2</option>
      </BaseSelect>
      <BaseSelect :selectedOption="null" disabled>
        <option :value="null" selected>disabled</option>
      </BaseSelect>

      <BaseSelect :selectedOption="null" size="lg">
        <option :value="null" selected disabled>Select large</option>
        <option value="option1">option1</option>
        <option value="option2">option2</option>
      </BaseSelect>
    </div>

    <br /><br />

    <div class="inline">
      <BaseCard>
        <h4 class="text-base-h4 bold-font">Base Card</h4>
        <BaseButton size="md" variant="primary" bold color="red-3" class="mt-2"
          >Action</BaseButton
        >
      </BaseCard>
      <BaseCard>
        <div class="d-flex verticaly-center">
          <h4 class="text-base-h4 bold-font">Base Card</h4>
          <BaseButton size="md" variant="primary" bold color="red-3"
            >Action</BaseButton
          >
        </div>
      </BaseCard>
    </div>
    <BaseCard fullWidth> full width card </BaseCard>
    <br />
    <BaseTabs
      v-model="tab"
      size="sm"
      :tabs="[
        {
          text: 'text1',
          value: 'test',
        },
        {
          text: 'text2',
          value: 'test2',
        },
        {
          text: 'text3',
          value: 'test3',
        },
      ]"
    />
    <br />
    <div class="d-flex">
      <BaseCopier text="test value" icon="copy-link-icon" />
      <BaseCopier text="123321123" icon="contacts-icon" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: 40px;
}
.inline {
  display: flex;
  align-items: flex-end;
  column-gap: 10px;
}
.d-flex {
  display: flex;
  column-gap: 10px;
}
.verticaly-center {
  align-items: center;
}
.mt-2 {
  margin-top: 20px;
}
</style>

<script>
export default {
  data() {
    return {
      tab: "test",
    };
  },
};
</script>
