import layout from "~/layout/App/main.vue";
import auth from "../middleware/auth";

const cronsRoutes = {
  path: "/crons",
  component: layout,
  name: "crons",
  children: [
    {
      path: "",
      component: () => import("~/views/Crons/Index"),
      name: "crons.index",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "create",
      component: () => import("~/views/Crons/Create"),
      name: "crons.create",
      meta: {
        middleware: auth,
      },
    },
    {
      path: "update",
      component: () => import("~/views/Crons/Update"),
      name: "crons.update",
      meta: {
        middleware: auth,
      },
    },
  ],
};

export default cronsRoutes;
