import { isEmpty } from "../utils.js";

const itnValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }

  if (value.match(/\D/)) {
    return "validate.itn_failde";
  }

  let inn = value.match(/(\d)/g);
  let hash = [-1, 5, 7, 9, 4, 6, 10, 5, 7];
  let sum = 0;
  for (let i = 0; i < inn.length - 1; ++i) {
    sum += inn[i] * hash[i];
  }

  let k = sum - 11 * Math.floor(sum / 11);

  if (k === 10) {
    k = 0;
  }

  if (k === parseInt(inn[9])) {
    return true;
  }

  return "validate.itn_failde";
};

export { itnValidator };
