import { show } from "~/api/contractSpendingChart";
import * as types from "../mutation-types";
import store from "../../store";

/**
 * Initial state
 */
export const state = {
  series: [],
  seriesSpendingBalance: [],
  seriesSpendingBarInfo: [],
  isLoading: false,
};

/**
 * Mutations
 */
export const mutations = {
  [types.SET_IS_LOADING_SERIES_CONTRACT_SPENDING_CHART](state, status) {
    state.isLoading = status;
  },
  [types.SET_SERIES_CONTRACT_SPENDING_BAR_INFO_CHART](state, data) {
    let result = [];

    Object.entries(data).forEach(([key, value]) => {
      //TODo временный вариант, далее поменять на константы, когда будет готовый вариант
      // комбинации графиков и данных на этих графиках.
      if (key === "0") {
        result.push({
          color: "blue",
          name: "Баланс",
          type: "spline",
          data: value,
        });
      } else {
        switch (key) {
          case "10":
            key = "Витрати за сховище";
            break;
          case "1":
            key = "Загальні витрати";
            break;
          case "20":
            key = "Витрати БД";
            break;
        }

        result.push({
          type: "column",
          yAxis: 1,
          name: key,
          data: value,
        });
      }
    });

    state.seriesSpendingBarInfo = result;
  },
  [types.SET_SERIES_CONTRACT_SPENDING_CHART](state, data) {
    let result = [];

    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        case "10":
          key = "Витрати за сховище";
          break;
        case "1":
          key = "Загальні витрати";
          break;
        case "20":
          key = "Витрати БД";
          break;
        case "30":
          key = "Запити до сайтів";
          break;
        case "31":
          key = "Навантаження CPU  сайту";
          break;
        case "32":
          key = "Використання  Memory сайту";
          break;
      }

      result.push({
        turboThreshold: 0,
        name: key,
        data: value,
      });
    });
    state.seriesSpendingBalance = result;
  },
};

/**
 * Actions
 */
export const actions = {
  fetchSeriesChart({ commit }, data) {
    let activeContractId =
      data.contractId || store.getters["contract/activeContract"]?.id;

    if (activeContractId) {
      // show(activeContractId, data.typeId, data.stepTypeId).then(() => {
      //   //TODO тут мы только jnghfdzktv запрос,  что бы получить данные. А может быть их получать по http запросу?
      //   //Одним словом подумать, может быть просто запросом или всё такие сокетом?
      //   // let { data } = response;
      //   // commit(types.SET_SERIES_CONTRACT_SPENDING_CHART, data);
      //   commit(types.SET_IS_LOADING_SERIES_CONTRACT_SPENDING_CHART, true);
      // });
    }
  },
};

/**
 * Getters
 */
export const getters = {
  seriesSpendingBalance: (state) => state.seriesSpendingBalance,
  seriesSpendingBarInfo: (state) => state.seriesSpendingBarInfo,
  isLoading: (state) => state.isLoading,
};
